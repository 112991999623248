import React, { useEffect } from "react";

// ASSETS

// STYLES
import * as styled from "./BeneficiaryCardStyled";
import "react-loading-skeleton/dist/skeleton.css";

// LIBRARIES
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";

// MISC
import { IProps } from "./BeneficiaryCardModel";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION
const BeneficiaryCard = (props: IProps) => {
  // PROPERTIES
  const {
    cardData,
    handleCardPress,
    appearance = "enable",
    isFetching = false,
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const toTitleCase = (str) => {
    let lowerStr = str.toLowerCase();

    return lowerStr.replace(/(^|\s)([a-zăâîșț])/g, function (initial) {
      return initial.toUpperCase();
    });
  };

  return (
    <styled.BeneficiaryCardContainer
      onClick={() => handleCardPress(cardData)}
      appearance={appearance}
    >
      <styled.UserNameContainer appearance={appearance}>
        {isFetching ? (
          <Skeleton />
        ) : (
          <styled.UserNameText appearance={appearance} id={"text"}>
            {toTitleCase(cardData?.fullName)}
          </styled.UserNameText>
        )}
      </styled.UserNameContainer>
      <styled.BottomContainer>
        <styled.SignUpContainer appearance={appearance}>
          {isFetching ? (
            <Skeleton count={2} />
          ) : (
            <>
              <CustomText
                type="H7"
                text={
                  appearance === "disable" ? "Inactivat la:" : "Înscris la:"
                }
              />
              <CustomText
                type="H7"
                text={moment(
                  appearance === "disable"
                    ? cardData?.inactivatedAt
                    : cardData?.createdAt
                ).format("DD.MM.YYYY")}
              />
            </>
          )}
        </styled.SignUpContainer>
        {appearance === "enable" && (
          <styled.ServicesContainer appearance={appearance}>
            <CustomText type="H7" text="Servicii active: " />
            <CustomText type="H7" text={cardData.noOfActivities} />
          </styled.ServicesContainer>
        )}
      </styled.BottomContainer>
    </styled.BeneficiaryCardContainer>
  );
};
export default BeneficiaryCard;
