// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { SIZE, COLORS, BORDER_RADIUS } from "constants/variables";
import { ReactComponent as ArrowHead } from "assets/icons/tableHeadArrow.svg";

export const CustomFilterButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};
  padding: 6px ${SIZE.max16};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  user-select: none;
`;
export const Text = styled.span`
  white-space: nowrap;
`;
export const TableHeadCellArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;
export const TableStatusCellTopArrow = styled(ArrowHead)<{
  isHighlighted: boolean;
}>(
  ({ isHighlighted = false }) => css`
    height: 6px;
    width: 10px;
    ${isHighlighted &&
    css`
      filter: brightness(0) saturate(100%) invert(38%) sepia(100%)
        saturate(6775%) hue-rotate(228deg) brightness(89%) contrast(90%);
    `}
  `
);
export const TableStatusCellBottomArrow = styled(ArrowHead)<{
  isHighlighted: boolean;
}>(
  ({ isHighlighted = false }) => css`
    height: 6px;
    width: 10px;
    transform: rotate(180deg);

    ${isHighlighted &&
    css`
      filter: brightness(0) saturate(100%) invert(38%) sepia(100%)
        saturate(6775%) hue-rotate(228deg) brightness(89%) contrast(90%);
    `}
  `
);
