// ASSETS

// STYLES
import * as styled from "./NewDocumentPageStyled";

// LIBRARIES
import { useAppSelector } from "hooks/reduxHooks";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";

// MISC

// COMPONENTS
import CNPFlow from "view/NewDocumentPage/CNPFlow/CNPFlow";
import LegalPersonFlow from "view/NewDocumentPage/LegalPersonFlow/LegalPersonFlow";
import ModelFlow from "view/NewDocumentPage/ModelFlow/ModelFlow";
import { isRequestLoadingSelector } from "slices/SessionSlice/sessionSliceSelectors";

// CONFIGURATION

const NewDocumentPage = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);
  const isRequestLoadingData = useAppSelector(isRequestLoadingSelector);

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  const displayInputTypes = () => {
    switch (newDocumentSelector?.currentFlow) {
      case "cnpFlow":
        return <CNPFlow />;
      case "legalPersonFlow":
        return <LegalPersonFlow />;
      case "modelFlow":
        return <ModelFlow />;
      default:
        return <CNPFlow />;
    }
  };

  return (
    !isRequestLoadingData && (
      <styled.NewDocumentPageContainer>
        {displayInputTypes()}
      </styled.NewDocumentPageContainer>
    )
  );
};

export default NewDocumentPage;
