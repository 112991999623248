import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomHeaderStyled";

// LIBRARIES

// MISC
import { IProps } from "components/CustomHeader/CustomHeaderModel";

// COMPONENTS
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION

const CustomHeader = (props: IProps) => {
  // PROPERTIES
  const { handleGoBack, text = "" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CustomHeaderContainer>
      <CustomIconButton
        handleClick={handleGoBack}
        appearance="headerBackButton"
      />
      <CustomText type="H6" text={text} />
    </styled.CustomHeaderContainer>
  );
};

export default CustomHeader;
