// LIBRARIES
import styled from "styled-components";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const DocumentsTabContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${SIZE.max24};
  height: 100%;
  //max-height: 70vh;
  padding-bottom: ${SIZE.max24};

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
export const DocumentsTableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;
export const TableWrapper = styled.div`
  background-color: ${COLORS.appWhite};

  border-radius: ${BORDER_RADIUS.cardRadius};
  height: 100%;
  display: flex;
  //flex-grow: 1;
  overflow-y: auto;
`;
export const CustomTableWrapper = styled.div`
  width: 100%;
`;
export const CustomTextWarningContainer = styled.div`
  padding: ${SIZE.max24};
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AttachmentFileContainer = styled.div`
  display: flex;

  min-width: 320px;
  width: 100%;
  max-width: 520px;
  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};

  @media (max-width: 1250px) {
    max-width: 100%;
  }
`;
