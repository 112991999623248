// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const DndCardContainer = styled.div`
  width: 100%;
`;

export const DndCardWrapper = styled.div<{
  isDragging: boolean;
}>(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${COLORS.appWhite};
    padding: ${SIZE.max16};
    border-radius: ${BORDER_RADIUS.cardRadius};

    div:first-child {
      display: inline;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      min-height: 70px;
    }

    div:last-child {
      text-transform: uppercase;

      max-width: 95%;
      display: inline;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  `
);
