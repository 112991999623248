import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { store } from "store/store";
import { handleLogout } from "slices/SessionSlice/sessionSlice";

export const baseUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  // timeout: 10000,
  credentials: "include", // This allows server to set cookies
  prepareHeaders: (headers, { getState }) => {
    const token = store.getState().session.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(handleLogout());

    // // send refresh token to get new access token
    // const refreshResult = await baseQuery("/refresh", api, extraOptions);
    // // console.log("refreshResult", refreshResult);
    //
    // if (refreshResult?.data) {
    //   // store the new token
    //   api.dispatch(handleSetToken(refreshResult?.data));
    //
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(handleLogout());
    // }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "ALL_USERS",
    "ALL_FILES",
    "ALL_ACTIVITIES",
    "ALL_COMMUNITY_CENTERS",
    "ALL_TARGET_GROUPS",
    "ALL_BENEFICIARIES",
    "ALL_BENEFICIARY_ACTIVITIES",
    "ALL_USERS_WITH_ACCESS",
  ],
  endpoints: (builder) => ({}),
});
