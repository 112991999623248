import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./StatisticsPageStyled";

// LIBRARIES
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { useAppSelector } from "hooks/reduxHooks";
import { statisticsPagePaginationSelector } from "slices/ClientSlice/clientSliceSelectors";
import { useDispatch } from "react-redux";
import ExportReportModal from "components/ExportReportModal/ExportReportModal";
import {
  useGetAllActivityCategoriesQuery,
  useGetAllActivityStatisticsQuery,
  useGetAllAgeGroupsStatisticsQuery,
  useGetAllStatisticsTableQuery,
  useGetAllTargetGroupsStatisticsQuery,
} from "api/statisticsApi";
import useTableHeadDetails from "hooks/useTableHeadHook";
import { baseUrl } from "api/apiSlice";
import { tokenSelector } from "slices/SessionSlice/sessionSliceSelectors";

import "react-loading-skeleton/dist/skeleton.css";

// MISC
import { statisticsPageTableHeadValues } from "mocks/statisticsMock";

// COMPONENTS
import HorizontalBarChart from "components/Atoms/CustomHorizontalBarChart/CustomHorizontalBarChart";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomTable from "components/Atoms/CustomTable/CustomTable";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import useIsRequestLoading from "hooks/useRequestLoading";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";
import CustomStatisticsSkeletonCard from "skeletons/CustomStatisticsSkeletonCard/CustomStatisticsSkeletonCard";

// CONFIGURATION

const StatisticsPage = () => {
  // PROPERTIES
  const currentToken = useAppSelector(tokenSelector);
  // API REQUESTS

  const statisticsPagePaginationDetails = useAppSelector(
    statisticsPagePaginationSelector
  );
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(statisticsPageTableHeadValues);

  const { data: activitiesListData, isLoading: isActivitiesListLoading } =
    useGetAllActivityCategoriesQuery();

  const { data: allAgeGroupsStatisticsData, isLoading: isAgeGroupsLoading } =
    useGetAllAgeGroupsStatisticsQuery();

  let localActivitiesList = activitiesListData?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const [selectedOption, setSelectedOption] = useState({
    activitiesStatistics: localActivitiesList?.[0]?.["key"],
  });

  const {
    data: allActivitiesStatisticsData,
    isLoading: isActivitiesLoading,
    isFetching: isActivitiesFetching,
  } = useGetAllActivityStatisticsQuery(
    {
      activityCategoryId: selectedOption?.activitiesStatistics,
    },
    { skip: !selectedOption?.activitiesStatistics }
  );

  const {
    data: allTargetGroupsStatisticsData,
    isLoading: isTargetGroupsLoading,
  } = useGetAllTargetGroupsStatisticsQuery();

  const {
    data: allTableData,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
  } = useGetAllStatisticsTableQuery({
    page: statisticsPagePaginationDetails?.pageNumber - 1,
    size: statisticsPagePaginationDetails?.pageSize,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const [isExportLoading, setIsExportLoading] = useState(false);

  // const pageLoading =
  //   (isActivitiesListLoading &&
  //     isAgeGroupsLoading &&
  //     isActivitiesLoading &&
  //     isTableLoading) ||
  //   isExportLoading;

  const pageLoading =
    isActivitiesListLoading ||
    isAgeGroupsLoading ||
    isActivitiesLoading ||
    isTableLoading ||
    isExportLoading;

  useIsRequestLoading(pageLoading);

  const ageGroupsMock = {
    title: "Grupuri de vârstă",
    hasDropdown: false,
    options: [
      { key: "1", value: "Lunar" },
      { key: "2", value: "Anual" },
    ],
    values: allAgeGroupsStatisticsData,
    legend: {
      topBarLabel: "Bărbați",
      bottomBarLabel: "Femei",
    },
  };

  const activitiesStatsMock = {
    title: "Activităţi",
    hasDropdown: true,
    options: localActivitiesList,
    values: allActivitiesStatisticsData,
    legend: {
      topBarLabel: "Bărbați",
      bottomBarLabel: "Femei",
    },
  };

  const targetGroupsStatsMock = {
    title: "Grupuri ţintă",
    hasDropdown: false,
    options: [],
    values: allTargetGroupsStatisticsData,
    legend: {
      topBarLabel: "Bărbați",
      bottomBarLabel: "Femei",
    },
  };

  // LIBRARY CONSTANTS

  const dispatch = useDispatch();

  // STATE CONSTANTS
  const [isModalOpen, setIsModalOpen] = useState(false);

  // LIFE CYCLE
  useEffect(() => {
    setSelectedOption({
      ...selectedOption,
      activitiesStatistics: localActivitiesList?.[0]?.["key"],
    });

    // eslint-disable-next-line
  }, [activitiesListData]);

  // EVENT HANDLERS
  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    clientPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        clientPage,
      })
    );
  };
  const handleExport = async () => {
    setIsExportLoading(true);

    const url = `${baseUrl}export/activities-excel`;

    const options = {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    fetch(url, options)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsExportLoading(false);
      });
  };

  const handleSelectChange = (name, event) => {
    setSelectedOption((selectedOption) => ({
      ...selectedOption,
      [name]: event,
    }));
  };

  return (
    <styled.StatisticsPageContainer>
      <styled.StatisticsPageTitleContainer>
        <CustomText type="H3" text="Statistici" />
        <styled.ExportButtonContainer>
          <CustomButton
            text={"Exporta Raport"}
            handleClick={handleExport}
            appearance={"smallestBlue"}
          />
        </styled.ExportButtonContainer>
      </styled.StatisticsPageTitleContainer>

      <styled.StatisticsPageChartContainer>
        {!isAgeGroupsLoading && <HorizontalBarChart data={ageGroupsMock} />}
        {isActivitiesFetching ? (
          <CustomStatisticsSkeletonCard rowCount={5} />
        ) : (
          <HorizontalBarChart
            data={activitiesStatsMock}
            selectedOption={selectedOption?.activitiesStatistics}
            handleSelectChange={(event) =>
              handleSelectChange("activitiesStatistics", event.target.value)
            }
          />
        )}
        {!isTargetGroupsLoading && (
          <HorizontalBarChart data={targetGroupsStatsMock} />
        )}
      </styled.StatisticsPageChartContainer>
      <styled.StatisticsTableContainer>
        <styled.StatisticsTableWrapper>
          {isTableFetching ? (
            <CustomSkeletonTable
              rowCount={6}
              columnCount={tableHeadDetails?.length}
            />
          ) : (
            <CustomTable
              banList={["id"]}
              headerData={tableHeadDetails}
              bodyData={allTableData?.content}
              onHeadCellPress={handleChangeSortOrder}
            />
          )}
        </styled.StatisticsTableWrapper>

        <CustomPagination
          page={statisticsPagePaginationDetails?.pageNumber}
          count={Math.ceil(
            allTableData?.["totalElements"] /
              statisticsPagePaginationDetails?.pageSize
          )}
          onChange={(event, value) =>
            handlePageChange(event, value, "statisticsPagePagination")
          }
        />
      </styled.StatisticsTableContainer>
      <ExportReportModal
        modalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </styled.StatisticsPageContainer>
  );
};

export default StatisticsPage;
