import { apiSlice } from "../api/apiSlice";

export const adminApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------- OPERATORS ------------------ //
    getAllUsersPageable: builder.query<
      any,
      {
        page: number;
        size: number;
        searchQuery: string;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, searchQuery, sortField, sortOrder }) =>
        `user/get-all-users-pageable?page=${page}&searchQuery=${searchQuery}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: ["ALL_USERS"],
      keepUnusedDataFor: 0,
    }),

    getAllUsers: builder.query<any, void>({
      query: () => `user/get-all-users`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getUsersWithAccesToBeneficiaryById: builder.query<
      any,
      {
        beneficiaryId: number;
      }
    >({
      query: ({ beneficiaryId }) =>
        `beneficiary/get-shared-operators-for-beneficiary?beneficiaryId=${beneficiaryId}`,
      providesTags: ["ALL_USERS_WITH_ACCESS"],
      keepUnusedDataFor: 0,
    }),

    createUser: builder.mutation<
      any,
      {
        username: string;
        role: string;
        fullName: string;
        countyId?: string;
        communityCenterIds?: number[];
      }
    >({
      query: ({ username, role, fullName, countyId, communityCenterIds }) => ({
        url: "user/create-user",
        method: "POST",

        body: { username, role, fullName, countyId, communityCenterIds },
      }),
      invalidatesTags: ["ALL_USERS"],
    }),

    editUser: builder.mutation<
      any,
      {
        username: string;
        role: string;
        fullName: string;
        id?: number;
        userStatus?: string;
        countyId?: string;
        communityCenterIds?: number[];
      }
    >({
      query: ({
        username,
        role,
        fullName,
        countyId,
        communityCenterIds,
        id,
        userStatus,
      }) => ({
        url: "user/edit-user",
        method: "PUT",

        body: {
          username,
          role,
          fullName,
          countyId,
          communityCenterIds,
          id,
          userStatus,
        },
      }),
      invalidatesTags: ["ALL_USERS"],
    }),

    activateInactivateUser: builder.mutation<
      any,
      {
        isActive: boolean;
        userId: number;
      }
    >({
      query: ({ isActive, userId }) => ({
        url: `user/activate-or-inactivate-user?isActive=${isActive}&userId=${userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_USERS"],
    }),

    // -------------------- ACTIVITIES ------------------ //
    getAllActivities: builder.query<
      any,
      {
        page: number;
        size: number;
        searchQuery: string;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, searchQuery, sortField, sortOrder }) =>
        `activity/get-all-activity-categories-pageable?page=${page}&searchQuery=${searchQuery}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: ["ALL_ACTIVITIES"],
      keepUnusedDataFor: 0,
    }),

    // CREATE ACTIVITY
    createActivity: builder.mutation<
      any,
      {
        activityCategoryId: number;
        name: string;
      }
    >({
      query: ({ activityCategoryId, name }) => ({
        url: `activity/create-activity`,
        method: "POST",
        body: { activityCategoryId, name },
      }),
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    // CREATE ACTIVITY CATEGORY
    createActivityCategory: builder.mutation<
      any,
      {
        name: string;
      }
    >({
      query: ({ name }) => ({
        url: `activity/create-activity-category`,
        method: "POST",
        body: { name },
      }),
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    // UPDATE ACTIVITY
    updateActivity: builder.mutation<
      any,
      {
        name: string;
        id: number;
        activityCategoryId: number;
      }
    >({
      query: ({ name, id, activityCategoryId }) => ({
        url: `activity/update-activity`,
        method: "PUT",
        body: { id, name, activityCategoryId },
      }),
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    // UPDATE ACTIVITY CATEGORY
    updateActivityCategory: builder.mutation<
      any,
      {
        name: string;
        id: number;
      }
    >({
      query: ({ name, id }) => {
        return {
          url: `activity/update-activity-category`,
          method: "PUT",
          body: { name, id },
        };
      },
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    activateInactivateActivityCategory: builder.mutation<
      any,
      {
        isActive: boolean;
        activityCategoryId: number;
      }
    >({
      query: ({ isActive, activityCategoryId }) => ({
        url: `activity/activate-or-inactivate-activity-category?isActive=${isActive}&activityCategoryId=${activityCategoryId}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    activateInactivateCActivity: builder.mutation<
      any,
      {
        isActive: boolean;
        activityId: number;
      }
    >({
      query: ({ isActive, activityId }) => ({
        url: `activity/activate-or-inactivate-activity?isActive=${isActive}&activityId=${activityId}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_ACTIVITIES"],
    }),

    // -------------------- COMMUNITY CENTER ------------------ //
    getAllCommunityCentersPageable: builder.query<
      any,
      {
        page: number;
        size: number;
        searchQuery: string;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, searchQuery, sortField, sortOrder }) =>
        `community-center/get-all-community-centers-pageable?page=${page}&searchQuery=${searchQuery}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: ["ALL_COMMUNITY_CENTERS"],
      keepUnusedDataFor: 0,
    }),

    getComunityCenterUsersById: builder.query<
      any,
      {
        communityCenterId: number;
      }
    >({
      query: ({ communityCenterId }) =>
        `community-center/get-community-center-users?communityCenterId=${communityCenterId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    // GET ALL COMMUNITY CENTERS
    getAllCommunityCenters: builder.query<any, void>({
      query: () => `community-center/get-all-community-centers`,
      providesTags: [],
      keepUnusedDataFor: 4,
    }),

    // CREATE COMMUNITY CENTER
    createCommunityCenter: builder.mutation<
      any,
      {
        countyId: number;
        name: string;
      }
    >({
      query: ({ countyId, name }) => ({
        url: `community-center/create-community-center`,
        method: "POST",
        body: { countyId, name },
      }),
      invalidatesTags: ["ALL_COMMUNITY_CENTERS"],
    }),

    updateCommunityCenter: builder.mutation<
      any,
      {
        countyId: number;
        id: number;
        name: string;
      }
    >({
      query: ({ countyId, name, id }) => ({
        url: `community-center/update-community-center`,
        method: "PUT",
        body: { countyId, name, id },
      }),
      invalidatesTags: ["ALL_COMMUNITY_CENTERS"],
    }),

    // ACTIVATE INACTIVATE COMMUNITY CENTER
    activateInactivateCommunityCenter: builder.mutation<
      any,
      {
        isActive: boolean;
        communityCenterId: number;
      }
    >({
      query: ({ isActive, communityCenterId }) => ({
        url: `community-center/activate-or-inactivate-community-center?isActive=${isActive}&communityCenterId=${communityCenterId}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_COMMUNITY_CENTERS"],
    }),
    // -------------------- TARGET GROUPS ------------------ //

    // GET ALL COUNTIES
    getAllCounties: builder.query<any, void>({
      query: () => `county/get-all-counties`,
      providesTags: [],
      keepUnusedDataFor: 4,
    }),

    // GET ALL TARGET GROUPS
    getAllTargetGroups: builder.query<
      any,
      {
        page: number;
        size: number;
        searchQuery: string;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, searchQuery, sortField, sortOrder }) =>
        `target-group/get-all-target-groups-pageable?page=${page}&searchQuery=${searchQuery}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: ["ALL_TARGET_GROUPS"],
      keepUnusedDataFor: 0,
    }),

    // CREATE TARGET GROUP
    createTargetGroup: builder.mutation<
      any,
      {
        code: string;
        name: string;
      }
    >({
      query: ({ code, name }) => ({
        url: `target-group/create-target-group`,
        method: "POST",
        body: { code, name },
      }),
      invalidatesTags: ["ALL_TARGET_GROUPS"],
    }),

    // UPDATE TARGET GROUP
    updateTargetGroups: builder.mutation<
      any,
      {
        id: number;
        name: string;
        code: string;
      }
    >({
      query: ({ name, id, code }) => ({
        url: `target-group/update-target-group`,
        method: "PUT",
        body: { name, id, code },
      }),
      invalidatesTags: ["ALL_TARGET_GROUPS"],
    }),

    // ACTIVATE INACTIVATE TARGET GROUP
    activateInactivateTargetGroups: builder.mutation<
      any,
      {
        isActive: boolean;
        targetGroupId: number;
      }
    >({
      query: ({ isActive, targetGroupId }) => ({
        url: `target-group/activate-or-inactivate-target-group?isActive=${isActive}&targetGroupId=${targetGroupId}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_TARGET_GROUPS"],
    }),

    // -------------------- TEMPLATE SECTION ------------------ //

    // GET ALL FORM CATEGORIES
    getAllFormCategories: builder.query<any, void>({
      query: () => `form/get-form-categories`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    // CREATE FORM MODEL
    createFormModel: builder.mutation<any, any>({
      query: (body) => ({
        url: `form/create-form-model`,
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: [],
    }),
    // EDIT FORM MODEL
    editFormModel: builder.mutation<any, any>({
      query: (body) => ({
        url: `form/edit-form-model`,
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: [],
    }),

    // GET ALL FORM MODELS BY CATEGORY
    getFormModelsByCategory: builder.query<any, { formCategoryId: number }>({
      query: ({ formCategoryId }) =>
        `form/get-form-models-by-category?formCategoryId=${formCategoryId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    // GET FORM MODEL BY ID
    getFormModelById: builder.query<any, { formId: number }>({
      query: ({ formId }) => `form/get-form-model?formId=${formId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  // OPERATORS
  useGetAllUsersPageableQuery,
  useGetAllUsersQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useActivateInactivateUserMutation,
  useGetUsersWithAccesToBeneficiaryByIdQuery,

  // ACTIVITIES
  useGetAllActivitiesQuery,
  useCreateActivityMutation,
  useCreateActivityCategoryMutation,
  useUpdateActivityMutation,
  useUpdateActivityCategoryMutation,
  useActivateInactivateActivityCategoryMutation,
  useActivateInactivateCActivityMutation,

  // COMMUNITY CENTER
  useGetAllCommunityCentersQuery,
  useGetAllCommunityCentersPageableQuery,
  useCreateCommunityCenterMutation,
  useActivateInactivateCommunityCenterMutation,
  useUpdateCommunityCenterMutation,
  useGetComunityCenterUsersByIdQuery,

  // COUNTY
  useGetAllCountiesQuery,
  useGetAllTargetGroupsQuery,
  useCreateTargetGroupMutation,
  useUpdateTargetGroupsMutation,
  useActivateInactivateTargetGroupsMutation,

  // TEMPLATE SECTION
  useGetAllFormCategoriesQuery,
  useCreateFormModelMutation,
  useEditFormModelMutation,
  useGetFormModelsByCategoryQuery,
  useLazyGetFormModelByIdQuery,
} = adminApi;
