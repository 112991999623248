import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./TargetGroupsStyled";

// LIBRARIES
import {
  useActivateInactivateTargetGroupsMutation,
  useCreateTargetGroupMutation,
  useGetAllTargetGroupsQuery,
  useUpdateTargetGroupsMutation,
} from "api/adminApi";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";
import useTableHeadDetails from "hooks/useTableHeadHook";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { targetGroupsPaginationSelector } from "slices/AdminSlice/adminSliceSelectors";
import { handlePagePaginationChange } from "slices/AdminSlice/adminSlice";

// MISC
import { targetGroupsHeadValues } from "mocks/adminMock";
import { renderData } from "helpers/globalFunc";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomTable from "components/Atoms/CustomTable/CustomTable";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import useIsRequestLoading from "hooks/useRequestLoading";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";

// CONFIGURATION
const TargetGroups = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const targetGroupsPaginationDetails = useAppSelector(
    targetGroupsPaginationSelector
  );
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);

  // STATE CONSTANTS
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(targetGroupsHeadValues);

  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    name: "",
    code: "",
    id: 0,
  });

  // API REQUESTS
  const {
    data: targetGroupsList,
    isLoading: isTargetGroupsListLoading,
    isFetching: isTargetGroupsListFetching,
  } = useGetAllTargetGroupsQuery({
    page: targetGroupsPaginationDetails.pageNumber - 1,
    size: targetGroupsPaginationDetails.pageSize,
    searchQuery: headerSearchValueDetails,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const [createTargetGroupRequest, { isLoading: isCreateTargetGroupLoading }] =
    useCreateTargetGroupMutation();
  const [updateTargetGroupRequest, { isLoading: isUpdateTargetGroupLoading }] =
    useUpdateTargetGroupsMutation();

  const [
    activateInactivateTargetGroupsRequest,
    { isLoading: isDeleteTargetGroupLoading },
  ] = useActivateInactivateTargetGroupsMutation();

  const pageLoading =
    isTargetGroupsListLoading ||
    isCreateTargetGroupLoading ||
    isUpdateTargetGroupLoading ||
    isDeleteTargetGroupLoading;
  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleAddUser = () => {
    setIsEditMode(false);
    setOpenModal(true);

    setModalDetails({
      name: "",
      code: "",
      id: 0,
    });
  };
  const handleCreateTargetGroup = async () => {
    if (isEditMode) {
      await updateTargetGroupRequest({
        id: modalDetails["id"],
        name: modalDetails["name"],
        code: modalDetails["code"],
      }).then(() => {
        setOpenModal(false);
      });
    } else {
      await createTargetGroupRequest({
        name: modalDetails["name"],
        code: modalDetails["code"],
      }).then(() => {
        setOpenModal(false);
      });
    }
  };
  const handleEditUser = (row: object) => {
    setIsEditMode(true);
    setOpenModal(true);

    setModalDetails({
      name: row["name"],
      code: row["code"],
      id: row["id"],
    });
  };
  const handleDeleteUser = async (row: object) => {
    await activateInactivateTargetGroupsRequest({
      targetGroupId: row["id"],
      isActive: !row["isActive"],
    });
  };
  const handleClose = () => {
    setIsEditMode(false);
    setOpenModal(false);
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    adminPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        adminPage,
      })
    );
  };

  const handleModalChange = (name: string, value: string) => {
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };

  const formDataArr = [
    {
      type: "input",
      name: "name",
      placeholder: "Denumire",
    },
    {
      type: "input",
      name: "code",
      placeholder: "Cod",
    },
  ];

  return (
    <styled.TargetGroupsContainer>
      <styled.OperatorsPageHeader>
        <CustomText type="H3" text="Grupuri țintă" />
        <styled.AddUserButtonContainer>
          <CustomButton text="Adauga Grup Tintă" handleClick={handleAddUser} />
        </styled.AddUserButtonContainer>
      </styled.OperatorsPageHeader>
      <styled.OperatorsPageTableContainer>
        <styled.TargetGroupsWrapper>
          {isTargetGroupsListFetching ? (
            <CustomSkeletonTable
              columnCount={tableHeadDetails.length}
              rowCount={10}
            />
          ) : (
            <CustomTable
              onEditPress={handleEditUser}
              onDeletePress={handleDeleteUser}
              onHeadCellPress={handleChangeSortOrder}
              hasAction={true}
              headerData={tableHeadDetails}
              bodyData={targetGroupsList?.content}
              banList={["id", "isActive", "lastUpdateAt"]}
            />
          )}
        </styled.TargetGroupsWrapper>

        <styled.OperatorsPagePaginationContainer>
          <CustomPagination
            page={targetGroupsPaginationDetails?.pageNumber}
            count={Math.ceil(
              targetGroupsList?.["totalElements"] /
                targetGroupsPaginationDetails?.pageSize
            )}
            onChange={(event, value) =>
              handlePageChange(event, value, "targetGroupsPagination")
            }
          />
        </styled.OperatorsPagePaginationContainer>
      </styled.OperatorsPageTableContainer>

      <CustomModal handleClose={handleClose} open={openModal}>
        <styled.ModalWrapper>
          <styled.TitleContainer>
            <CustomText type="H6" text="Detalii Grup Tinta" />
          </styled.TitleContainer>

          <styled.ModalContent>
            {formDataArr?.map((item) => (
              <styled.InputCustomWrapper key={item.name}>
                {renderData(item, modalDetails, handleModalChange)}
              </styled.InputCustomWrapper>
            ))}
          </styled.ModalContent>
          <styled.ButtonsContainer>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={handleClose}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance={
                  modalDetails["name"] && modalDetails["code"]
                    ? "smallestBlue"
                    : "smallestDisabled"
                }
                text={isEditMode ? "Modifica Grup Tinta" : "Adauga Grup Tinta"}
                handleClick={
                  modalDetails["name"] && modalDetails["code"]
                    ? handleCreateTargetGroup
                    : () => {}
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsContainer>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.TargetGroupsContainer>
  );
};

export default TargetGroups;
