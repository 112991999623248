// LIBRARIES
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
import { useDecodedJwt } from "hooks/useDecodedJwt";

// MISC
import { tokenSelector } from "slices/SessionSlice/sessionSliceSelectors";

export function RequireAuth({ allowedRoles }: { allowedRoles?: string[] }) {
  // LIBRARY CONSTANTS
  const currentToken = useAppSelector(tokenSelector);
  const accessToken = currentToken;

  let location = useLocation();
  const { role } = useDecodedJwt();

  return allowedRoles?.includes(role) ? (
    <Outlet />
  ) : accessToken ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
