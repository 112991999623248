import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomPaginationStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomPaginationModel";

// REDUX

// COMPONENTS

// CONFIGURATION
const CustomPagination = (props: IProps) => {
  // PROPERTIES
  const { count = 0 } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CustomPaginationContainer>
      {count > 1 && <styled.CustomPaginationComp {...props} />}
    </styled.CustomPaginationContainer>
  );
};

export default CustomPagination;
