import React, { useState } from "react";

// ASSETS
import { ReactComponent as EditIcon } from "assets/icons/EditIcon.svg";
import { ReactComponent as OkIcon } from "assets/icons/OkIcon.svg";

// STYLES
import * as styled from "./SideBeneficiaryStatusStyled";

// LIBRARIES
import moment from "moment";
import { useUpdateBeneficiaryDetailsMutation } from "api/beneficiaryApi";
import { formatDateTime } from "helpers/globalFunc";

// MISC
import { IProps } from "./SideBeneficiaryStatusModel";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";
import { useGetAllTargetGroupsListQuery } from "api/newDocumentApi";

// CONFIGURATION
const SideBeneficiaryStatus = (props: IProps) => {
  // PROPERTIES
  const { beneficiaryData } = props;

  // API REQUESTS
  const [updateBeneficiaryDetailsRequest] =
    useUpdateBeneficiaryDetailsMutation();

  const { data: targetGroupsList } = useGetAllTargetGroupsListQuery();

  let localTargetGroupsList = targetGroupsList?.map((item) => {
    return {
      key: item.id,
      value: item.code,
    };
  });

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const [editable, setEditable] = useState(false);
  const [beneficiary, setBeneficiary] = useState([
    {
      type: "notEditable",
      label: "Data înregistrării",
      info: moment(beneficiaryData?.createdAt).format("DD.MM.YYYY"),
      disabled: true,
    },
    {
      type: "notEditable",
      label: "Data nașterii",
      info: moment(beneficiaryData?.birthDate).format("DD.MM.YYYY"),
      disabled: true,
    },
    {
      type: "editableText",
      label: "CNP",
      info: beneficiaryData?.cnp,
      disabled: true,
    },
    {
      type: "notEditable",
      label: "Sex",
      info: beneficiaryData?.gender,
      disabled: true,
    },
    {
      type: "editableText",
      label: "Localitate",
      info: beneficiaryData?.city,
      disabled: true,
    },
    {
      type: "editableText",
      label: "Centru comunitar",
      info: beneficiaryData?.communityCenter?.name,
      disabled: true,
    },
    {
      type: "editableDropdown",
      label: "Cat. Grup. Țintă",
      info: beneficiaryData?.targetGroup?.code,
      disabled: true,
    },
    {
      type: "editableText",
      label: "Descrierea afecțiunii",
      info: beneficiaryData?.conditionsDescription,
      disabled: true,
    },
  ]);

  // LIFE CYCLE

  // EVENT HANDLERS

  const displayInfoTypes = (item: any) => {
    if (!editable) {
      if (item.label === "Cat. Grup. Țintă") {
        const targetGroup = localTargetGroupsList?.find(
          (findValue) => findValue?.key === beneficiary?.[6]?.info
        );
        return (
          <styled.InfoCategory>
            {targetGroup?.value || item.info}
          </styled.InfoCategory>
        );
      } else {
        return <styled.InfoCategory>{item.info}</styled.InfoCategory>;
      }
    }
    switch (item.type) {
      case "editableText":
        return (
          <CustomInput
            appearance="small"
            placeholder={item?.placeholder}
            name={item?.label}
            onChange={(event) =>
              handleElemsChange(item.label, event.target.value)
            }
            value={item.info}
          />
        );
      case "editableDropdown":
        const targetGroup = localTargetGroupsList?.find(
          (findValue) =>
            findValue?.value?.toString().toLowerCase() ===
            item?.info?.toString().toLowerCase()
        );

        return (
          <CustomSelect
            options={localTargetGroupsList}
            value={targetGroup?.key || item.info}
            onChange={(event) => {
              handleElemsChange(item.label, event.target.value);
            }}
          />
        );
      case "notEditable":
        return <styled.InfoCategory>{item.info}</styled.InfoCategory>;
      default:
        return null;
    }
  };

  const handleElemsChange = (name: string, value: string | boolean) => {
    if (editable) {
      setBeneficiary((prevBeneficiary) => {
        const updatedBeneficiary = prevBeneficiary.map((item) =>
          item.label === name ? { ...item, info: value } : item
        );
        return updatedBeneficiary;
      });
    }
  };

  const makeChanges = () => {
    setEditable(!editable);
  };

  const handleSave = async () => {
    const parts = beneficiary[1].info.split(".");
    const reformattedBirthdate = parts.join("-");

    await updateBeneficiaryDetailsRequest({
      birthDate: formatDateTime(reformattedBirthdate, "00:00:00"),
      city: beneficiary[4].info,
      cnp: beneficiary[2].info,
      communityCenterId: beneficiaryData?.communityCenter?.id,
      conditionsDescription: beneficiary[7].info,
      fullName: beneficiaryData?.fullName,
      gender: beneficiary[3].info,
      id: beneficiaryData?.id,
      targetGroupId: beneficiary?.[6]?.info,
    }).then(() => {
      setEditable(false);
    });
  };

  return (
    <styled.SideBeneficiaryContainer>
      <styled.TitleContainer>
        <CustomText type="H3" text={"Date beneficiar"} />
        <styled.IconWrapper>
          {editable === true ? (
            <OkIcon onClick={handleSave} />
          ) : (
            <EditIcon onClick={makeChanges} />
          )}
        </styled.IconWrapper>
      </styled.TitleContainer>
      <styled.InfoContainer>
        {beneficiary?.map((item, index) => (
          <styled.CategoryWrapper key={index}>
            <styled.TitleCategory>{item.label}</styled.TitleCategory>
            {displayInfoTypes(item)}

            {/*<CustomInput*/}
            {/*  appearance="small"*/}
            {/*  name={item?.label}*/}
            {/*  onChange={(event) =>*/}
            {/*    handleElemsChange(item.label, event.target.value)*/}
            {/*  }*/}
            {/*  value={item.info}*/}
            {/*/>*/}
          </styled.CategoryWrapper>
        ))}
      </styled.InfoContainer>
    </styled.SideBeneficiaryContainer>
  );
};

export default SideBeneficiaryStatus;
