import React, { useState } from "react";

// ASSETS
import { ReactComponent as BadgeIcon } from "assets/icons/BadgeIcon.svg";

// STYLES
import * as styled from "./ActivitiesTabListStyled";

// LIBRARIES
import moment from "moment/moment";
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { useAppDispatch } from "hooks/reduxHooks";

// MISC
import { IProps } from "./ActivitiesTabListModel";

// COMPONENTS
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import ActivityModal from "components/ActivityModal/ActivityModal";
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION
const ActivitiesTabList = (props: IProps) => {
  // PROPERTIES
  const { title = "", activityData, beneficiaryDetails } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();

  // STATE CONSTANTS
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activityDetails, setActivityDetails] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleModalOpen = async () => {
    setOpenModal(true);
    dispatch(
      handlePagePaginationChange({
        value: 1,
        clientPage: "clientBeneficiaryActivitiesPagination",
      })
    );
  };

  const handleOpenModalInEditMode = (activity) => {
    setOpenModal(true);
    setIsEditMode(true);
    setActivityDetails(activity);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setIsEditMode(false);
    setActivityDetails({});
  };

  return (
    <styled.ActivitiesTabListContainer>
      <styled.ActivitiesTabListHeader>
        <styled.ActivitiesTabListTitle>{title}</styled.ActivitiesTabListTitle>

        <styled.ActivitiesModalContainer>
          <CustomIconButton handleClick={handleModalOpen} appearance="primary">
            <styled.ActivitiesModalWrapper>
              <BadgeIcon />
              <styled.ActivitiesModalBtnText>
                Adauga activitate
              </styled.ActivitiesModalBtnText>
            </styled.ActivitiesModalWrapper>
          </CustomIconButton>
        </styled.ActivitiesModalContainer>
      </styled.ActivitiesTabListHeader>
      <styled.ActivitiesTabListBody>
        {activityData?.content?.length > 0 ? (
          activityData?.content?.map((activity, index) => {
            return (
              <styled.ActivityItemWrapper
                key={`activity-list--${index}`}
                onClick={() => handleOpenModalInEditMode(activity)}
              >
                <styled.ActivityMarkerSection>
                  <styled.ActivityMarkerDot />

                  {index !== activityData.length - 1 && (
                    <styled.ActivityMarkerLine />
                  )}
                </styled.ActivityMarkerSection>
                <styled.DateMarkerSection>
                  <styled.DateMarkerDate>
                    {moment(activity?.startAt).format("DD.MM.YYYY")}
                  </styled.DateMarkerDate>
                  <styled.DateMarkerTime>
                    {moment(activity?.startAt).format("HH:mm") +
                      " - " +
                      moment(activity?.endtAt).format("HH:mm")}
                  </styled.DateMarkerTime>
                </styled.DateMarkerSection>
                <styled.DetailsMarkerSection>
                  <styled.DetailsMarkerTitle>
                    {activity?.activity?.category?.name +
                      " - " +
                      activity?.activity?.name}
                  </styled.DetailsMarkerTitle>
                  <styled.DetailsMarkerAssignee>
                    {"Susţinut de: " + activity?.assignee}
                  </styled.DetailsMarkerAssignee>
                  <styled.DetailsMarkerDesc>
                    {activity?.description}
                  </styled.DetailsMarkerDesc>
                </styled.DetailsMarkerSection>
              </styled.ActivityItemWrapper>
            );
          })
        ) : (
          <styled.CustomTextWarningContainer>
            <CustomText
              type="H4"
              text="Nu sunt activitati disponibile pentru acest beneficiar"
            />
          </styled.CustomTextWarningContainer>
        )}
      </styled.ActivitiesTabListBody>

      <ActivityModal
        modalOpen={openModal}
        closeModal={handleModalClose}
        handleAdd={() => {}}
        activityDetails={activityDetails}
        isEditMode={isEditMode}
        beneficiaryId={beneficiaryDetails["id"]}
      />
    </styled.ActivitiesTabListContainer>
  );
};

export default ActivitiesTabList;
