export const cnpFlowData = {
  step1: [
    {
      type: "customText",
      value: "1. Adauga CPN beneficiar",
    },
    {
      type: "customInput",
      name: "CNP",
      placeholder: "CNP",
    },
  ],
  step2: [
    {
      type: "customText",
      value: "2. Nume si prenume beneficiar",
    },
    {
      type: "customInput",
      name: "firstName",
      placeholder: "Nume",
    },
    {
      type: "customInput",
      name: "lastName",
      placeholder: "Prenume",
    },
  ],
  step3: [
    {
      type: "customText",
      value: "3. Sex",
    },
    {
      type: "customCheckbox",
      name: "sex",
      options: ["Masculin", "Feminin"],
    },
  ],
  step4: [
    {
      type: "customText",
      value: "4. Descrierea conditiei",
    },

    {
      type: "customInput",
      name: "conditionsDescription",
      placeholder: "Conditie",
    },
  ],
  step5: [
    {
      type: "customText",
      value: "5. Domiciuliu",
    },

    {
      type: "customInput",
      name: "address",
      placeholder: "Adresa",
    },
  ],
  step6: [
    {
      type: "customText",
      value: "5. Incadreaza in grup tinta",
    },

    {
      type: "customSelect",
      name: "targetGroup",
      placeholder: "Grup tinta",
    },
  ],
  // step7: [
  //   {
  //     type: "customText",
  //     value: "6. Reprezentant legal",
  //   },
  //
  //   {
  //     type: "customCheckbox",
  //     name: "legalRep",
  //     options: ["Da", "Nu"],
  //   },
  // ],
};

export const legalPersonFlowData = {
  step1: [
    {
      type: "customText",
      value: "1. Adauga CPN reprezentant legal",
    },
    {
      type: "customInput",
      name: "CNP",
      placeholder: "CNP",
    },
  ],
  step2: [
    {
      type: "customText",
      value: "2. Nume si prenume reprezentant legal",
    },
    {
      type: "customInput",
      name: "firstName",
      placeholder: "Nume",
    },
    {
      type: "customInput",
      name: "lastName",
      placeholder: "Prenume",
    },
  ],
  step3: [
    {
      type: "customText",
      value: "3. Sex",
    },
    {
      type: "customCheckbox",
      name: "sex",
      options: ["Masculin", "Feminin"],
    },
  ],
  step4: [
    {
      type: "customText",
      value: "4. Tip act de indetitate si seria",
    },
    {
      type: "customCheckbox",
      name: "idType",
      options: ["CI", "BI"],
    },
    {
      type: "customInput",
      name: "serialNr",
      placeholder: "Serie act de identitate",
    },
  ],
  step5: [
    {
      type: "customText",
      value: "5. Domiciuliu",
    },

    {
      type: "customInput",
      name: "address",
      placeholder: "Adresa",
    },
  ],
};
