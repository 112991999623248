// LIBRARIES
import styled, { css } from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

// MISC

export const BarChartComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  flex-basis: 400px;
  flex-grow: 1;
  width: 100%;

  gap: ${SIZE.max16};
  justify-content: center;
  align-items: center;
  overflow: auto;

  border-radius: ${BORDER_RADIUS.cardRadius};
  background-color: ${COLORS.appWhite};
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
`;
export const SelectContainer = styled.div`
  width: 180px;

  div > div {
    padding: 4px 0px 4px 8px !important;
    margin: 0 !important;
    background-color: ${COLORS.appLightGrey} !important;

    &:focus {
      background-color: ${COLORS.appLightGrey} !important;
    }
  }
`;
export const BarChartHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  padding: ${SIZE.max16}${SIZE.max24};
  border-bottom: 2px solid ${COLORS.appLightGrey};
`;
export const BarChartBodyContainer = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
  overflow: auto;
  padding: 0 ${SIZE.max24};
`;
export const BarChartItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${SIZE.max16};
`;
export const BarChartItemTitleContainer = styled.div`
  white-space: nowrap;
  min-width: 60px;
  text-align: right;
`;
export const BarChartItemValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max8};
  width: 100%;
`;
export const BarChartLegend = styled.div`
  padding: 0 ${SIZE.max24} ${SIZE.max16} ${SIZE.max24};
  display: flex;
  gap: ${SIZE.max24};
  align-items: center;
  width: 100%;
`;
export const BarChartLegendItem = styled.div`
  display: flex;
  gap: ${SIZE.max8};
  align-items: center;
`;
export const BarChartDot = styled.div<{ color: string }>(
  ({ color }) => css`
    background-color: ${color};
    border-radius: 50%;
    width: 10px;
    height: 10px;
  `
);
