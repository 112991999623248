import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./CNPFlowStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import {
  useCreateBeneficiaryMutation,
  useGetAllTargetGroupsListQuery,
  useLazyGetBeneficiaryByCNPQuery,
} from "api/newDocumentApi";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";

// MISC
import { cnpFlowData } from "mocks/newDocumentData";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomRadioButton from "components/Atoms/CustomRadioButton/CustomRadioButton";
import CustomHeader from "components/CustomHeader/CustomHeader";
import { useDecodedJwt } from "hooks/useDecodedJwt";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";
// import useIsRequestLoading from "hooks/useRequestLoading";

// CONFIGURATION

const CNPFlow = () => {
  // PROPERTIES

  // API REQUESTS
  const { communityCenterId } = useDecodedJwt();

  const [
    getBeneficiaryByCNPRequest,
    { isLoading: isGetBeneficiaryByIdLoading },
  ] = useLazyGetBeneficiaryByCNPQuery();
  const [createBeneficiaryRequest, { isLoading: isCreateBeneficiaryLoading }] =
    useCreateBeneficiaryMutation();

  const { data: targetGroupsList, isLoading: isTargetGroupsLoading } =
    useGetAllTargetGroupsListQuery();

  let localTargetGroupsList = targetGroupsList?.map((item) => {
    return {
      key: item.id,
      value: item.code,
    };
  });

  const pageLoading =
    isGetBeneficiaryByIdLoading ||
    isCreateBeneficiaryLoading ||
    isTargetGroupsLoading;
  console.log("pageLoading", pageLoading);
  // useIsRequestLoading(pageLoading);

  // LIBRARY CONSTANTS
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // STATE CONSTANTS
  const [currentStep, setCurrentStep] = useState("step1");
  const [isCNPValid, setIsCNPValid] = useState(false);
  const [formState, setFormState] = useState({});

  // LIFE CYCLE
  useEffect(() => {
    const cnpValidator =
      /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;

    if (cnpValidator.test(formState?.["step1"]?.["CNP"])) {
      setIsCNPValid(true);
    } else {
      setIsCNPValid(false);
    }
    // eslint-disable-next-line
  }, [formState?.["step1"]]);

  // EVENT HANDLERS
  const displayInputTypes = (item: any) => {
    switch (item.type) {
      case "customText":
        return <CustomText type="H3" text={item.value} />;
      case "customInput":
        return (
          <CustomInput
            appearance="secondary"
            placeholder={item?.placeholder}
            name={item?.name}
            onChange={(event) =>
              handleElemsChange(event.target.name, event.target.value)
            }
            value={formState?.[currentStep]?.[item.name]}
          />
        );
      case "customCheckbox":
        return (
          <CustomRadioButton
            options={item?.options}
            onClick={(event) => handleElemsChange(item.name, event)}
            selectedOption={formState?.[currentStep]?.[item.name]}
          />
        );

      case "customSelect":
        return (
          <CustomSelect
            value={formState?.[currentStep]?.[item.name]}
            options={localTargetGroupsList}
            onChange={(event) =>
              handleElemsChange(item.name, event.target.value)
            }
          />
        );
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCurrentStep = async (step: string) => {
    let localCurrentStep = currentStep;

    let updatedStep: string = localCurrentStep.replace(
      /\d+/,
      (match: string): string => {
        return String(
          step === "next" ? parseInt(match) + 1 : parseInt(match) - 1
        );
      }
    );

    if (step === "next" && currentStep === "step1") {
      await getBeneficiaryByCNPRequest({
        cnpValue: formState?.["step1"]?.["CNP"],
      }).then((res) => {
        if (res?.["status"] === "rejected") {
          setCurrentStep(updatedStep);
          return;
        }

        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            hasUnsavedChanges: true,
            currentStep: 0,
            currentFlow: "modelFlow",
            currentBeneficiaryData: res?.data,
          })
        );
        setCurrentStep("step1");
      });

      return;
    }

    if (step === "next" && currentStep === "step6") {
      const localCNP = formState?.["step1"]?.["CNP"];
      let localGender = "";
      let localBirthDate = "";

      const year = parseInt(localCNP.substring(1, 3));
      const month = parseInt(localCNP.substring(3, 5));
      const day = parseInt(localCNP.substring(5, 7));
      const genderDigit = parseInt(localCNP.substring(0, 1));

      localGender = genderDigit % 2 === 0 ? "F" : "M";

      const currentYear = new Date().getFullYear() % 100;
      const birthYear = year <= currentYear ? 2000 + year : 1900 + year;

      const date = new Date(birthYear, month - 1, day);

      localBirthDate = date?.toISOString();

      const body = {
        birthDate: localBirthDate,
        city: formState?.["step5"]?.["address"],
        cnp: localCNP,
        communityCenterId: communityCenterId,
        conditionsDescription: formState?.["step4"]?.["conditionsDescription"],
        fullName:
          formState?.["step2"]?.["firstName"] +
          " " +
          formState?.["step2"]?.["lastName"],
        gender: localGender,
        targetGroupId: formState?.["step6"]?.["targetGroup"],
      };

      await createBeneficiaryRequest(body).then((res) => {
        if (res?.["status"] === "rejected") {
          setCurrentStep("step1");
          return;
        }
        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            hasUnsavedChanges: true,
            currentStep: 0,
            currentFlow: "modelFlow",
            currentBeneficiaryData: res?.["data"],
          })
        );
        setCurrentStep("step1");
      });
    }

    if (step === "next" && currentStep === "step7") {
      if (formState?.["step7"]?.["legalRep"] === "Nu") {
        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            hasUnsavedChanges: true,
            currentStep: 0,
            currentFlow: "legalPersonFlow",
          })
        );
        setCurrentStep("step1");
      } else {
        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            hasUnsavedChanges: true,
            currentStep: 0,
            currentFlow: "modelFlow",
          })
        );
        setCurrentStep("step1");
      }
      return;
    }

    setCurrentStep(updatedStep);
  };

  const handleElemsChange = (name: string, value: string | boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [currentStep]: {
        ...prevState[currentStep],
        [name]: value,
      },
    }));
  };

  const isCurrentStepEmpty = () => {
    const currentStepData = formState[currentStep];

    if (currentStep === "step1") {
      return isCNPValid;
    } else if (currentStepData && typeof currentStepData === "object") {
      return Object.values(currentStepData).some((value) => !!value);
    }

    return false;
  };

  return (
    <styled.NewDocumentPageContainer>
      <CustomHeader text={"Inapoi la Sablon"} handleGoBack={handleGoBack} />

      <styled.NewDocumentPageContentWrapper>
        <styled.SubtitleText>
          <CustomText type="H2" text={newDocumentSelector?.title} />
        </styled.SubtitleText>

        {Object.entries(cnpFlowData).map(([key, value]) => {
          return value.map((item, index) => {
            return (
              currentStep === key && (
                <styled.ComponentWrapper key={`component-wrapper--${index}`}>
                  {displayInputTypes(item)}
                </styled.ComponentWrapper>
              )
            );
          });
        })}
      </styled.NewDocumentPageContentWrapper>
      <styled.StepButtonContainer>
        {currentStep !== "step1" && (
          <CustomIconButton
            handleClick={() => handleCurrentStep("back")}
            appearance="backButton"
          />
        )}

        <styled.NextButtonContainer isEmpty={isCurrentStepEmpty()}>
          <CustomButton
            text="Mergi mai departe"
            handleClick={
              !isCurrentStepEmpty() ? () => {} : () => handleCurrentStep("next")
            }
            appearance={!isCurrentStepEmpty() ? "disabled" : "primary"}
          />
        </styled.NextButtonContainer>
      </styled.StepButtonContainer>
    </styled.NewDocumentPageContainer>
  );
};

export default CNPFlow;
