import React from "react";

// ASSETS

// STYLES

// LIBRARIES
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useDispatch, useSelector } from "react-redux";
import { handleChangeSnackBar } from "slices/SessionSlice/sessionSlice";
import {
  isRequestLoadingSelector,
  snackBar,
  snackBarAlert,
} from "slices/SessionSlice/sessionSliceSelectors";
import { useAppSelector } from "hooks/reduxHooks";
import { SkeletonTheme } from "react-loading-skeleton";

// MISC

// COMPONENTS
import AppRoutes from "./config/AppRoutes";
import LoadingAnimation from "components/Atoms/LoadingAnimation/LoadingAnimation";

// CONFIGURATION

const App = () => {
  // API REQUESTS

  // LIBRARY CONSTANTS
  const dispatch = useDispatch();
  const snackBarData = useSelector(snackBar);
  const snackBarAlertData = useSelector(snackBarAlert);
  const isRequestLoadingData = useAppSelector(isRequestLoadingSelector);
  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleClose = (_, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      handleChangeSnackBar({
        snackBar: { open: false, autoHideDuration: 2000 },
      })
    );
  };

  return (
    <SkeletonTheme baseColor="#ededed" highlightColor="#d3d3d3">
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} element={<AppRoutes />} />
        </Routes>

        <Snackbar {...snackBarData} onClose={handleClose}>
          <MuiAlert {...snackBarAlertData} onClose={handleClose}>
            {snackBarAlertData?.message}
          </MuiAlert>
        </Snackbar>

        {isRequestLoadingData && <LoadingAnimation />}
      </BrowserRouter>
    </SkeletonTheme>
  );
};

export default App;
