import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./ActivitiesTabStyled";
import { IProps } from "view/BeneficiaryPage/components/ActivitiesTab/ActivitiesTabModel";

// LIBRARIES
import { useGetBeneficiaryActivitiesQuery } from "api/beneficiaryApi";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";
import { clientBeneficiaryActivitiesPaginationSelector } from "slices/ClientSlice/clientSliceSelectors";
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";

// MISC

// COMPONENTS
import ActivitiesTabList from "components/ActivitiesTabList/ActivitiesTabList";
import ActivityCheckBoxList from "components/ActivityCheckBoxList/ActivityCheckBoxList";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import LoadingAnimation from "components/Atoms/LoadingAnimation/LoadingAnimation";

// CONFIGURATION
const ActivityTab = (props: IProps) => {
  // PROPERTIES
  const { beneficiaryData, beneficiaryDetails } = props;

  // LIBRARY CONSTANTS
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);
  const clientBeneficiaryActivitiesPaginationDetails = useAppSelector(
    clientBeneficiaryActivitiesPaginationSelector
  );
  const dispatch = useAppDispatch();

  // STATE CONSTANTS
  const [activityCategoryIds, setActivityCategoryIds] = useState<string>("");

  // API REQUESTS
  const {
    data: beneficiaryActivitiesList,
    isLoading: isBeneficiaryActivitiesLoading,
  } = useGetBeneficiaryActivitiesQuery({
    beneficiaryId: beneficiaryData?.id,
    activityCategoryIds: activityCategoryIds,
    searchQuery: headerSearchValueDetails,
    page: clientBeneficiaryActivitiesPaginationDetails.pageNumber - 1,
    size: clientBeneficiaryActivitiesPaginationDetails.pageSize,
  });

  // const pageLoading = isBeneficiaryActivitiesLoading;
  //
  // useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    clientPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        clientPage,
      })
    );
  };

  return (
    <styled.ActivityTabContainer>
      <styled.ActivitiesListContainer>
        <styled.ActivitiesListWrappers>
          {isBeneficiaryActivitiesLoading ? (
            <LoadingAnimation />
          ) : (
            <ActivitiesTabList
              title="Activităţi"
              activityData={beneficiaryActivitiesList}
              beneficiaryDetails={beneficiaryDetails}
            />
          )}
        </styled.ActivitiesListWrappers>
        {Math.ceil(
          beneficiaryActivitiesList?.["totalElements"] /
            clientBeneficiaryActivitiesPaginationDetails?.pageSize
        ) > 1 && (
          <styled.PaginationContainer>
            <CustomPagination
              page={clientBeneficiaryActivitiesPaginationDetails?.pageNumber}
              count={Math.ceil(
                beneficiaryActivitiesList?.["totalElements"] /
                  clientBeneficiaryActivitiesPaginationDetails?.pageSize
              )}
              onChange={(event, value) =>
                handlePageChange(
                  event,
                  value,
                  "clientBeneficiaryActivitiesPagination"
                )
              }
            />
          </styled.PaginationContainer>
        )}
      </styled.ActivitiesListContainer>
      <styled.CheckboxListContainer>
        <ActivityCheckBoxList setActivityCategoryIds={setActivityCategoryIds} />
      </styled.CheckboxListContainer>
    </styled.ActivityTabContainer>
  );
};

export default ActivityTab;
