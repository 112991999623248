// LIBRARIES
import styled, { css } from "styled-components";
import { Select } from "@mui/material";

// MISC
import { IStyled } from "./CustomSelectModel";
import { BORDER_RADIUS, FONT_SIZE, COLORS, SIZE } from "constants/variables";

export const SelectComponent = styled(Select)<IStyled>(
  ({ appearance }) => css`
    ${appearance === "primary" &&
    css`
      .MuiSelect-icon {
        color: ${COLORS.appDarkGrey};
        margin-right: ${FONT_SIZE.max12};
      }

      .MuiSelect-select {
        background-color: ${COLORS.appWhite};
        border-radius: ${BORDER_RADIUS.cardRadius};
        padding: ${SIZE.max16};
        color: ${COLORS.appBlack};
      }
      .MuiSelect-select::placeholder {
      }
    `}
    ${appearance === "secondary" && css``}
  `
);
