import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./DndColumnStyled";

// LIBRARIES
import { Droppable, DroppableProps } from "react-beautiful-dnd";

// MISC
import { IProps } from "./DndColumnModel";

// REDUX

// COMPONENTS
import DndCard from "components/CustomDragNDrop/DndCard/DndCard";

// CONFIGURATION

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};
const DndColumn = (props: IProps) => {
  // PROPERTIES
  const { droppableId, column } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.DndColumnContainer>
      <StrictModeDroppable droppableId={droppableId} key={droppableId}>
        {(provided, snapshot) => {
          return (
            <styled.DndCardContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column?.map((item, index) => {
                return (
                  <DndCard key={item.id.toString()} item={item} index={index} />
                );
              })}
              {provided.placeholder}
            </styled.DndCardContainer>
          );
        }}
      </StrictModeDroppable>
    </styled.DndColumnContainer>
  );
};

export default DndColumn;
