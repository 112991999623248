import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./ExportReportModalStyled";

// LIBRARIES

// MISC
import { IProps } from "./ExportReportModalModel";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomCheckedBox from "components/Atoms/CustomCheckedBox/CustomCheckedBox";

// CONFIGURATION

const ExportReportModal = (props: IProps) => {
  // PROPERTIES
  const { modalOpen, closeModal } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const [checkedList, setCheckedList] = useState({});

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleProcessAddButton = async () => {
    // const checkedOptions = modalContent.options.filter(
    //   (option) => checkedList[option]
    // );
  };

  const handleCheck = (option) => {
    setCheckedList((prevCheckedList) => ({
      ...prevCheckedList,
      [option]: !prevCheckedList[option],
    }));
  };

  const modalContent = {
    title: "Exportă raport",
    options: [
      "Tip activitate",
      "Responsabil Activitate",
      "Număr Participanți",
      "Categorii Servicii",
      "Dată Activitate",
      "Nume Prenume Beneficiar",
      "Centru Communitar",
      "Județ",
      "Grup Țintă",
    ],
    button: "Exportă raport",
  };

  return (
    <styled.ModalComponent open={modalOpen} onClose={closeModal}>
      <styled.ModalWrapper>
        <styled.TitleContainer>
          <CustomText type="H6" text={modalContent.title} />
        </styled.TitleContainer>
        <styled.ModalContent>
          {modalContent.options.map((option, index) => (
            <styled.CheckBoxWrapper key={`modal-export-key--${index}`}>
              <CustomCheckedBox
                key={index}
                appearance={"colorChange"}
                checked={checkedList[option] || false}
                label={option}
                onChange={() => handleCheck(option)}
              />
            </styled.CheckBoxWrapper>
          ))}
        </styled.ModalContent>
        <styled.ButtonsContainer>
          <styled.IndividualButtonWrapper>
            <CustomButton
              appearance="cancel"
              text="Anulează"
              handleClick={closeModal}
            />
          </styled.IndividualButtonWrapper>
          <styled.IndividualButtonWrapper>
            <CustomButton
              appearance="middleBlue"
              text={modalContent.button}
              handleClick={handleProcessAddButton}
            />
          </styled.IndividualButtonWrapper>
        </styled.ButtonsContainer>
      </styled.ModalWrapper>
    </styled.ModalComponent>
  );
};

export default ExportReportModal;
