// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const AttachmentFileContainer = styled.div`
  width: 100%;

  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //overflow-y: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: ${SIZE.max24};
  border-bottom: 2px solid ${COLORS.appLightGrey};
`;
export const AddFileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;

  svg {
    aspect-ratio: 1;
    width: 20px;
    height: 20px;
  }
`;
export const LabelForInput = styled.label`
  color: ${COLORS.appBlue};
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.semiBold};
  background-color: ${COLORS.appWhite};
  border: none;
`;
export const InputToHide = styled.input`
  display: none;
`;
export const FilesContainer = styled.div`
  padding: ${SIZE.max24};

  display: flex;
  flex-direction: column;
  min-height: 150px;

  overflow-y: auto;
`;
export const FileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.appLightGrey};
`;
export const NoFilesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-block: 5px;
`;

export const IconWrapper = styled.div`
  margin-right: ${SIZE.max8};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileDetailsContainer = styled.div`
  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.semiBold};
  padding-block: 5px;
  display: flex;
  align-items: center;
  gap: ${SIZE.max8};
  /* width: 100%; */
  svg {
    width: 18px;
    height: 18px;
  }
`;
export const Span = styled.span`
  display: inline;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;
export const HandleFilesWrapper = styled.div`
  display: flex;
  gap: ${SIZE.max8};
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`;
