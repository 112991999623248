import { createSelector } from "reselect";
import { clientSelector } from "slices/ClientSlice/clientSlice";

export const clientBeneficiaryPaginationSelector = createSelector(
  clientSelector,
  (client) => client.clientBeneficiaryPagination
);
export const clientBeneficiaryActivitiesPaginationSelector = createSelector(
  clientSelector,
  (client) => client.clientBeneficiaryActivitiesPagination
);
export const clientControlPagePaginationSelector = createSelector(
  clientSelector,
  (client) => client.clientControlPagePagination
);
export const clientBeneficiaryPageDocumentsSelector = createSelector(
  clientSelector,
  (client) => client.clientBeneficiaryPageDocumentsPagination
);
export const statisticsPagePaginationSelector = createSelector(
  clientSelector,
  (client) => client.statisticsPagePagination
);
