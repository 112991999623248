interface IPaginationDetails {
  pageCount: number; // total number of pages
  pageSize: number; // how many elem page should have
  pageNumber: number; // Current page
}

interface ITemplateInitialState {
  isLoading: boolean;
  operatorsPagination: IPaginationDetails;
  activitiesPagination: IPaginationDetails;
  communityCentersPagination: IPaginationDetails;
  targetGroupsPagination: IPaginationDetails;
  usersLogsPagination: IPaginationDetails;
}

export const initialState: ITemplateInitialState = {
  isLoading: false,
  operatorsPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  activitiesPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  communityCentersPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  targetGroupsPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  usersLogsPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
};
