import React from "react";

// ASSETS

// STYLES
import * as styled from "./DocumentsTabStyled";

// LIBRARIES
import {
  useGetBeneficiaryCompletedFormsQuery,
  useLazyGetCompletedFormByIdQuery,
} from "api/beneficiaryApi";
import useTableHeadDetails from "hooks/useTableHeadHook";
import { useAppSelector } from "hooks/reduxHooks";
import { clientBeneficiaryPageDocumentsSelector } from "slices/ClientSlice/clientSliceSelectors";
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { useDispatch } from "react-redux";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import { useNavigate } from "react-router-dom";

// MISC
import { operatorsTableHeadValues } from "mocks/operatorMock";
import { IProps } from "view/BeneficiaryPage/components/DocumentsTab/DocumentsTabModel";

// COMPONENTS
import AttachmentFile from "components/AttachmentFile/AttachmentFile";
import CustomTable from "components/Atoms/CustomTable/CustomTable";

import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomText from "components/Atoms/CustomText/CustomText";
import LoadingAnimation from "components/Atoms/LoadingAnimation/LoadingAnimation";
import useIsRequestLoading from "hooks/useRequestLoading";

// CONFIGURATION
const DocumentsTab = (props: IProps) => {
  // PROPERTIES
  const { beneficiaryData } = props;

  // LIBRARY CONSTANTS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(operatorsTableHeadValues);

  const clientBeneficiaryPageDocumentsDetails = useAppSelector(
    clientBeneficiaryPageDocumentsSelector
  );

  const newDocumentSelector = useAppSelector(newDocumentDataSelector);

  // API REQUESTS
  const { data: beneficiaryFormList, isLoading: isBeneficiaryFormListLoading } =
    useGetBeneficiaryCompletedFormsQuery({
      page: clientBeneficiaryPageDocumentsDetails?.pageNumber - 1,
      size: clientBeneficiaryPageDocumentsDetails?.pageSize,
      sortField: tableHeadCell.sortField,
      sortOrder: tableHeadCell.sortOrder,
      beneficiaryId: beneficiaryData["id"],
    });

  const [
    getCompletedFormByIdRequest,
    { isLoading: isGetCompletedFormByIdLoading },
  ] = useLazyGetCompletedFormByIdQuery();

  // const pageLoading =
  //   isBeneficiaryFormListLoading || isGetCompletedFormByIdLoading;
  const pageLoading = isGetCompletedFormByIdLoading;

  useIsRequestLoading(pageLoading);

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const onRowClick = async (row: any) => {
    await getCompletedFormByIdRequest({
      formId: row["id"],
    }).then((res) => {
      let counter = 0;

      let localSections = res?.data?.sections?.map((section, index) => {
        return {
          ...section,
          completed: index === 0 ? 1 : 0,
          questions: section?.questions?.map((question) => {
            const localOptions = question?.answers?.map((option) => {
              return {
                value: option?.answer,
                key: option?.answerId,
              };
            });
            return {
              ...question,
              name: `${question?.type}-${question?.questionId}`,
              step: counter++,
              options: localOptions,
              completedAnswer: question?.completedAnswer || "",
              selectedOptions: question?.selectedOptions || [],
            };
          }),
        };
      });

      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          displayMode: "viewMode",
          hasUnsavedChanges: true,
          completionDuration: res?.data?.duration,
          title: res?.data?.title,
          currentFlow: "modelFlow",
          formId: row["id"],
          currentStep: 0,
          currentBeneficiaryData: beneficiaryData,
          sections: [...localSections],
        })
      );
      navigate(`/new-document`, { state: { formId: row["id"] } });
    });
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    clientPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        clientPage,
      })
    );
  };

  return (
    <styled.DocumentsTabContainer>
      <styled.DocumentsTableContainer>
        <styled.TableWrapper>
          {isBeneficiaryFormListLoading ? (
            <LoadingAnimation />
          ) : (
            <styled.CustomTableWrapper>
              {beneficiaryFormList?.content?.length > 0 ? (
                <CustomTable
                  banList={["id"]}
                  headerData={tableHeadDetails}
                  bodyData={beneficiaryFormList?.content}
                  onHeadCellPress={handleChangeSortOrder}
                  onRowClick={onRowClick}
                />
              ) : (
                <styled.CustomTextWarningContainer>
                  <CustomText
                    type="H4"
                    text="Nu sunt documente disponibile pentru acest beneficiar"
                  />
                </styled.CustomTextWarningContainer>
              )}
            </styled.CustomTableWrapper>
          )}
        </styled.TableWrapper>

        {Math.ceil(
          beneficiaryFormList?.["totalElements"] /
            clientBeneficiaryPageDocumentsDetails?.pageSize
        ) > 1 && (
          <styled.PaginationContainer>
            <CustomPagination
              page={clientBeneficiaryPageDocumentsDetails?.pageNumber}
              count={Math.ceil(
                beneficiaryFormList?.["totalElements"] /
                  clientBeneficiaryPageDocumentsDetails?.pageSize
              )}
              onChange={(event, value) =>
                handlePageChange(
                  event,
                  value,
                  "clientBeneficiaryPageDocumentsPagination"
                )
              }
            />
          </styled.PaginationContainer>
        )}
      </styled.DocumentsTableContainer>
      <styled.AttachmentFileContainer>
        <AttachmentFile beneficiaryData={beneficiaryData} />
      </styled.AttachmentFileContainer>
    </styled.DocumentsTabContainer>
  );
};

export default DocumentsTab;
