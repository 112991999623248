// LIBRARIES
import styled from "styled-components";

// MISC
import { SIZE } from "constants/variables";

export const CustomDragNDropContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  padding-bottom: 20px;
`;
export const DndContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DndTitleContainer = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const DndAddMoreContainer = styled.div`
  cursor: pointer;
`;
