import React from "react";

// ASSETS

// STYLES
import * as styled from "./SideMenuStyled";

// LIBRARIES

// MISC
import { IProps } from "./SideMenuModel";

// COMPONENTS
import NavContent from "components/SideMenu/NavContent/NavContent";
import SideMenuStatus from "components/SideMenu/SideMenuStatus/SideMenuStatus";

// CONFIGURATION
const SideMenu = (props: IProps) => {
  // PROPERTIES
  const { side = "left" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const renderComponent = () => {
    switch (side) {
      case "left":
        return <NavContent />;
      case "right":
        return <SideMenuStatus />;
    }
  };

  return (
    <styled.SideMenuContainer>{renderComponent()}</styled.SideMenuContainer>
  );
};

export default SideMenu;
