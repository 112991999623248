// @ts-nocheck

import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomTimePickerStyled";

// LIBRARIES
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ro } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// MISC
import { IProps } from "./CustomTimePickerModel";

// COMPONENTS

// CONFIGURATION

const CustomTimePicker = (props: IProps) => {
  // PROPERTIES
  const {
    name = "",
    value = new Date().toDateString(),
    handleChange,
    label = "",
    placeholder = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
      <styled.DatePickerComponent
        label={label}
        value={value ? new Date(Date.parse(value)) : null}
        ampm={false}
        InputProps={{
          disableUnderline: true,
        }}
        slotProps={{ textField: { placeholder: placeholder } }}
        onChange={(newDate: string | null) => handleChange(newDate, name)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
