// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";
import { IStyled } from "components/Atoms/CustomRadioButton/CustomRadioButtonModel";

export const CustomCheckBoxListContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding: ${SIZE.max24};
  width: 100%;
  height: 100%;
  border-radius: ${BORDER_RADIUS.cardRadius};

  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};
`;

export const TitleContainer = styled.div`
  white-space: nowrap;
`;

export const CheckBoxListWrapper = styled.div<IStyled>`
  width: 100%;
`;
export const RadioLabel = styled.label<IStyled>``;
export const ButtonWrapper = styled.div<IStyled>(
  ({ checked }) => css`
    && {
      //height: 56px;
      background-color: ${COLORS.appWhite};
      margin-bottom: ${SIZE.max16};
      border-radius: ${BORDER_RADIUS.cardRadius};
      display: flex;
      align-items: center;
      //padding: 16px;
      cursor: pointer;
    }
  `
);
export const OptionText = styled.span<IStyled>`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max16};
  font-weight: ${FONT_WEIGHT.semiBold};
  margin-left: 12px;
`;
export const SvgWrapper = styled.span<IStyled>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;
