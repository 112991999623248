// @ts-nocheck

// ASSETS
import { ReactComponent as CheckBoxChecked } from "assets/icons/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/CkeckBokUnchecked.svg";

// STYLES
import * as styled from "./CustomRadioButtonStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomRadioButtonModel";
import { QUESTION_TYPES } from "constants/variables";

// COMPONENTS

// CONFIGURATION

const CustomRadioButton = (props: IProps) => {
  // PROPERTIES
  const {
    options = [],
    appearance = "primary",
    onClick = () => {},
    selectedOption = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const translateRadioValues = (selectedOption) => {
    switch (selectedOption) {
      case QUESTION_TYPES.ONLY_RADIO_BUTTON:
        return "Selecţie unică";
      case QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS:
        return "Selecţie multiplă";
      case QUESTION_TYPES.ONLY_SHORT_ANSWER:
        return "Descriere scurtă";
      case QUESTION_TYPES.ONLY_LONG_ANSWER:
        return "Descriere paragraf";
      case QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER:
        return "Selecţie unică şi descriere scurtă";
      case QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER:
        return "Selecţie multiplă şi descriere scurtă";
      case QUESTION_TYPES.NEW_SECTION:
        return "Secțiune nouă";
      default:
        return selectedOption;
    }
  };

  return (
    <styled.RadioListWrapper>
      {options.map((option, index) => (
        <styled.RadioLabel key={index}>
          <styled.ButtonWrapper
            appearance={appearance}
            checked={selectedOption === option ? "checked" : "unchecked"}
            onClick={() => onClick(option)}
          >
            <styled.SvgWrapper>
              {selectedOption === option ? (
                <CheckBoxChecked />
              ) : (
                <CheckBoxUnchecked />
              )}
            </styled.SvgWrapper>

            <styled.OptionText>
              {translateRadioValues(option)}
            </styled.OptionText>
          </styled.ButtonWrapper>
        </styled.RadioLabel>
      ))}
    </styled.RadioListWrapper>
  );
};

export default CustomRadioButton;
