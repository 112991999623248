import React from "react";

// ASSETS

// STYLES
import * as styled from "./DndCardStyled";

// LIBRARIES
import { Draggable } from "react-beautiful-dnd";

// MISC
import { IProps } from "./DndCardModel";
import CustomText from "components/Atoms/CustomText/CustomText";

// REDUX

// COMPONENTS

// CONFIGURATION
const DndCard = (props: IProps) => {
  // PROPERTIES
  const { item, index } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.DndCardContainer>
      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
        {(provided, snapshot) => {
          return (
            <styled.DndCardWrapper
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
            >
              <CustomText type="H5" text={item.description} />
              <CustomText type="H7" text={item.name} />
            </styled.DndCardWrapper>
          );
        }}
      </Draggable>
    </styled.DndCardContainer>
  );
};

export default DndCard;
