// @ts-nocheck

// ASSETS
import { ReactComponent as CheckBoxChecked } from "assets/icons/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/CkeckBokUnchecked.svg";

// STYLES
import * as styled from "./CustomMultipleRadioButtonStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomMultipleRadioButtonModel";

// COMPONENTS

// CONFIGURATION

const CustomMultipleRadioButton = (props: IProps) => {
  // PROPERTIES
  const {
    options = [],
    appearance = "primary",
    onClick = () => {},
    selectedOption = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.RadioListWrapper>
      <styled.RadioListWrapper>
        {options?.map((option, index) => (
          <styled.RadioLabel key={index}>
            <styled.ButtonWrapper
              appearance={appearance}
              checked={selectedOption === option?.key ? "checked" : "unchecked"}
              onClick={() => onClick(option.key)}
            >
              <styled.SvgWrapper>
                {selectedOption?.includes(option?.key) ? (
                  <CheckBoxChecked />
                ) : (
                  <CheckBoxUnchecked />
                )}
              </styled.SvgWrapper>

              <styled.OptionText>{option?.value}</styled.OptionText>
            </styled.ButtonWrapper>
          </styled.RadioLabel>
        ))}
      </styled.RadioListWrapper>
    </styled.RadioListWrapper>
  );
};

export default CustomMultipleRadioButton;
