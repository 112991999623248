import React from "react";

// STYLES
import * as styled from "./CustomAccordionTableStyled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// LIBRARIES
import moment from "moment";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

// MISC
import { IProps } from "./CustomAccordionTableModel";
import { COLORS } from "constants/variables";
import {
  TableAddIcon,
  TableCrossIcon,
  TableDeleteIcon,
  TableEditIcon,
} from "./CustomAccordionTableStyled";
import { translateAppRoles } from "helpers/globalFunc";

// REDUX

// COMPONENTS

const Row = (props) => {
  const {
    row,
    hasAction = false,
    onEditPress = () => {},
    onDeletePress = () => {},
    onAddPress = () => {},
    onRowClick = () => {},
    banList = [],
  } = props;

  const [open, setOpen] = React.useState(false);

  let formats = [moment.ISO_8601, "MM/DD/YYYY  :)  HH*mm*ss"];

  const displayStatusType = (value: string = "", row: any = "") => {
    let textColor = row?.isActive ? COLORS.appBlack : COLORS.appDarkGrey;

    let statusText =
      value === "true" ? "Activ" : value === "false" ? "Inactiv" : value;

    const formatTime = moment(statusText, formats, true).isValid()
      ? moment(statusText).format("DD/MM/YYYY")
      : statusText;

    if (value === "false") {
      textColor = COLORS.appRed;
    } else if (value === "true") {
      textColor = COLORS.appGreen;
    }

    return (
      <styled.TableStatusCellContainer>
        <styled.TableStatusCellText value={textColor}>
          {translateAppRoles(formatTime || "-")}
        </styled.TableStatusCellText>
      </styled.TableStatusCellContainer>
    );
  };

  return (
    <React.Fragment>
      <styled.TableRowComp>
        {Object.entries(row).map(([key, value], objIndex) => {
          return (
            !banList.includes(key) && (
              <styled.TableCellComp
                key={`custom-table-row-cell--${key}`}
                onClick={() => onRowClick(row)}
                style={{ width: "30%", paddingLeft: 15 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {objIndex === 1 && (
                    <IconButton onClick={() => setOpen(!open)}>
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  )}
                  {displayStatusType(value.toString(), row)}
                </div>
              </styled.TableCellComp>
            )
          );
        })}
        {hasAction && (
          <TableCell style={{ width: "10%" }}>
            <styled.TableActionIcons>
              <styled.TableActionAddIconContainer
                onClick={() => onAddPress(row)}
              >
                <TableAddIcon />
              </styled.TableActionAddIconContainer>
              <styled.TableActionEditIconContainer
                onClick={() => onEditPress(row, null, "service")}
              >
                <TableEditIcon />
              </styled.TableActionEditIconContainer>

              {row["isActive"] ? (
                <styled.TableActionDeleteIconContainer
                  onClick={() => onDeletePress(row, null, "service")}
                >
                  <TableDeleteIcon />
                </styled.TableActionDeleteIconContainer>
              ) : (
                <styled.TableActionCrossIconContainer
                  onClick={() => onDeletePress(row, null, "service")}
                >
                  <TableCrossIcon />
                </styled.TableActionCrossIconContainer>
              )}
            </styled.TableActionIcons>
          </TableCell>
        )}
      </styled.TableRowComp>
      <styled.TableRowComp>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Collapse in={open}>
            <Table>
              <TableBody
                style={{
                  backgroundColor: COLORS.appLightGrey,
                }}
              >
                {row["activities"].map((historyRow, index) => (
                  <TableRow
                    key={`sub-cat--${index}`}
                    style={{
                      backgroundColor: COLORS.appLightGrey,
                    }}
                  >
                    <TableCell
                      style={{
                        width: "30%",
                        paddingLeft: 55,
                      }}
                    >
                      {displayStatusType(
                        historyRow.name.toString(),
                        historyRow
                      )}
                    </TableCell>
                    <TableCell style={{ width: "30%", paddingLeft: 15 }}>
                      {displayStatusType(
                        moment(historyRow.createdAt).format("DD/MM/YYYY"),
                        historyRow
                      )}
                    </TableCell>
                    <TableCell style={{ width: "30%", paddingLeft: 15 }}>
                      {displayStatusType(
                        moment(historyRow.lastUpdateAt).format("DD/MM/YYYY"),
                        historyRow
                      )}
                    </TableCell>

                    {hasAction && (
                      <TableCell style={{ width: "10%" }}>
                        <styled.TableActionIcons>
                          <styled.TableActionEditIconContainer
                            onClick={() =>
                              onEditPress(row, historyRow, "activity")
                            }
                          >
                            <TableEditIcon />
                          </styled.TableActionEditIconContainer>

                          {historyRow["isActive"] ? (
                            <styled.TableActionDeleteIconContainer
                              onClick={() =>
                                onDeletePress(row, historyRow, "activity")
                              }
                            >
                              <TableDeleteIcon />
                            </styled.TableActionDeleteIconContainer>
                          ) : (
                            <styled.TableActionCrossIconContainer
                              onClick={() =>
                                onDeletePress(row, historyRow, "activity")
                              }
                            >
                              <TableCrossIcon />
                            </styled.TableActionCrossIconContainer>
                          )}
                        </styled.TableActionIcons>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </styled.TableRowComp>
    </React.Fragment>
  );
};

const CustomTable = (props: IProps) => {
  // PROPS
  const {
    hasAction = false,
    hasAccordion = false,
    onEditPress = () => {},
    onDeletePress = () => {},
    onHeadCellPress = () => {},
    onAddPress = () => {},
    headerData = [],
    bodyData = [],
    banList = [],
  } = props;

  // CONSTANTS USING LIBRARIES

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <styled.TableContainer>
      <Table>
        <TableHead className="custom-table-head">
          <TableRow className="custom-table-head-row">
            {headerData.map((cell, index) => {
              return (
                <TableCell
                  className="custom-table-head-cell"
                  key={`custom-table-row-header-cell--${index}`}
                >
                  <styled.TableHeadCellContainer
                    onClick={() => onHeadCellPress(cell)}
                  >
                    {cell.name}
                    <styled.TableHeadCellArrowsContainer>
                      <styled.TableStatusCellTopArrow
                        isHighlighted={cell.sortOrder === "asc"}
                      />
                      <styled.TableStatusCellBottomArrow
                        isHighlighted={cell.sortOrder === "desc"}
                      />
                    </styled.TableHeadCellArrowsContainer>
                  </styled.TableHeadCellContainer>
                </TableCell>
              );
            })}
            {hasAction && (
              <TableCell className="custom-table-head-cell" align="right" />
            )}
          </TableRow>
        </TableHead>
        <TableBody className="custom-table-body">
          {bodyData?.map((row, index) => (
            <Row
              key={`row-table--${index}`}
              row={row}
              onEditPress={onEditPress}
              onDeletePress={onDeletePress}
              onHeadCellPress={onHeadCellPress}
              hasAction={hasAction}
              hasAccordion={hasAccordion}
              onAddPress={onAddPress}
              headerData={headerData}
              bodyData={bodyData}
              banList={banList}
            />
          ))}
        </TableBody>
      </Table>
    </styled.TableContainer>
  );
};

export default CustomTable;
