import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./TemplatesListPageStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { handleUpdateTemplateData } from "slices/TemplateSlice/templateSlice";
import { templateDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import {
  useGetAllFormCategoriesQuery,
  useGetFormModelsByCategoryQuery,
  useLazyGetFormModelByIdQuery,
} from "api/adminApi";

// MISC
import { renderData } from "helpers/globalFunc";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import TemplatesListCard from "components/TemplatesListCard/TemplatesListCard";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import useIsRequestLoading from "hooks/useRequestLoading";
import moment from "moment/moment";

// CONFIGURATION

const TemplatesListPagePage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // STATE CONSTANTS
  const [value, setValue] = useState("1");
  const [currentCardId, setCurrentCardId] = useState<number>(null);
  const [currentCloneCardId, setCloneCurrentCardId] = useState<number>(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    documentName: "",
    documentTypeId: "",
    documentTypeValue: "",
  });

  const templateDateSelector = useAppSelector(templateDataSelector);

  // API REQUESTS
  const { data: formCategoriesData, isLoading: isFormCategoriesLoading } =
    useGetAllFormCategoriesQuery();

  const localListOfCategories = formCategoriesData?.map((category) => {
    return {
      key: category?.id.toString(),
      value: category?.name,
    };
  });

  const {
    data: formModelsByCategoryData,
    isLoading: isFormModelsLoading,
    isFetching: isFormModelsFetching,
  } = useGetFormModelsByCategoryQuery({
    formCategoryId: parseInt(value),
  });

  const [getFormModelByIdRequest, { isLoading: isFormLoading }] =
    useLazyGetFormModelByIdQuery();

  // const pageLoading =
  //   (isFormCategoriesLoading && isFormModelsLoading) || isFormLoading;
  const pageLoading =
    isFormCategoriesLoading || isFormModelsLoading || isFormLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE
  useEffect(() => {
    if (!isFormCategoriesLoading) {
      setValue(formCategoriesData[0]?.id.toString());
    }

    // eslint-disable-next-line
  }, [isFormCategoriesLoading]);

  // EVENT HANDLERS
  const formDataArr = [
    {
      type: "input",
      name: "documentName",
      placeholder: "Titlu sablon",
    },
    {
      type: "dropdown",
      name: "documentTypeId",
      placeholder: "Tip document",
      options: localListOfCategories,
    },
  ];

  const handleCardClick = async (card: any) => {
    if (templateDateSelector.hasUnsavedChanges) {
      setCloneCurrentCardId(null);
      setCurrentCardId(card?.id);
      setOpenModal(true);
    } else {
      await getFormModelByIdRequest({
        formId: card?.id,
      }).then((res) => {
        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            displayMode: "editMode",
            hasUnsavedChanges: false,
            sections: res?.data?.sections,
            title: res?.data?.title,
            canBeEdited: res?.data?.canBeEdited,
            templateTypeId: res?.data?.category?.id,
            templateType: res?.data?.category?.name,
            formId: card?.id,
            currentQuestionId: "",
            currentSectionId:
              res?.data?.sections[res?.data?.sections?.length - 1]?.sectionId,
          })
        );
        navigate(`/template`, { state: { formId: card.id } });
      });
    }
  };

  const handleCloneForm = async (card: any) => {
    if (templateDateSelector.hasUnsavedChanges) {
      setCurrentCardId(null);
      setCloneCurrentCardId(card?.id);
      setOpenModal(true);
    } else {
      await getFormModelByIdRequest({
        formId: card?.id,
      }).then((res) => {
        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            displayMode: "createMode",
            hasUnsavedChanges: false,
            sections: res?.data?.sections,
            title: `${res?.data?.title} clonat`,
            canBeEdited: true,
            templateTypeId: res?.data?.category?.id,
            templateType: res?.data?.category?.name,
            formId: card?.id,
            currentQuestionId: "",
            currentSectionId:
              res?.data?.sections[res?.data?.sections?.length - 1]?.sectionId,
          })
        );
        navigate(`/template`, { state: { formId: card.id } });
      });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalDetails({
      documentName: "",
      documentTypeId: "",
      documentTypeValue: "",
    });
  };

  const handleModalChange = (name: string, value: string) => {
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });

    if (name === "documentTypeId") {
      const localValue = localListOfCategories.find(
        (item) => item.key === value
      );

      setModalDetails({
        ...modalDetails,
        [name]: value,
        documentTypeValue: localValue?.value,
      });
    }
  };

  const handleCreateTemplate = () => {
    dispatch(
      handleUpdateTemplateData({
        ...templateDateSelector,
        displayMode: "createMode",
        templateType: modalDetails.documentTypeValue,
        templateTypeId: modalDetails.documentTypeId,
        title: modalDetails.documentName,
        currentQuestionId: "",
        currentSectionId: "",
        hasUnsavedChanges: true,
        sections: [],
      })
    );
    navigate(`/template`, { state: { formId: -1 } });
  };

  const handleResetTemplate = async () => {
    if (currentCardId) {
      await getFormModelByIdRequest({
        formId: currentCardId,
      }).then((res) => {
        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            displayMode: "editMode",
            hasUnsavedChanges: false,
            sections: res?.data?.sections,
            canBeEdited: res?.data?.canBeEdited,
            title: res?.data?.title,
            templateTypeId: res?.data?.category?.id,
            templateType: res?.data?.category?.name,
            formId: currentCardId,
            currentSectionId:
              res?.data?.sections[res?.data?.sections?.length - 1]?.sectionId,
          })
        );
        navigate(`/template`, { state: { formId: currentCardId } });
      });
    } else if (currentCloneCardId) {
      await getFormModelByIdRequest({
        formId: currentCloneCardId,
      }).then((res) => {
        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            displayMode: "createMode",
            hasUnsavedChanges: false,
            sections: res?.data?.sections,
            canBeEdited: true,
            title: `${res?.data?.title} clonat`,
            templateTypeId: res?.data?.category?.id,
            templateType: res?.data?.category?.name,
            formId: currentCloneCardId,
            currentSectionId:
              res?.data?.sections[res?.data?.sections?.length - 1]?.sectionId,
          })
        );
        navigate(`/template`, { state: { formId: currentCloneCardId } });
      });
    } else {
      dispatch(
        handleUpdateTemplateData({
          ...templateDateSelector,
          displayMode: "viewMode",
          templateType: "",
          title: "",
          hasUnsavedChanges: false,
          sections: [],
        })
      );
    }
  };
  const handleContinueTemplate = () => {
    const localDisplayMode =
      (templateDateSelector?.currentQuestionId !== "" &&
        (typeof templateDateSelector?.currentQuestionId === "number" ||
          !isNaN(Number(templateDateSelector?.currentQuestionId)))) ||
      (templateDateSelector?.currentSectionId !== "" &&
        (typeof templateDateSelector?.currentSectionId === "number" ||
          !isNaN(Number(templateDateSelector?.currentSectionId))))
        ? "editMode"
        : "createMode";

    dispatch(
      handleUpdateTemplateData({
        ...templateDateSelector,
        displayMode: localDisplayMode,
      })
    );

    navigate(`/template`, {
      state: {
        formId:
          localDisplayMode === "editMode" ? templateDateSelector?.formId : -1,
      },
    });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <styled.TemplatesListPagePageContainer>
      <styled.PageContentContainer>
        <styled.TitleContainer>
          <styled.RightPageContainer>
            <TabContext value={value}>
              <styled.TabWrapper>
                <TabList
                  onChange={handleTabChange}
                  className="tab-list-mui"
                  TabIndicatorProps={{
                    style: {
                      // width: 64,
                      height: 3,
                    },
                  }}
                >
                  {localListOfCategories?.map((tabData, index) => {
                    return (
                      <Tab
                        key={`tab-list-manage-file-page--${index}`}
                        className="tab-mui"
                        value={tabData.key}
                        icon={tabData.value}
                      />
                    );
                  })}
                </TabList>
                <styled.ButtonContainer>
                  {templateDateSelector.hasUnsavedChanges && (
                    <CustomButton
                      appearance="middleBlue"
                      text={"Continuă șablon"}
                      handleClick={handleContinueTemplate}
                    />
                  )}
                  <CustomButton
                    appearance="middleBlue"
                    text={"Adaugă șablon"}
                    handleClick={handleOpenModal}
                  />
                </styled.ButtonContainer>
              </styled.TabWrapper>

              {localListOfCategories?.map((tabData, index) => {
                return (
                  <TabPanel
                    value={tabData.key}
                    className="tab-panel-mui"
                    key={`tab-panel-manage-file-page--${index}`}
                  >
                    <styled.TitleContainer>
                      <CustomText type="H3" text="Șabloane documente" />
                    </styled.TitleContainer>

                    <styled.TemplatesCardContainer>
                      {formModelsByCategoryData?.length > 0 ? (
                        formModelsByCategoryData?.map((card, index) => {
                          return (
                            <styled.TemplateCard
                              key={`template-card--${index}`}
                              onClick={() => handleCardClick(card)}
                            >
                              <TemplatesListCard
                                type={card.title || "FARA NUME"}
                                date={moment(card?.creationDate).format(
                                  "DD.MM.YYYY"
                                )}
                                isFetching={isFormModelsFetching}
                                handleCloneForm={handleCloneForm}
                                formDetails={card}
                              />
                            </styled.TemplateCard>
                          );
                        })
                      ) : (
                        <CustomText type="H4" text="Niciun șablon disponibil" />
                      )}
                    </styled.TemplatesCardContainer>
                  </TabPanel>
                );
              })}
            </TabContext>
          </styled.RightPageContainer>
        </styled.TitleContainer>
      </styled.PageContentContainer>

      <CustomModal handleClose={handleClose} open={openModal}>
        {templateDateSelector.hasUnsavedChanges ? (
          <styled.ModalWrapper>
            <styled.ModalTitleContainer>
              <CustomText type="H6" text="ATENTIE!" />
            </styled.ModalTitleContainer>
            <styled.ModalContent>
              <CustomText type="H6" text="Exista un sablon in lucru." />
              <CustomText
                type="H6"
                text="Continuarea presupune stergerea acestui șablon."
              />
              <CustomText
                type="H6"
                text="Esti sigur ca doresti sa continui? "
              />
            </styled.ModalContent>
            <styled.ButtonsContainer>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="cancel"
                  text="Anuleaza"
                  handleClick={handleClose}
                />
              </styled.IndividualButtonWrapper>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="smallestBlue"
                  text={"Da, sunt sigur"}
                  handleClick={handleResetTemplate}
                />
              </styled.IndividualButtonWrapper>
            </styled.ButtonsContainer>
          </styled.ModalWrapper>
        ) : (
          <styled.ModalWrapper>
            <styled.ModalTitleContainer>
              <CustomText type="H6" text="Initiaza șablon" />
            </styled.ModalTitleContainer>
            <styled.ModalContent>
              {formDataArr.map((item) => (
                <styled.InputCustomWrapper key={item.name}>
                  {renderData(item, modalDetails, handleModalChange)}
                </styled.InputCustomWrapper>
              ))}
            </styled.ModalContent>
            <styled.ButtonsContainer>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="cancel"
                  text="Anuleaza"
                  handleClick={handleClose}
                />
              </styled.IndividualButtonWrapper>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="smallestBlue"
                  text={"Initiaza șablon"}
                  handleClick={handleCreateTemplate}
                />
              </styled.IndividualButtonWrapper>
            </styled.ButtonsContainer>
          </styled.ModalWrapper>
        )}
      </CustomModal>
    </styled.TemplatesListPagePageContainer>
  );
};

export default TemplatesListPagePage;
