import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./NewDocumentsListPageStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import {
  useGetAllFormCategoriesQuery,
  useGetFormModelsByCategoryQuery,
  useLazyGetFormModelByIdQuery,
} from "api/adminApi";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

// MISC

// COMPONENTS
import CustomHeader from "components/CustomHeader/CustomHeader";
import CustomText from "components/Atoms/CustomText/CustomText";
import TemplatesListCard from "components/TemplatesListCard/TemplatesListCard";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import useIsRequestLoading from "hooks/useRequestLoading";
import moment from "moment";

// CONFIGURATION

const NewDocumentsListPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const newDocumentSelector = useAppSelector(newDocumentDataSelector);

  // STATE CONSTANTS
  const [value, setValue] = useState("1");

  // API REQUESTS
  const { data: formCategoriesData, isLoading: isFormCategoriesLoading } =
    useGetAllFormCategoriesQuery();

  const localListOfCategories = formCategoriesData?.map((category) => {
    return {
      key: category?.id.toString(),
      value: category?.name,
    };
  });

  const {
    data: formModelsByCategoryData,
    isLoading: isFormModelsLoading,
    isFetching: isFormModelsFetching,
  } = useGetFormModelsByCategoryQuery({
    formCategoryId: parseInt(value),
  });

  const [getFormModelByIdRequest, { isLoading: isFormModelByIdLoading }] =
    useLazyGetFormModelByIdQuery();

  // const pageLoading =
  //   (isFormCategoriesLoading && isFormModelsLoading) || isFormModelByIdLoading;

  const pageLoading =
    isFormCategoriesLoading || isFormModelsLoading || isFormModelByIdLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE
  useEffect(() => {
    if (!isFormCategoriesLoading) {
      setValue(formCategoriesData[0]?.id.toString());
    }
    // eslint-disable-next-line
  }, [isFormCategoriesLoading]);

  // EVENT HANDLERS
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleCardClick = async (card) => {
    await getFormModelByIdRequest({
      formId: card?.id,
    }).then((res) => {
      let counter = 0;

      let localSections = res?.data?.sections?.map((section, index) => {
        return {
          ...section,
          completed: index === 0 ? 1 : 0,
          questions: section?.questions?.map((question) => {
            const localOptions = question?.answers?.map((option) => {
              return {
                value: option?.answer,
                key: option?.answerId,
              };
            });
            return {
              ...question,
              name: `${question?.type}-${question?.questionId}`,
              step: counter++,
              options: localOptions,
              completedAnswer: question?.completedAnswer || "",
              selectedOptions: question?.selectedOptions || [],
            };
          }),
        };
      });

      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          displayMode: "createMode",
          hasUnsavedChanges: true,
          completionDuration: 0,
          title: res?.data?.title,
          currentFlow: "cnpFlow",
          formId: card?.id,
          currentBeneficiaryData: {},
          sections: [...localSections],
        })
      );
      navigate(`/new-document`, { state: { formId: card.id } });
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleContinueTemplate = () => {
    if (newDocumentSelector?.["currentBeneficiaryData"]?.["id"]) {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          hasUnsavedChanges: true,
          currentStep: 0,
          currentFlow: "modelFlow",
        })
      );
    } else {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          hasUnsavedChanges: true,
          currentStep: 0,
          currentFlow: "cnpFlow",
        })
      );
    }

    navigate(`/new-document`, { state: { formId: -1 } });
  };

  return (
    <styled.TemplatePageContainer>
      <CustomHeader text={"Inapoi la Panou"} handleGoBack={handleGoBack} />

      <styled.PageContentContainer>
        <styled.TitleContainer>
          <CustomText type="H2" text="Alege șablon" />
        </styled.TitleContainer>

        <styled.RightPageContainer>
          <TabContext value={value}>
            <styled.TabWrapper>
              <TabList
                onChange={handleTabChange}
                className="tab-list-mui"
                TabIndicatorProps={{
                  style: {
                    // width: 64,
                    height: 3,
                  },
                }}
              >
                {localListOfCategories?.map((tabData, index) => {
                  return (
                    <Tab
                      key={`tab-list-manage-file-page--${index}`}
                      className="tab-mui"
                      value={tabData.key}
                      icon={tabData.value}
                    />
                  );
                })}
              </TabList>

              {newDocumentSelector.hasUnsavedChanges && (
                <styled.ButtonContainer>
                  <CustomButton
                    appearance="middleBlue"
                    text={"Continuă document"}
                    handleClick={handleContinueTemplate}
                  />
                </styled.ButtonContainer>
              )}
            </styled.TabWrapper>
            {localListOfCategories?.map((tabData, index) => {
              return (
                <TabPanel
                  value={tabData.key}
                  className="tab-panel-mui"
                  key={`tab-panel-manage-file-page--${index}`}
                >
                  <styled.TemplatesCardContainer>
                    {formModelsByCategoryData?.length > 0 ? (
                      formModelsByCategoryData?.map((card, index) => {
                        return (
                          <styled.TemplateCard
                            key={`template-card--${index}`}
                            onClick={() => handleCardClick(card)}
                          >
                            <TemplatesListCard
                              type={card.title || "FARA NUME"}
                              date={moment(card?.creationDate).format(
                                "DD.MM.YYYY"
                              )}
                              isFetching={isFormModelsFetching}
                            />
                          </styled.TemplateCard>
                        );
                      })
                    ) : (
                      <CustomText type="H4" text="Niciun șablon disponibil" />
                    )}
                  </styled.TemplatesCardContainer>
                </TabPanel>
              );
            })}
          </TabContext>
        </styled.RightPageContainer>
      </styled.PageContentContainer>
    </styled.TemplatePageContainer>
  );
};

export default NewDocumentsListPage;
