import { apiSlice } from "../api/apiSlice";

export const newDocumentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBeneficiaryByCNP: builder.query<any, { cnpValue: string }>({
      query: ({ cnpValue }) =>
        `beneficiary/get-beneficiary-by-cnp?cnp=${cnpValue}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllTargetGroupsList: builder.query<any, void>({
      query: () => `target-group/get-all-target-groups`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    createBeneficiary: builder.mutation<
      any,
      {
        birthDate: string;
        city: string;
        cnp: string;
        communityCenterId: number;
        conditionsDescription: string;
        fullName: string;
        gender: string;
        targetGroupId: number;
      }
    >({
      query: ({
        birthDate,
        city,
        cnp,
        communityCenterId,
        conditionsDescription,
        fullName,
        gender,
        targetGroupId,
      }) => ({
        url: `beneficiary/create-beneficiary`,
        method: "POST",
        body: {
          birthDate,
          city,
          cnp,
          communityCenterId,
          conditionsDescription,
          fullName,
          gender,
          targetGroupId,
        },
      }),
      invalidatesTags: [],
    }),

    completeBeneficiaryForm: builder.mutation<any, any>({
      query: ({ formId, duration, beneficiaryId, bodyData }) => ({
        url: `beneficiary/complete-form?beneficiaryId=${beneficiaryId}&duration=${duration}&formId=${formId}`,

        method: "POST",
        body: bodyData,
      }),
      invalidatesTags: [],
    }),

    editBeneficiaryForm: builder.mutation<any, any>({
      query: ({ formId, beneficiaryId, bodyData }) => ({
        url: `beneficiary/edit-form?beneficiaryId=${beneficiaryId}&completedFormId=${formId}`,

        method: "POST",
        body: bodyData,
      }),
      invalidatesTags: [],
    }),

    deleteBeneficiaryForm: builder.mutation<any, any>({
      query: ({ formId }) => ({
        url: `beneficiary/activate-or-inactivate-completed-form?formId=${formId}`,
        method: "PUT",
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useLazyGetBeneficiaryByCNPQuery,
  useCreateBeneficiaryMutation,
  useGetAllTargetGroupsListQuery,
  useCompleteBeneficiaryFormMutation,
  useEditBeneficiaryFormMutation,
  useDeleteBeneficiaryFormMutation,
} = newDocumentApi;
