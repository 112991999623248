import React, { useEffect, useRef, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./TemplateRadioListStyled";

// LIBRARIES
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { templateDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import { handleUpdateTemplateData } from "slices/TemplateSlice/templateSlice";

// MISC

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION

const TemplateRadioList = (props: { question: any; type: string }) => {
  // PROPERTIES
  const { question, type = "radio" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const templateDateSelector = useAppSelector(templateDataSelector);

  // STATE CONSTANTS
  const [currentCheckboxIdList, setCurrentCheckboxIdList] = useState([0]);
  const answerRefs = useRef([]);

  // LIFE CYCLE
  const previousAnswersLength = useRef(question?.answers?.length || 0);

  useEffect(() => {
    const currentAnswersLength = question?.answers?.length || 0;
    if (currentAnswersLength > previousAnswersLength?.current) {
      answerRefs?.current[currentAnswersLength - 1]?.current?.focus();
    }
    previousAnswersLength.current = currentAnswersLength;
  }, [question?.answers]);

  useEffect(() => {
    answerRefs.current.forEach((ref) => {
      if (ref.current) {
        adjustTextareaHeight(ref.current);
      }
    });
  }, []);

  // EVENT HANDLERS
  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };
  const handleAnswersChange = (event, answerIndex) => {
    if (!templateDateSelector?.canBeEdited) return;

    const currentQuestionId = templateDateSelector?.currentQuestionId;
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newSections = templateDateSelector?.sections.map((section) => {
      return {
        ...section,
        questions: section.questions.map((question) => {
          if (question.questionId === currentQuestionId) {
            if (answerIndex >= 0 && answerIndex < question.answers.length) {
              const newAnswers = [...question.answers];
              newAnswers[answerIndex] = {
                ...newAnswers[answerIndex],
                answer: event.target.value,
              };

              return {
                ...question,
                answers: newAnswers,
              };
            }
          }
          return question;
        }),
      };
    });

    const updatedTemplateData = {
      ...templateDateSelector,
      sections: newSections,
      hasUnsavedChanges: true,
    };

    dispatch(handleUpdateTemplateData(updatedTemplateData));
  };
  const handleAddMoreOptions = () => {
    const currentQuestionId = templateDateSelector?.currentQuestionId;

    const newAnswer = {
      answer: "",
    };

    const newSections = templateDateSelector?.sections.map((section) => {
      return {
        ...section,
        questions: section.questions.map((question) => {
          if (question.questionId === currentQuestionId) {
            return {
              ...question,
              answers: [...question.answers, newAnswer],
            };
          }
          return question;
        }),
      };
    });

    const updatedTemplateData = {
      ...templateDateSelector,
      sections: newSections,
      hasUnsavedChanges: true,
    };

    dispatch(handleUpdateTemplateData(updatedTemplateData));
  };
  const handleDeleteAnswer = (answerIndexToDelete) => {
    const currentQuestionId = templateDateSelector?.currentQuestionId;

    const newSections = templateDateSelector.sections.map((section) => {
      return {
        ...section,
        questions: section.questions.map((question) => {
          if (question.questionId === currentQuestionId) {
            if (
              answerIndexToDelete >= 0 &&
              answerIndexToDelete < question.answers.length
            ) {
              const newAnswers = [...question.answers];
              newAnswers.splice(answerIndexToDelete, 1);
              return {
                ...question,
                answers: newAnswers,
              };
            }
          }
          return question;
        }),
      };
    });

    const updatedTemplateData = {
      ...templateDateSelector,
      sections: newSections,
      hasUnsavedChanges: true,
    };

    dispatch(handleUpdateTemplateData(updatedTemplateData));
  };

  const handleCheckBoxes = (index) => {
    if (!templateDateSelector?.canBeEdited) return;

    if (type === "radio") {
      setCurrentCheckboxIdList([index]);
    } else if (type === "checkbox") {
      if (currentCheckboxIdList.includes(index)) {
        const newCheckboxIdList = currentCheckboxIdList.filter(
          (checkboxId) => checkboxId !== index
        );
        setCurrentCheckboxIdList(newCheckboxIdList);
      } else {
        setCurrentCheckboxIdList([...currentCheckboxIdList, index]);
      }
    }
  };

  return (
    <styled.RadioListContainer>
      <styled.RadioContainer>
        {question?.answers?.map((answers, index) => {
          if (!answerRefs?.current?.[index]) {
            answerRefs.current[index] = React.createRef();
          }

          return (
            <styled.RadioWrapper key={`radio-list--${index}`}>
              <styled.RadioItemWrapper>
                <styled.CheckboxIconContainer
                  onClick={() => handleCheckBoxes(index)}
                >
                  {currentCheckboxIdList.includes(index) && (
                    <styled.RadioCheckedIcon />
                  )}
                  {!currentCheckboxIdList.includes(index) && (
                    <styled.RadioUnCheckedIcon />
                  )}
                </styled.CheckboxIconContainer>
                <styled.Textarea
                  ref={answerRefs?.current?.[index]}
                  name={"answer"}
                  onChange={(event) => {
                    handleAnswersChange(event, index);
                  }}
                  value={answers?.answer}
                  rows={1}
                  maxLength={200}
                  placeholder={`Varianta ${index + 1}`}
                />
              </styled.RadioItemWrapper>
              {templateDateSelector?.canBeEdited &&
                question?.answers?.length > 1 && (
                  <styled.DeleteIconContainer
                    onClick={() => handleDeleteAnswer(index)}
                  >
                    <styled.DeleteIconImg />
                  </styled.DeleteIconContainer>
                )}
            </styled.RadioWrapper>
          );
        })}
      </styled.RadioContainer>
      {templateDateSelector?.canBeEdited && (
        <styled.RadioAddMoreContainer onClick={handleAddMoreOptions}>
          <styled.AddIconImg />
          <CustomText type={"H6"} text={"Adaugă variantă"} />
        </styled.RadioAddMoreContainer>
      )}
    </styled.RadioListContainer>
  );
};

export default TemplateRadioList;
