import React, { useEffect } from "react";

// ASSETS
import { ReactComponent as CheckBoxChecked } from "assets/icons/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/CkeckBokUnchecked.svg";

// STYLES
import * as styled from "./ActivityCheckBoxListStyled";
import { IProps } from "./ActivityCheckBoxListModel";

// LIBRARIES
import { useGetAllActivityCategoriesQuery } from "api/beneficiaryApi";

// MISC

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION
const ActivityCheckBoxList = (props: IProps) => {
  // PROPERTIES
  const { setActivityCategoryIds } = props;

  // API REQUESTS
  const { data: getAllActivityCategoriesList } =
    useGetAllActivityCategoriesQuery();

  let localActivities = getAllActivityCategoriesList?.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  localActivities?.unshift({ id: -1, name: "Toate categoriile" });

  // STATE CONSTANTS
  const [formState, setFormState] = React.useState([
    { id: -1, name: "Toate categoriile" },
  ]);

  // LIFE CYCLE
  useEffect(() => {
    if (formState.length === 0) {
      setFormState([{ id: -1, name: "Toate categoriile" }]);
    }

    const activityIds = formState
      .map((item) => item.id)
      .filter((item) => item !== -1);

    setActivityCategoryIds(activityIds.join(","));
    // eslint-disable-next-line
  }, [formState]);

  // EVENT HANDLERS

  const handleOnCheckboxPress = (option: { id: number; name: string }) => {
    if (option.id === -1) {
      setFormState([{ id: -1, name: "Toate categoriile" }]);
      return;
    }
    if (formState.some((item) => item.id === -1)) {
      setFormState([option]);
      return;
    }

    const updatedFormState = formState.some((item) => item.id === option.id)
      ? formState.filter((item) => item.id !== option.id)
      : [...formState, option];

    setFormState(updatedFormState);
  };

  return (
    <styled.CustomCheckBoxListContainer>
      <styled.TitleContainer>
        <CustomText type="H3" text="Categorii activităţi" />
      </styled.TitleContainer>

      <styled.CheckBoxListWrapper>
        {localActivities?.map((option, index) => (
          <styled.RadioLabel key={index}>
            <styled.ButtonWrapper
              checked={
                formState.some((item) => item.id === option.id)
                  ? "checked"
                  : "unchecked"
              }
              onClick={() => handleOnCheckboxPress(option)}
            >
              <styled.SvgWrapper>
                {formState.some((item) => item.id === option.id) ? (
                  <CheckBoxChecked />
                ) : (
                  <CheckBoxUnchecked />
                )}
              </styled.SvgWrapper>

              <styled.OptionText>{option.name}</styled.OptionText>
            </styled.ButtonWrapper>
          </styled.RadioLabel>
        ))}
      </styled.CheckBoxListWrapper>
    </styled.CustomCheckBoxListContainer>
  );
};

export default ActivityCheckBoxList;
