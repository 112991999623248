import { createSelector } from "reselect";
import { templateSelector } from "slices/TemplateSlice/templateSlice";

export const rightSideMenuDataSelector = createSelector(
  templateSelector,
  (template) => template.rightSideMenuData
);
export const templateDataSelector = createSelector(
  templateSelector,
  (template) => template.templateData
);
export const newDocumentDataSelector = createSelector(
  templateSelector,
  (template) => template.newDocumentData
);
