// @ts-nocheck
import React, { useState, forwardRef } from "react";

// ASSETS
import { ReactComponent as HidePasswordIcon } from "assets/icons/HidePasswordIcon.svg";
import { ReactComponent as ShowPasswordIcon } from "assets/icons/ShowPasswordIcon.svg";

// STYLES
import * as styled from "./CustomInputStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomInputModel";

// COMPONENTS

// CONFIGURATION

const CustomInput = forwardRef((props: IProps, ref) => {
  // PROPERTIES
  const { appearance = "primary", type = "text", validation = "" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const [showPassword, setShowPassword] = useState(false);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <styled.InputComponentWrapper
      appearance={appearance}
      validation={validation}
    >
      <styled.InputComponent
        {...props}
        ref={ref}
        secureTextEntry={showPassword}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={props.onChange}
      />
      {type === "password" && (
        <styled.IconWrapper onClick={handleShowPassword}>
          {!showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </styled.IconWrapper>
      )}
    </styled.InputComponentWrapper>
  );
});

export default CustomInput;
