// LIBRARIES
import styled from "styled-components";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const StatisticsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;
export const StatisticsPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ExportButtonContainer = styled.div``;
export const StatisticsPageChartContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  flex-wrap: wrap;
`;
export const StatisticsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
  min-width: 300px;
  width: 100%;
  align-items: flex-end;
  text-transform: uppercase;
  padding-bottom: ${SIZE.max24};
`;
export const StatisticsTableWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};
  min-height: 329px;
`;
