// LIBRARIES
import styled from "styled-components";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const LoginPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const LoginContentContainer = styled.div`
  background-color: ${COLORS.appWhite};
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
  }
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  padding: ${SIZE.max60};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const InputCustomWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: 256px;
  margin-bottom: 16px;
`;
export const CustomButtonWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: 256px;
  margin-bottom: 16px;
`;

export const HeadWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: 256px;
`;
export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

export const Subtitle = styled.div`
  margin-bottom: ${SIZE.max24};
`;

export const LogoContentContainer = styled.div`
  background-color: ${COLORS.appLightGrey};
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 100%;
  max-width: 256px;
  min-height: 180px;
  max-height: 360px;
`;
export const LogoWrapper = styled.div`
  padding: ${SIZE.max40};
  svg {
    min-width: 100px;
    width: 100%;
    max-width: 256px;
  }
`;
// ------------MODAL-------------------//
export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;

export const TitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;

export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
`;

export const ButtonsWrapper = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
