// LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./clientSliceModel";
import { revertAll } from "store/store";

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    handlePagePaginationChange: (state, action) => {
      state[action.payload.clientPage].pageNumber = action.payload.value;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { handlePagePaginationChange } = clientSlice.actions;

export const clientSelector = (state) => state.client;
export default clientSlice.reducer;
