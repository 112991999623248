// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomInput/CustomInputModel";
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  BORDER_RADIUS,
  SIZE,
} from "constants/variables";

export const InputComponentWrapper = styled.div<IStyled>(
  ({ validation, type }) => css`
    border: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    border-radius: ${BORDER_RADIUS.cardRadius};
    padding: ${SIZE.max16};
    width: 100%;
    background-color: ${COLORS.appWhite};
    position: relative;
    min-height: ${type === "short" ? SIZE.max104 : SIZE.max200};
    ${validation === "successInput" &&
    css`
      border: solid 1px ${COLORS.appGreen};
    `}
    ${validation === "errorInput" &&
    css`
      border: solid 1px ${COLORS.appRed};
    `}
      &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  `
);

export const Textarea = styled.textarea`
  flex-grow: 1;
  width: 100%;
  border: none;
  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max16};
  resize: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${COLORS.appDarkGrey};
    font-size: ${FONT_SIZE.max16};
  }
`;

export const CharacterCount = styled.div`
  color: ${COLORS.appDarkGrey};
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max12};
  font-weight: ${FONT_WEIGHT.medium};
  position: absolute;
  bottom: ${SIZE.max16};
  right: ${SIZE.max24};
`;
