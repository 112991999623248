// LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./templateSliceModel";
import { revertAll } from "store/store";

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    handleChangeSideData: (state, action) => {
      let localRightSideMenuData = state.rightSideMenuData;

      localRightSideMenuData.statusData[action.payload.index] = {
        ...localRightSideMenuData.statusData[action.payload.index],
        [action.payload.name]: action.payload.completed,
      };

      state.rightSideMenuData = localRightSideMenuData;
    },

    handleChangeTemplateSectionData: (state, action) => {
      let localTemplateData = state.templateData;

      //eslint-disable-next-line
      localTemplateData.sections.map((section) => {
        if (section.sectionId === action.payload.sectionId) {
          section[action.payload.name] = action.payload.value;
        }
      });

      state.templateData = localTemplateData;
    },

    handleChangeTemplateQuestionData: (state, action) => {
      let localTemplateData = state.templateData;
      localTemplateData[action.payload.name] = action.payload.value;

      //eslint-disable-next-line
      localTemplateData.sections.map((section) => {
        if (section.sectionId === action.payload.sectionId) {
          //eslint-disable-next-line
          section.questions.map((question) => {
            if (question.questionId === action.payload.questionId) {
              question[action.payload.name] = action.payload.value;
            }
          });
        }
      });

      state.templateData = localTemplateData;
    },

    handleUpdateTemplateData: (state, action) => {
      state.templateData = action.payload;
    },

    handleUpdateNewDocumentData: (state, action) => {
      state.newDocumentData = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  handleChangeSideData,
  handleUpdateNewDocumentData,
  handleChangeTemplateSectionData,
  handleChangeTemplateQuestionData,
  handleUpdateTemplateData,
} = templateSlice.actions;

export const templateSelector = (state) => state.template;
export default templateSlice.reducer;
