// LIBRARIES
import styled from "styled-components";
import { Modal } from "@mui/material";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  SIZE,
  SHADOW,
  FONT_FAMILY,
  FONT_SIZE,
} from "constants/variables";

export const ModalComponent = styled(Modal)`
  background-color: rgba(176, 179, 191, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${SIZE.max24};
  outline: none;
  box-shadow: ${SHADOW.modalShadow};
  backdrop-filter: blur(5px);
`;
export const Package = styled.div``;
export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max650};
`;
export const TitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
`;
export const InputCustomWrapper = styled.div`
  div > div {
    font-family: ${FONT_FAMILY.poppins} !important;
  }

  div > input::placeholder {
    color: ${COLORS.appDarkGrey};
    font-size: ${FONT_SIZE.max16};
    font-family: ${FONT_FAMILY.poppins} !important;
    opacity: 1;
  }
`;
export const PickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
export const AutocompleteWrapper = styled.div`
  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};
  max-height: 100px;
  overflow-y: auto;

  div > div {
    font-family: ${FONT_FAMILY.poppins} !important;
  }
  div > input::placeholder {
    color: ${COLORS.appDarkGrey} !important;
    font-size: ${FONT_SIZE.max16} !important;
    opacity: 1 !important;
    font-family: ${FONT_FAMILY.poppins} !important;
  }
`;

export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ModalBtnsContainer = styled.div`
  display: flex;
  gap: ${SIZE.max16};
`;
export const IndividualButtonWrapper = styled.div``;
export const DeleteButtonWrapper = styled.div`
  button {
    background-color: ${COLORS.appRed};
  }
`;
