// LIBRARIES
import styled from "styled-components";
import { Modal } from "@mui/material";
import { SIZE } from "constants/variables";

// MISC

export const ModalComponent = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${SIZE.max24};
  outline: none;
`;
