// LIBRARIES
import styled from "styled-components";
import { SIZE } from "constants/variables";

// MISC

export const SideMenuContainer = styled.div`
  display: flex;
  height: 100%;
  padding: ${SIZE.max60} ${SIZE.max40};
  justify-content: center;
`;
