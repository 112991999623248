// ASSETS

// STYLES
import * as styled from "./CustomStatisticsSkeletonCardStyled";
import "react-loading-skeleton/dist/skeleton.css";

// LIBRARIES
import Skeleton from "react-loading-skeleton";

// MISC
import { IProps } from "./CustomStatisticsSkeletonCardModel";

// COMPONENTS

// CONFIGURATION

const CustomStatisticsSkeletonCard = (props: IProps) => {
  // PROPERTIES
  const { rowCount = 1 } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.StatisticsCardSkeletonContainer>
      <styled.BarChartHeaderContainer>
        <Skeleton height={25} width={100} />
        <Skeleton height={25} width={100} />
      </styled.BarChartHeaderContainer>
      <styled.BarChartBodyContainer>
        {[...Array(rowCount)]?.map((_, index) => {
          return <Skeleton height={20} key={`bar-item-list--${index}`} />;
        })}
      </styled.BarChartBodyContainer>

      <styled.BarChartLegend>
        <styled.BarChartLegendItem>
          <Skeleton height={15} width={100} />
        </styled.BarChartLegendItem>
        <styled.BarChartLegendItem>
          <Skeleton height={15} width={100} />
        </styled.BarChartLegendItem>
      </styled.BarChartLegend>
    </styled.StatisticsCardSkeletonContainer>
  );
};

export default CustomStatisticsSkeletonCard;
