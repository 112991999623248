// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS, SIZE } from "constants/variables";

export const PasswordSettingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const PasswordContentContainer = styled.div`
  background-color: ${COLORS.appWhite};
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
  }
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  padding: ${SIZE.max60};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SIZE.max24};
`;
export const InputCustomWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: 256px;
`;
export const ErrorMsgText = styled.span`
  color: ${COLORS.appRed};
  min-width: 100px;
  width: 100%;
  max-width: 256px;
`;
export const CustomButtonWrapper = styled.div`
  margin-top: ${SIZE.max16};
  min-width: 100px;
  width: 100%;
  max-width: 256px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const LogoContentContainer = styled.div`
  background-color: ${COLORS.appLightGrey};
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 100%;
  max-width: 256px;
  min-height: 180px;
  max-height: 360px;
`;
export const LogoWrapper = styled.div`
  padding: ${SIZE.max40};
  svg {
    min-width: 100px;
    width: 100%;
    max-width: 256px;
  }
`;
