import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./CommunityCentersStyled";

// LIBRARIES
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { communityCentersPaginationSelector } from "slices/AdminSlice/adminSliceSelectors";
import { handlePagePaginationChange } from "slices/AdminSlice/adminSlice";
import {
  useActivateInactivateCommunityCenterMutation,
  useCreateCommunityCenterMutation,
  useGetAllCommunityCentersPageableQuery,
  useGetAllCountiesQuery,
  useUpdateCommunityCenterMutation,
} from "api/adminApi";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";

// MISC
import useTableHeadDetails from "hooks/useTableHeadHook";
import { communityCentersHeadValues } from "mocks/adminMock";
import { renderData } from "helpers/globalFunc";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomTable from "components/Atoms/CustomTable/CustomTable";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import useIsRequestLoading from "hooks/useRequestLoading";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";

// CONFIGURATION
const CommunityCenters = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const communityCentersPaginationDetails = useAppSelector(
    communityCentersPaginationSelector
  );
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);

  // STATE CONSTANTS
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(communityCentersHeadValues);

  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    name: "",
    countyId: 0,
    id: 0,
  });

  // API REQUESTS
  const {
    data: communityCentersList,
    isLoading: isCommunityCentersListLoading,
    isFetching: isCommunityCentersListFetching,
  } = useGetAllCommunityCentersPageableQuery({
    page: communityCentersPaginationDetails.pageNumber - 1,
    size: communityCentersPaginationDetails.pageSize,
    searchQuery: headerSearchValueDetails,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const { data: countiesList, isLoading: isCountiesListLoading } =
    useGetAllCountiesQuery();
  const localCountiesList = countiesList?.map((county) => {
    return {
      key: county?.id,
      value: county?.name,
    };
  });

  const [
    createCommunityCenterRequest,
    { isLoading: isCreateCommunityCenterLoading },
  ] = useCreateCommunityCenterMutation();
  const [
    updateCommunityCenterRequest,
    { isLoading: isUpdateCommunityCenterLoading },
  ] = useUpdateCommunityCenterMutation();
  const [
    activateInactivateCommunityCenterRequest,
    { isLoading: isDeleteCommunityCenterLoading },
  ] = useActivateInactivateCommunityCenterMutation();

  const pageLoading =
    isCommunityCentersListLoading ||
    isCountiesListLoading ||
    isCreateCommunityCenterLoading ||
    isUpdateCommunityCenterLoading ||
    isDeleteCommunityCenterLoading;
  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleAddCenter = () => {
    setIsEditMode(false);
    setOpenModal(true);

    setModalDetails({
      name: "",
      countyId: 0,
      id: 0,
    });
  };
  const handleCreateCenter = async () => {
    if (isEditMode) {
      await updateCommunityCenterRequest({
        id: modalDetails["id"],
        name: modalDetails["name"],
        countyId: modalDetails["countyId"],
      }).then(() => {
        setOpenModal(false);
      });
    } else {
      await createCommunityCenterRequest({
        name: modalDetails["name"],
        countyId: modalDetails["countyId"],
      }).then(() => {
        setOpenModal(false);
      });
    }
  };
  const handleEditUser = (row: object) => {
    setIsEditMode(true);
    setOpenModal(true);

    setModalDetails({
      name: row["name"],
      countyId: row["countyId"],
      id: row["id"],
    });
  };
  const handleDeleteUser = async (row: object) => {
    await activateInactivateCommunityCenterRequest({
      communityCenterId: row["id"],
      isActive: !row["isActive"],
    });
  };
  const handleClose = () => {
    setIsEditMode(false);
    setOpenModal(false);
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    adminPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        adminPage,
      })
    );
  };

  const handleModalChange = (name: string, value: string) => {
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };

  const formDataArr = [
    {
      type: "input",
      name: "name",
      placeholder: "Denumire",
    },
    {
      type: "dropdown",
      name: "countyId",
      placeholder: "Judet",
      options: localCountiesList,
    },
  ];

  return (
    <styled.CommunityCentersContainer>
      <styled.OperatorsPageHeader>
        <CustomText type="H3" text="Centre Comunitare" />
        <styled.AddUserButtonContainer>
          <CustomButton text="Adauga Centru" handleClick={handleAddCenter} />
        </styled.AddUserButtonContainer>
      </styled.OperatorsPageHeader>
      <styled.OperatorsPageTableContainer>
        <styled.OperatorsPageTableWrapper>
          {isCommunityCentersListFetching ? (
            <CustomSkeletonTable
              columnCount={tableHeadDetails.length}
              rowCount={10}
            />
          ) : (
            <CustomTable
              onEditPress={handleEditUser}
              onDeletePress={handleDeleteUser}
              onHeadCellPress={handleChangeSortOrder}
              hasAction={true}
              headerData={tableHeadDetails}
              bodyData={communityCentersList?.content}
              banList={["id", "isActive", "lastUpdateAt", "countyId"]}
            />
          )}
        </styled.OperatorsPageTableWrapper>

        <styled.OperatorsPagePaginationContainer>
          <CustomPagination
            page={communityCentersPaginationDetails?.pageNumber}
            count={Math.ceil(
              communityCentersList?.["totalElements"] /
                communityCentersPaginationDetails?.pageSize
            )}
            onChange={(event, value) =>
              handlePageChange(event, value, "communityCentersPagination")
            }
          />
        </styled.OperatorsPagePaginationContainer>
      </styled.OperatorsPageTableContainer>

      <CustomModal handleClose={handleClose} open={openModal}>
        <styled.ModalWrapper>
          <styled.TitleContainer>
            <CustomText type="H6" text="Detalii Centru Comunitar" />
          </styled.TitleContainer>

          <styled.ModalContent>
            {formDataArr.map((item) => (
              <styled.InputCustomWrapper key={item.name}>
                {renderData(item, modalDetails, handleModalChange)}
              </styled.InputCustomWrapper>
            ))}
          </styled.ModalContent>
          <styled.ButtonsContainer>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={handleClose}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance={
                  modalDetails["name"] && modalDetails["countyId"]
                    ? "smallestBlue"
                    : "smallestDisabled"
                }
                text={isEditMode ? "Modifica Centru" : "Adauga Centru"}
                handleClick={
                  modalDetails["name"] && modalDetails["countyId"]
                    ? handleCreateCenter
                    : () => {}
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsContainer>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.CommunityCentersContainer>
  );
};

export default CommunityCenters;
