// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { COLORS, SIZE } from "constants/variables";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.appLightGrey};
`;

export const HeaderWrapper = styled.div<{ isLoading: boolean }>(
  ({ isLoading }) => css`
    background-color: transparent;
    visibility: ${isLoading ? "hidden" : "visible"};
  `
);

export const ContentContainer = styled.div<{ isLoading: boolean }>(
  ({ isLoading }) => css`
    height: 100%;
    min-height: 200px;
    display: flex;
    visibility: ${isLoading ? "hidden" : "visible"};
  `
);

export const ContentWrapper = styled.div`
  padding: ${SIZE.max24} ${SIZE.max40} ${SIZE.max60} ${SIZE.max40};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow-y: auto;
  min-width: 300px;
`;

export const OutletWrapper = styled.div<{ hasSideBar?: boolean }>(
  ({ hasSideBar }) => css`
    max-width: ${hasSideBar ? "1600px" : "1920px"};
    width: 100%;
  `
);

export const SideMenuWrapper = styled.div<{ side: string }>(
  ({ side }) => css`
    min-width: 150px;
    width: 100%;
    height: 100%;

    ${side === "left" &&
    css`
      background-color: transparent;
      max-width: 250px;
    `}
    ${side === "right" &&
    css`
      background-color: ${COLORS.appWhite};
      max-width: 400px;
    `}
  `
);
