// LIBRARIES
import styled from "styled-components";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const ActivityTabContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${SIZE.max24};

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const ActivitiesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZE.max24};
  border-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ActivitiesListWrappers = styled.div`
  background-color: ${COLORS.appWhite};
  overflow: auto;

  display: flex;
  flex-grow: 1;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const CheckboxListContainer = styled.div`
  display: flex;

  min-width: 320px;
  width: 100%;
  max-width: 520px;

  @media (max-width: 1250px) {
    max-width: 100%;
  }

  overflow-y: auto;
  background-color: ${COLORS.appWhite};
  border-radius: ${BORDER_RADIUS.cardRadius};
`;
