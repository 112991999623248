// LIBRARIES
import styled from "styled-components";
import { COLORS, FONT_SIZE, SIZE } from "constants/variables";

// MISC

export const TemplatePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZE.max104};

  height: 100%;
`;

export const SectionTitleContainer = styled.div`
  padding: 0px ${SIZE.max80};
  display: flex;
  align-items: center;

  div > span {
    color: ${COLORS.appDarkGrey} !important;
  }

  div > input {
    font-size: ${FONT_SIZE.max26};
  }

  div > input::placeholder {
    font-size: ${FONT_SIZE.max26};
    color: ${COLORS.appBlack};
    font-style: italic;
  }
`;
export const TitleContainer = styled.div`
  width: ${SIZE.max160};
`;
export const TemplatePageContentContainer = styled.div`
  padding-bottom: ${SIZE.max24};
  flex-grow: 1;
`;
export const TemplatePageContentWrapper = styled.div``;
export const SaveButtonContainer = styled.div`
  max-width: 256px;
  margin-left: ${SIZE.max80};
`;
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const QuestionWrapper = styled.div`
  padding: 0px ${SIZE.max80};
  display: flex;
  align-items: center;

  div > span {
    color: ${COLORS.appDarkGrey} !important;
  }

  div > input {
    font-size: ${FONT_SIZE.max26};
  }

  div > input::placeholder {
    font-size: ${FONT_SIZE.max26};
    color: ${COLORS.appBlack};
    font-style: italic;
  }
`;
export const QuestionTypeWrapper = styled.div`
  padding: 0px ${SIZE.max80};
  max-width: 50%;
`;

export const TemplateTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;
