import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomModalStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomModalModel";

// COMPONENTS

// CONFIGURATION

const CustomModal = (props: IProps) => {
  // PROPERTIES
  const { handleClose, open = false, children } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.ModalComponent open={open} onClose={handleClose}>
      {children}
    </styled.ModalComponent>
  );
};

export default CustomModal;
