// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const AutocompleteContainer = styled.div`
  background-color: ${COLORS.appWhite};
  position: relative;
  z-index: 1;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS.cardRadius};
  padding: ${SIZE.max16};
  width: 100%;
  border-bottom: solid 1px ${COLORS.appLightGrey};
`;
export const Input = styled.input`
  background-color: transparent;
  width: 100%;
  border: none;
  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max16};
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: ${COLORS.appDarkGrey};
    font-size: ${FONT_SIZE.max16};
  }
`;
export const RenderSuggestionsContainer = styled.div`
  max-height: 150px;
  background-color: ${COLORS.appWhite};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  padding: ${SIZE.max16};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 90%;
  z-index: 2;
  left: 0;
`;
export const RenderSuggestions = styled.ul`
  list-style-type: none;
`;
export const Item = styled.li`
  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max16};
  list-style-type: none;
  margin-block: 6px;
`;
