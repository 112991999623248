import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect } from "react";
import { handleIsLoadingChange } from "slices/SessionSlice/sessionSlice";
import { isRequestLoadingSelector } from "slices/SessionSlice/sessionSliceSelectors";

const useIsRequestLoading = (isRequestLoading: boolean) => {
  const dispatch = useAppDispatch();

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const isRequestLoadingData = useAppSelector(isRequestLoadingSelector);

  useEffect(() => {
    if (!isRequestLoading && isRequestLoadingData) {
      sleep(500).then(() => {
        dispatch(handleIsLoadingChange(isRequestLoading));
      });
    } else {
      dispatch(handleIsLoadingChange(isRequestLoading));

      sleep(10000).then(() => {
        if (isRequestLoading) dispatch(handleIsLoadingChange(false));
      });
    }
    // eslint-disable-next-line
  }, [isRequestLoading]);
};

export default useIsRequestLoading;
