// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { SIZE, COLORS, BORDER_RADIUS, FONT_SIZE } from "constants/variables";

export const NewDocumentPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const NewDocumentPageContentWrapper = styled.div`
  padding: ${SIZE.max24} ${SIZE.max80};
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};
  flex-grow: 1;
  overflow: auto;
`;
export const ViewModeContentContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
  flex-grow: 1;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubtitleButtonsContainer = styled.div`
  display: flex;
  gap: ${SIZE.max16};
  white-space: nowrap;
  align-items: center;

  button {
    height: 35px;
  }
`;
export const SubtitleText = styled.div`
  //margin-bottom: 20px;
`;

export const ComponentWrapper = styled.div<{ isOnViewMode?: boolean }>(
  ({ isOnViewMode }) => css`
    max-width: ${isOnViewMode ? "100%" : SIZE.max480};
    padding-right: ${isOnViewMode ? "50px" : 0};

    @media (max-width: 600px) {
      max-width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 16px;

    :nth-child(1) {
      margin-bottom: 30px;
    }
  `
);
export const ComponentAnswersWrapper = styled.div<{ isOnViewMode?: boolean }>(
  ({ isOnViewMode }) => css`
    display: flex;
    gap: 10px;
    background-color: ${COLORS.appWhite};
    padding: 10px 15px;
    border-radius: ${BORDER_RADIUS.cardRadius};
  `
);

export const SelectComponentContainer = styled.div`
  max-height: 250px;
  overflow: auto;
  padding-right: 10px;
`;
export const ComponentContainer = styled.div`
  padding-right: 10px;
`;

export const StepButtonContainer = styled.div`
  padding-left: ${SIZE.max80};
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: ${SIZE.max480};
`;
export const NextButtonContainer = styled.div`
  //background-color: ${COLORS.appBlue};
  flex: 1;
  border-radius: ${BORDER_RADIUS.cardRadius};
  display: flex;
  align-items: center;
`;

// MODAL

export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;

export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
`;

export const InputCustomWrapper = styled.div`
  margin-bottom: ${SIZE.max16};
`;
export const CheckboxListContainer = styled.div``;
export const BoundedLineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: ${SIZE.max16};
`;
export const BoundedText = styled.span`
  color: ${COLORS.appDarkGrey};
  font-size: ${FONT_SIZE.max12};
  white-space: nowrap;
`;
export const BoundedLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.appDarkGrey};
`;

export const ModalTitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  /* height: ${SIZE.max65}; */
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const IndividualButtonWrapper = styled.div``;
