// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomInput/CustomInputModel";
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  BORDER_RADIUS,
  SIZE,
} from "constants/variables";

export const InputComponentWrapper = styled.div<IStyled>(
  ({ appearance, validation }) => css`
    background-color: ${COLORS.appLightGrey};
    border: none;
    display: flex;
    justify-content: space-between;
    border-radius: ${BORDER_RADIUS.cardRadius};
    padding: ${SIZE.max16};
    width: 100%;
    ${appearance === "primary" && css``}
    ${appearance === "secondary" &&
    css`
      background-color: ${COLORS.appWhite};
      /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    `};

    ${validation === "successInput" &&
    css`
      border: solid 1px ${COLORS.appGreen};
    `}
    ${validation === "errorInput" &&
    css`
      border: solid 1px ${COLORS.appRed};
    `}
      &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    ${appearance === "small" &&
    css`
      background-color: transparent;
      padding-block: 6px;
      padding-left: 0;
    `}
  `
);

export const InputComponent = styled.input`
  background-color: transparent;
  width: 100%;
  border: none;
  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max16};
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: ${COLORS.appDarkGrey};
    font-size: ${FONT_SIZE.max16};
  }
`;

export const IconWrapper = styled.div<IStyled>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    min-width: ${FONT_SIZE.max12};
    max-width: ${SIZE.max24};
    width: 100%;
    min-height: ${FONT_SIZE.max12};
    max-height: ${SIZE.max24};
    padding: 0;
  }
`;
