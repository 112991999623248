import React, { useEffect } from "react";

// LIBRARIES
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// MISC
import { APP_ROLES } from "constants/variables";
import { useDecodedJwt } from "hooks/useDecodedJwt";

// COMPONENTS
import { RequireAuth } from "config/RequireAuth";
import Layout from "components/Layout/Layout";
import ControlPanelPage from "view/ControlPanelPage/ControlPanelPage";
import LoginPage from "view/LoginPage/LoginPage";
import UnauthorizedPage from "view/UnauthorizedPage/UnauthorizedPage";
import ActionPage from "view/ActionPage/ActionPage";
import StatisticsPage from "view/StatisticsPage/StatisticsPage";
import TemplatePage from "view/TemplatePage/TemplatePage";
import NewDocumentPage from "view/NewDocumentPage/NewDocumentPage";
import OperatorsPage from "view/OperatorsPage/OperatorsPage";
import ServicesPage from "view/ServicesPage/ServicesPage";
import OperatorLogsPage from "view/OperatorLogsPage/OperatorLogsPage";
import BeneficiaryPage from "view/BeneficiaryPage/BeneficiaryPage";
import CommunityCenters from "view/CommunityCenters/CommunityCenters";
import TargetGroups from "view/TargetGroups/TargetGroups";
import BeneficiaryListPage from "view/BeneficiaryListPage/BeneficiaryListPage";
import PasswordSettingPage from "view/PasswordSettingPage/PasswordSettingPage";
import TemplatesListPage from "view/TemplatesListPage/TemplatesListPage";
import NewDocumentsListPage from "view/NewDocumentsListPage/NewDocumentsListPage";
import ForgotPasswordPage from "view/ForgotPasswordPage/ForgotPasswordPage";
import ChangePasswordPage from "view/ChangePasswordPage/ChangePasswordPage";

const AppRoutes = () => {
  // LIBRARY CONSTANTS
  const { pathname } = useLocation();

  // LIFE CYCLE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { role } = useDecodedJwt();

  return (
    <Routes>
      {/* public routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/create-password/:id" element={<PasswordSettingPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />

      <Route
        element={
          <RequireAuth
            allowedRoles={[
              APP_ROLES.OPERATOR,
              APP_ROLES.ZONE_MANAGER,
              APP_ROLES.CENTER_MANAGER,
              APP_ROLES.ADMIN,
            ]}
          />
        }
      >
        <Route path="/change-password" element={<ChangePasswordPage />} />
      </Route>
      <Route element={<Layout />}>
        {/* public routes */}

        {/* private routes */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[APP_ROLES.OPERATOR, APP_ROLES.CENTER_MANAGER]}
            />
          }
        >
          <Route path="/control-panel" element={<ControlPanelPage />} />
          <Route path="/beneficiary-list" element={<BeneficiaryListPage />} />
          <Route path="/actions" element={<ActionPage />} />
          <Route path="/new-document-list" element={<NewDocumentsListPage />} />
          <Route path="/new-document" element={<NewDocumentPage />} />
          <Route path="/beneficiary" element={<BeneficiaryPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={[
                APP_ROLES.OPERATOR,
                APP_ROLES.ZONE_MANAGER,
                APP_ROLES.CENTER_MANAGER,
              ]}
            />
          }
        >
          <Route path="/statistics" element={<StatisticsPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[APP_ROLES.ADMIN]} />}>
          <Route path="/operator-logs" element={<OperatorLogsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/community-centers" element={<CommunityCenters />} />
          <Route path="/target-groups" element={<TargetGroups />} />
          <Route path="/template" element={<TemplatePage />} />
          <Route path="/templates-list" element={<TemplatesListPage />} />
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={[
                APP_ROLES.ADMIN,
                APP_ROLES.CENTER_MANAGER,
                APP_ROLES.ZONE_MANAGER,
              ]}
            />
          }
        >
          <Route path="/operators" element={<OperatorsPage />} />
        </Route>

        <Route
          path="*"
          element={
            <Navigate
              replace
              to={
                role === APP_ROLES.ADMIN || role === APP_ROLES.ZONE_MANAGER
                  ? "/operators"
                  : "/control-panel"
              }
            />
          }
        />
        {/* private routes */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
