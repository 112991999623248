import React, { useEffect, useRef } from "react";

// ASSETS

// STYLES
import * as styled from "./TemplatePageStyled";

// LIBRARIES
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { templateDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import { handleUpdateTemplateData } from "slices/TemplateSlice/templateSlice";
import { QUESTION_TYPES } from "constants/variables";
import {
  useCreateFormModelMutation,
  useEditFormModelMutation,
} from "api/adminApi";
import { handleChangeSnackBar } from "slices/SessionSlice/sessionSlice";
import useIsRequestLoading from "hooks/useRequestLoading";

// MISC

// COMPONENTS
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomTextArea from "components/Atoms/CustomTextArea/CustomTextArea";
import TemplateRadioList from "view/TemplatePage/TemplateRadioList/TemplateRadioList";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomHeader from "components/CustomHeader/CustomHeader";

// CONFIGURATION

const TemplatePage = () => {
  // PROPERTIES

  // API REQUESTS
  const [
    createFormModelRequest,
    {
      isError: isCreateFormError,
      error: createFormError,
      isLoading: isCreateFormLoading,
    },
  ] = useCreateFormModelMutation();
  const [
    editFormModelRequest,
    {
      isError: isEditFormError,
      error: editFormError,
      isLoading: isEditFormLoading,
    },
  ] = useEditFormModelMutation();

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();

  const sectionInputRef = useRef(null);
  const questionInputRef = useRef(null);

  const navigate = useNavigate();
  const { state } = useLocation();
  const templateDateSelector = useAppSelector(templateDataSelector);

  // STATE CONSTANTS
  const pageLoading = isCreateFormLoading || isEditFormLoading;
  useIsRequestLoading(pageLoading);

  // LIFE CYCLE
  useEffect(() => {
    if (templateDateSelector?.sections.length === 0) {
      dispatch(
        handleUpdateTemplateData({
          ...templateDateSelector,
          hasUnsavedChanges: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [templateDateSelector?.sections]);

  useEffect(() => {
    if (sectionInputRef?.current) {
      sectionInputRef?.current?.focus();
    }
  }, [templateDateSelector?.sections?.length]); // Triggered when a new section is added

  useEffect(() => {
    if (questionInputRef?.current) {
      questionInputRef?.current?.focus();
    }
  }, [templateDateSelector?.currentQuestionId]);

  console.log("isCreateFormError", isCreateFormError);

  useEffect(() => {
    if (isCreateFormError || isEditFormError) {
      const message = isCreateFormError
        ? createFormError?.["data"]?.details?.[0]
        : editFormError?.["data"]?.details?.[0];
      console.log("message", createFormError);
      let translatedMsg;

      if (message?.includes("already exists")) {
        translatedMsg = "Un formular cu acest titlu există deja!";
      } else {
        translatedMsg = "A apărut o eroare!";
      }

      dispatch(
        handleChangeSnackBar({
          snackBar: {
            open: true,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
          snackBarAlert: {
            message: translatedMsg,
            severity: "error",
            elevation: 6,
            variant: "filled",
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [isCreateFormError, isEditFormError]);

  // EVENT HANDLERS
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveChanges = async () => {
    const completeEmptyAnswers = templateDateSelector?.sections?.map(
      (section, sectionIndex) => {
        return {
          ...section,
          title: section?.title
            ? section?.title
            : `Secțiunea ${sectionIndex + 1}`,
          questions: section?.questions?.map((question, questionIndex) => {
            return {
              ...question,
              question: question?.question
                ? question?.question
                : `Întrebarea ${questionIndex + 1}`,
              answers: question?.answers?.map((answer, answerIndex) => {
                return {
                  answer: answer?.answer
                    ? answer?.answer
                    : `Varianta ${answerIndex + 1}`,
                };
              }),
            };
          }),
        };
      }
    );

    if (templateDateSelector.displayMode === "createMode") {
      const body = {
        formCategoryId: parseInt(templateDateSelector?.templateTypeId),
        sections: completeEmptyAnswers,
        title: templateDateSelector?.title,
      };

      await createFormModelRequest(body).then((res) => {
        if (res?.["error"]?.status === 500) return;

        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            sections: completeEmptyAnswers,
            hasUnsavedChanges: false,
          })
        );
        navigate(-1);
      });
    } else if (templateDateSelector.displayMode === "editMode") {
      const body = {
        formCategoryId: parseInt(templateDateSelector?.templateTypeId),
        sections: completeEmptyAnswers,
        title: templateDateSelector?.title,
        formId: state?.formId,
      };

      await editFormModelRequest(body).then((res) => {
        if (res?.["error"]?.status === 500) return;

        dispatch(
          handleUpdateTemplateData({
            ...templateDateSelector,
            sections: completeEmptyAnswers,
            hasUnsavedChanges: false,
          })
        );
      });
    }
  };

  const handleChangeSectionName = (event, sectionData) => {
    if (!templateDateSelector?.canBeEdited) return;

    const updatedTemplateData = {
      ...templateDateSelector,
      sections: templateDateSelector.sections.map((section) => {
        if (section.sectionId === sectionData.sectionId) {
          return {
            ...section,
            title: event.target.value,
          };
        }
        return section;
      }),
    };

    dispatch(
      handleUpdateTemplateData({
        ...updatedTemplateData,
        hasUnsavedChanges: true,
      })
    );
  };
  const handleChangeQuestionName = (event, questionData, sectionSection) => {
    if (!templateDateSelector?.canBeEdited) return;

    const updatedSections = templateDateSelector.sections.map((section) => {
      if (section.sectionId === sectionSection?.sectionId) {
        return {
          ...section,
          questions: section.questions.map((question) => {
            if (question.questionId === questionData?.questionId) {
              return {
                ...question,
                [event?.target?.name]: event?.target?.value,
              };
            }
            return question;
          }),
        };
      }
      return section;
    });

    const updatedTemplateData = {
      ...templateDateSelector,
      sections: updatedSections,
      hasUnsavedChanges: true,
    };

    dispatch(handleUpdateTemplateData(updatedTemplateData));
  };

  const renderData = (question) => {
    switch (question?.type) {
      case QUESTION_TYPES.ONLY_RADIO_BUTTON:
        return <TemplateRadioList question={question} type={"radio"} />;
      case QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS:
        return <TemplateRadioList question={question} type={"checkbox"} />;
      case QUESTION_TYPES.ONLY_SHORT_ANSWER:
        return (
          <CustomTextArea
            wordLength={100}
            type={"short"}
            name={"answer"}
            onChange={() => {}}
            value={""}
            placeholder={""}
          />
        );
      case QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER:
        return (
          <styled.TemplateTypeContainer>
            <TemplateRadioList question={question} type={"radio"} />
            <CustomTextArea
              wordLength={100}
              type={"short"}
              name={"answer"}
              onChange={() => {}}
              value={""}
              placeholder={""}
            />
          </styled.TemplateTypeContainer>
        );
      case QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER:
        return (
          <styled.TemplateTypeContainer>
            <TemplateRadioList question={question} type={"checkbox"} />
            <CustomTextArea
              wordLength={100}
              type={"short"}
              name={"answer"}
              onChange={() => {}}
              value={""}
              placeholder={""}
            />
          </styled.TemplateTypeContainer>
        );
      case QUESTION_TYPES.ONLY_LONG_ANSWER:
        return (
          <CustomTextArea
            wordLength={200}
            type={"long"}
            name={"answer"}
            onChange={() => {}}
            value={""}
            placeholder={""}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <styled.TemplatePageContainer>
      <CustomHeader text={"Înapoi la şabloane"} handleGoBack={handleGoBack} />
      <styled.TemplatePageContentContainer>
        {templateDateSelector?.sections?.map((section, index) => {
          return (
            <styled.TemplatePageContentWrapper
              key={`sections-list--${index}--${section?.sectionId}`}
            >
              {templateDateSelector?.currentSectionId ===
                section?.sectionId && (
                <styled.SectionTitleContainer>
                  <CustomText type={"H2"} text={`${index + 1}.`} />
                  <CustomInput
                    name={"title"}
                    ref={sectionInputRef}
                    placeholder={"Scrie titlul secțiunii aici"}
                    onChange={(event) =>
                      handleChangeSectionName(event, section)
                    }
                    value={section.title}
                  />
                </styled.SectionTitleContainer>
              )}
              {section.questions?.map((question, index) => {
                return (
                  templateDateSelector?.currentQuestionId ===
                    question?.questionId && (
                    <styled.QuestionContainer
                      key={`questions-list--${index}--${question?.questionId}`}
                    >
                      <styled.QuestionWrapper>
                        <CustomText type={"H3"} text={`${index + 1}.`} />
                        <CustomInput
                          ref={questionInputRef}
                          name={"question"}
                          placeholder={"Scrie titlul întrebării aici"}
                          onChange={(event) =>
                            handleChangeQuestionName(event, question, section)
                          }
                          value={question.question}
                        />
                      </styled.QuestionWrapper>
                      <styled.QuestionTypeWrapper>
                        {renderData(question)}
                      </styled.QuestionTypeWrapper>
                    </styled.QuestionContainer>
                  )
                );
              })}
            </styled.TemplatePageContentWrapper>
          );
        })}
      </styled.TemplatePageContentContainer>
      {templateDateSelector?.canBeEdited && (
        <styled.SaveButtonContainer>
          <CustomButton
            appearance={
              templateDateSelector?.hasUnsavedChanges ? "primary" : "disabled"
            }
            text={"Salvează"}
            handleClick={
              templateDateSelector?.hasUnsavedChanges
                ? handleSaveChanges
                : () => {}
            }
          />
        </styled.SaveButtonContainer>
      )}
    </styled.TemplatePageContainer>
  );
};

export default TemplatePage;
