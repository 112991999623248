import React from "react";

// ASSETS

// STYLES
import * as styled from "./LayoutStyled";

// LIBRARIES
import { Outlet } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import { isRequestLoadingSelector } from "slices/SessionSlice/sessionSliceSelectors";

// MISC
import { useCurrentDevice } from "hooks/useCurrentDevice";

// COMPONENTS
import Header from "components/Header/Header";
import SideMenu from "components/SideMenu/SideMenu";

// CONFIGURATION
const Layout = () => {
  // PROPERTIES
  const currentPath =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const currentPathWithId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 2
    ];

  const { currentDevice } = useCurrentDevice();

  const headerAllowedPaths = [
    "control-panel",
    "beneficiary-list",
    "statistics",
    "actions",
    "templates-list",
    "operators",
    "operator-logs",
    "services",
    "community-centers",
    "target-groups",
    "beneficiary",
  ];
  const leftSideAllowedPaths = [
    "control-panel",
    "beneficiary-list",
    "statistics",
    "templates-list",
    "actions",
    "operators",
    "operator-logs",
    "services",
    "community-centers",
    "target-groups",
  ];
  const rightSideAllowedPaths = ["actions", "new-document", "template"];

  // API REQUESTS

  // LIBRARY CONSTANTS
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);
  const isRequestLoadingData = useAppSelector(isRequestLoadingSelector);

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const checkCurrentPage = () => {
    if (currentPath !== "new-document") {
      return true;
    } else {
      return newDocumentSelector?.displayMode !== "viewMode";
    }
  };

  return (
    <styled.LayoutContainer>
      {headerAllowedPaths?.includes(currentPath) && (
        <styled.HeaderWrapper isLoading={isRequestLoadingData}>
          <Header appearance="full" />
        </styled.HeaderWrapper>
      )}
      <styled.ContentContainer isLoading={isRequestLoadingData}>
        {leftSideAllowedPaths?.includes(currentPath) && (
          <styled.SideMenuWrapper side="left">
            <SideMenu side="left" />
          </styled.SideMenuWrapper>
        )}
        <styled.ContentWrapper>
          <styled.OutletWrapper
            hasSideBar={leftSideAllowedPaths?.includes(currentPath)}
          >
            <Outlet />
          </styled.OutletWrapper>
        </styled.ContentWrapper>
        {(rightSideAllowedPaths?.includes(currentPath) ||
          rightSideAllowedPaths?.includes(currentPathWithId)) &&
          currentDevice !== "mobile" &&
          checkCurrentPage() && (
            <styled.SideMenuWrapper side="right">
              <SideMenu side="right" />
            </styled.SideMenuWrapper>
          )}
      </styled.ContentContainer>
    </styled.LayoutContainer>
  );
};

export default Layout;
