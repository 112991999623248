export const operatorsTableHeadValues = [
  {
    name: "Email",
    sortField: "username",
    sortOrder: "",
  },
  {
    name: "Nume",
    sortField: "fullName",
    sortOrder: "",
  },
  {
    name: "Rol",
    sortField: "role",
    sortOrder: "",
  },

  {
    name: "Centru Comunitar",
    sortField: "communityCenter",
    sortOrder: "",
  },
  {
    name: "Status",
    sortField: "status",
    sortOrder: "",
  },
  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "",
  },
];

export const operatorsTableBodyValues = [
  {
    id: 1,
    name: "Alexandru Georgescu",
    email: "alexandru.georgescu@dgas.ro",
    communityCenter: "Faraoani",
    role: "Operator",
    status: "Activ",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 2,
    name: "Ioana Mendelea",
    email: "Ioanam67@dgas.ro",
    communityCenter: "Faraoani",
    role: "Operator",
    status: "Activ",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 3,
    name: "Alexandru Costin",
    email: "alexandru.costin@dgas.ro",
    communityCenter: "Faraoani",
    role: "Operator",
    status: "Inactiv",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 4,
    name: "Ioana Mendelea",
    email: "Ioanam67@dgas.ro",
    communityCenter: "Faraoani",
    role: "Operator",
    status: "Activ",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 5,
    name: "Ioana Mendelea",
    email: "Ioanam67@dgas.ro",
    communityCenter: "Faraoani",
    role: "Operator",
    status: "Activ",
    createdAt: "19.02.2023",
    isActive: true,
  },
];

export const operatorLogHeadValues = [
  {
    name: "Tip Actiune",
    sortField: "actionType",
    sortOrder: "none",
  },
  {
    name: "Denumire",
    sortField: "name",
    sortOrder: "none",
  },
  {
    name: "Beneficiar",
    sortField: "beneficiary",
    sortOrder: "none",
  },
  {
    name: "Data si ora",
    sortField: "createdAt",
    sortOrder: "none",
  },
];

export const operatorLogBodyValues = [
  {
    id: 1,
    actionType: "Modificat",
    name: "Fișă de consiliere și informare socială",
    beneficiary: "Reti Ana",
    dateAndHour: "28.02.2023 - 14:52",
  },
  {
    id: 2,
    actionType: "Descărcat",
    name: "Hemoleucograma completa.pdf",
    beneficiary: "Roca Boldog Iosif",
    dateAndHour: "30.03.2023 - 16:03",
  },
  {
    id: 3,
    actionType: "Adăugat activitate",
    name: "Ședință kinetoterapie",
    beneficiary: "Cochioarca Marcu Damian",
    dateAndHour: "02.04.2023 - 10:11",
  },
];
export const communityCentersHeadValues = [
  {
    name: "Nume",
    sortField: "name",
    sortOrder: "",
  },
  {
    name: "Judet",
    sortField: "county",
    sortOrder: "",
  },

  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "",
  },
];

export const targetGroupsHeadValues = [
  {
    name: "Cod",
    sortField: "code",
    sortOrder: "none",
  },
  {
    name: "Denumire",
    sortField: "name",
    sortOrder: "none",
  },

  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "none",
  },
];

export const serviceHeadValues = [
  {
    name: "Nume Categorie",
    sortField: "name",
    sortOrder: "",
  },

  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "",
  },
  {
    name: "Ultima Actualizare",
    sortField: "lastUpdateAt",
    sortOrder: "",
  },
];

export const beneficiaryDetails = [
  {
    name: "ROCA BOLDOG IOSIF",
  },
];

export const sideBeneficiaryData = [
  {
    label: "Data înregistrării",
    info: "06.09.2021",
  },
  {
    label: "Data nașterii",
    info: "18.03.1975",
  },
  {
    label: "CNP",
    info: "1750318040056",
  },
  {
    label: "Sex",
    info: "M",
  },
  {
    label: "Localitate",
    info: "Faraoani",
  },
  {
    label: "Centru comunitar",
    info: "Faraoani",
  },
  {
    label: "Cat. Grup. Țintă",
    info: "ADIF",
  },
  {
    label: "Descrierea afecțiunii",
    info: "Fam. vulnerabila",
  },
];

export const cardItemsData = [
  {
    name: "Roca Boldog Iosif",
    signUp: "29.04.2022",
    services: 2,
  },
  {
    name: "Reti Ana",
    signUp: "03.11.2022",
    services: 7,
  },
  {
    name: "Cadar Diana Maria",
    signUp: "15.06.2023",
    services: 5,
  },
  {
    name: "Roca Boldog Iosif",
    signUp: "29.04.2022",
    services: 2,
  },
  {
    name: "Duma Felicia",
    signUp: "29.04.2022",
    services: 2,
  },
  {
    name: "Cochioarca Marcu Damian",
    signUp: "29.04.2022",
    services: 2,
  },
  {
    name: "Antal Alexandra Stefania",
    signUp: "29.04.2022",
    services: 10,
  },
  {
    name: "Roca Boldog Iosif",
    signUp: "29.04.2022",
    services: 2,
  },
  {
    name: "Reti Ana",
    signUp: "03.11.2022",
    services: 7,
  },
  {
    name: "Cadar Diana Maria",
    signUp: "15.06.2023",
    services: 5,
  },
];
