// LIBRARIES
import styled from "styled-components";
import { Modal } from "@mui/material";

// MISC
import { BORDER_RADIUS, COLORS, SIZE, SHADOW } from "constants/variables";

export const ModalComponent = styled(Modal)`
  background-color: rgba(176, 179, 191, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${SIZE.max24};
  outline: none;
  box-shadow: ${SHADOW.modalShadow};
  backdrop-filter: blur(5px);
`;
export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;
export const TitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ModalContent = styled.div`
  padding: ${SIZE.max40};
  padding-bottom: ${SIZE.max104};
  display: flex;
  flex-flow: row wrap;
  gap: ${SIZE.max16};
`;
export const CheckBoxWrapper = styled.div``;
export const InputCustomWrapper = styled.div`
  margin-bottom: ${SIZE.max16};
`;
export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const IndividualButtonWrapper = styled.div``;
