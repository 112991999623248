// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS, SIZE } from "constants/variables";

export const CustomHeaderContainer = styled.div`
  width: 100%;
  padding: ${SIZE.max24};
  display: flex;
  gap: ${SIZE.max24};
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  div:last-child {
    span {
      color: ${COLORS.appDarkGrey};
    }
  }
`;
