// LIBRARIES
import styled, { css } from "styled-components";
import { Table, TableCell, TableRow } from "@mui/material";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
} from "constants/variables";
import { IStyled } from "components/Atoms/CustomTable/CustomTableModel";
import { ReactComponent as ArrowHead } from "assets/icons/tableHeadArrow.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/tableDeleteIcon.svg";
import { ReactComponent as EditIcon } from "assets/icons/tableEditIcon.svg";
import { ReactComponent as CrossIcon } from "assets/icons/tableCrossIcon.svg";
import { ReactComponent as AddIcon } from "assets/icons/AddIcon.svg";

export const TableContainer = styled(Table)`
  min-width: 750px;

  .custom-table-head {
    border-bottom: 2px solid ${COLORS.appLightGrey};
  }
  .custom-table-head-row {
    color: ${COLORS.appWhite};

    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }
  .custom-table-head-cell {
    color: ${COLORS.appDarkGrey};
    background-color: ${COLORS.appWhite};
    text-transform: uppercase;
    font-size: ${FONT_SIZE.max14};
    font-weight: ${FONT_WEIGHT.semiBold};
    height: 68px;
    &:first-child {
      border-top-left-radius: ${BORDER_RADIUS.cardRadius};
    }
    &:last-child {
      border-top-right-radius: ${BORDER_RADIUS.cardRadius};
    }
  }
  }
`;
export const TableRowComp = styled(TableRow)`
  cursor: pointer;
  background-color: ${COLORS.appWhite};
  border-bottom: 2px solid ${COLORS.appLightGrey};

  td {
    padding: 4px;
  }

  &:hover > td {
    background-color: ${COLORS.appHover};
  }
`;
export const TableHeadCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
export const TableHeadCellArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const TableStatusCellTopArrow = styled(ArrowHead)<{
  isHighlighted: boolean;
}>(
  ({ isHighlighted = false }) => css`
    height: 6px;
    width: 10px;

    ${isHighlighted &&
    css`
      filter: brightness(0) saturate(100%) invert(38%) sepia(100%)
        saturate(6775%) hue-rotate(228deg) brightness(89%) contrast(90%);
    `}
  `
);
export const TableStatusCellBottomArrow = styled(ArrowHead)<{
  isHighlighted: boolean;
}>(
  ({ isHighlighted = false }) => css`
    height: 6px;
    width: 10px;
    transform: rotate(180deg);

    ${isHighlighted &&
    css`
      filter: brightness(0) saturate(100%) invert(38%) sepia(100%)
        saturate(6775%) hue-rotate(228deg) brightness(89%) contrast(90%);
    `}
  `
);

export const TableActionIcons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;
export const TableActionEditIconContainer = styled.div``;
export const TableEditIcon = styled(EditIcon)``;
export const TableActionDeleteIconContainer = styled.div``;
export const TableDeleteIcon = styled(DeleteIcon)``;
export const TableActionCrossIconContainer = styled.div``;
export const TableCrossIcon = styled(CrossIcon)``;
export const TableActionAddIconContainer = styled.div``;
export const TableAddIcon = styled(AddIcon)``;

export const TableCellComp = styled(TableCell)`
  border-color: ${COLORS.appWhite};
  font-size: ${FONT_SIZE.max14};
`;

export const TableStatusCellContainer = styled.div``;

export const TableStatusCellText = styled.span<IStyled>(
  ({ value }) => css`
    color: ${value};
  `
);
