import { useEffect, useState } from "react";

// MISC
import { DEVICE_TYPE } from "constants/variables";

export const useCurrentDevice = () => {
  const isSSR = typeof window !== "undefined";

  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  const [currentDevice, setCurrentDevice] = useState(DEVICE_TYPE.DESKTOP);

  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? "landscape" : "portrait"
  );

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    if (window.innerWidth < 600) {
      setCurrentDevice(DEVICE_TYPE.MOBILE);
    } else if (window.innerWidth < 1280) {
      setCurrentDevice(DEVICE_TYPE.TABLET);
    } else {
      setCurrentDevice(DEVICE_TYPE.DESKTOP);
    }

    setOrientation(
      window.innerWidth > window.innerHeight ? "landscape" : "portrait"
    );
  };

  const handleResize = () => {
    changeWindowSize();
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return { windowSize, currentDevice, orientation };
};
