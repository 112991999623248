// LIBRARIES
import styled from "styled-components";

// MISC
import { SIZE } from "constants/variables";
export const TableSkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${SIZE.max24};
`;

export const ContentSkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 28px;
`;
export const RowSkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
`;
export const CellSkeletonContainer = styled.div`
  width: 100%;
`;
