// LIBRARIES
import styled from "styled-components";

// MISC
import { SIZE } from "constants/variables";

export const BeneficiaryListPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${SIZE.max24};
  min-width: 200px;
`;
export const UpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;

export const FilterSectionContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  justify-content: flex-end;
  min-width: 170px;
  padding-right: ${SIZE.max55};
  @media (max-width: 575px) {
    flex-direction: column;
    padding-right: 0;
  }
`;

export const FilterSectionWrapper = styled.div``;
export const DownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  padding-right: ${SIZE.max55};
`;

export const CardHistoryContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 575px) {
    justify-content: center;
  }
`;
