// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  SIZE,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from "constants/variables";
export const HeaderWrapper = styled.div``;
export const HeaderContainer = styled.div`
  background-color: ${COLORS.appWhite};
  height: 100%;
  overflow: auto;
  padding: ${SIZE.max24} ${SIZE.max40};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${SIZE.max160};
`;
export const FirstPartHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${SIZE.max160};
  min-height: 40px;
  width: 100%;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  cursor: pointer;
  & > svg {
    height: ${SIZE.max24};
    min-width: 100px;
    width: 100%;
    max-width: 150px;
  }
`;
export const SearchComponentWrapper = styled.div`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.semiBold};
  color: ${COLORS.appDarkGrey};
  white-space: nowrap;
  width: 100%;
`;
export const OperatorDetailes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
  line-height: ${FONT_SIZE.max14};
`;
export const ForgotPasswordTest = styled.span`
  color: ${COLORS.appDarkGrey};
  font-size: ${FONT_SIZE.max12};
  cursor: pointer;
  font-weight: ${FONT_WEIGHT.medium};
  padding-top: 4px;
`;
