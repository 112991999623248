// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomText/CustomTextModel";
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from "constants/variables";

export const CustomTextContainer = styled.div``;

export const CustomTextLabel = styled.span<IStyled>(
  ({ type }) => css`
    && {
      font-family: ${FONT_FAMILY.poppins};
      ${type === "H1" &&
      css`
        font-size: ${FONT_SIZE.max56};
        font-weight: ${FONT_WEIGHT.semiBold};
        color: ${COLORS.appBlack};
      `}
      ${type === "H2" &&
      css`
        font-size: ${FONT_SIZE.max26};
        font-weight: ${FONT_WEIGHT.medium};
        color: ${COLORS.appBlack};
      `}
      ${type === "H3" &&
      css`
        font-size: ${FONT_SIZE.max20};
        font-weight: ${FONT_WEIGHT.bold};
        color: ${COLORS.appBlack};
      `}
      ${type === "H4" &&
      css`
        font-size: ${FONT_SIZE.max16};
        font-weight: ${FONT_WEIGHT.semiBold};
        color: ${COLORS.appBlack};
      `}
      ${type === "H5" &&
      css`
        font-size: ${FONT_SIZE.max16};
        font-weight: ${FONT_WEIGHT.regular};
        color: ${COLORS.appBlack};
      `}
      ${type === "H6" &&
      css`
        font-size: ${FONT_SIZE.max14};
        font-weight: ${FONT_WEIGHT.semiBold};
        color: ${COLORS.appBlack};
      `}
      ${type === "H7" &&
      css`
        font-size: ${FONT_SIZE.max12};
        font-weight: ${FONT_WEIGHT.bold};
        color: ${COLORS.appDarkGrey};
      `}
      ${type === "H8" &&
      css`
        font-size: ${FONT_SIZE.max12};
        font-weight: ${FONT_WEIGHT.bold};
        color: ${COLORS.appRed};
      `}
    }
  `
);
