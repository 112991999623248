import React, { useState, useEffect } from "react";

// ASSETS

// STYLES
import * as styled from "./BeneficiaryListPageStyled";

// LIBRARIES
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { clientBeneficiaryPaginationSelector } from "slices/ClientSlice/clientSliceSelectors";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGetAllBeneficiariesQuery } from "api/beneficiaryApi";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";
import { useNavigate } from "react-router-dom";

// MISC
import { beneficiaryListSort } from "mocks/beneficiaryPageMocks";
import useTableHeadDetails from "hooks/useTableHeadHook";

// COMPONENTS
import BeneficiaryCard from "components/BeneficiaryCard/BeneficiaryCard";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomFilterButton from "components/Atoms/CustomFilterButton/CustomFilterButton";
import useIsRequestLoading from "hooks/useRequestLoading";

// CONFIGURATION

const BeneficiaryListPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clientBeneficiaryPaginationDetails = useAppSelector(
    clientBeneficiaryPaginationSelector
  );
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(beneficiaryListSort);

  // STATE CONSTANTS
  const [sizeByResolution, setSizeByResolution] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth;

      dispatch(
        handlePagePaginationChange({
          value: 1,
          clientPage: "clientBeneficiaryPagination",
        })
      );

      if (innerWidth > 1868) {
        setSizeByResolution(14);
      } else if (innerWidth >= 1620) {
        setSizeByResolution(12);
      } else {
        setSizeByResolution(10);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  // API REQUESTS
  const {
    data: beneficiaryList,
    isLoading: isBeneficiaryListLoading,
    isFetching: isBeneficiaryListFetching,
  } = useGetAllBeneficiariesQuery({
    page: clientBeneficiaryPaginationDetails.pageNumber - 1,
    size: sizeByResolution,
    searchQuery: headerSearchValueDetails,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const pageLoading = isBeneficiaryListLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleCardPress = (card: object) => {
    dispatch(
      handlePagePaginationChange({
        value: 1,
        clientPage: "clientBeneficiaryActivitiesPagination",
      })
    );

    navigate("/beneficiary", { state: card });
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    clientPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        clientPage,
      })
    );
  };

  return (
    <styled.BeneficiaryListPageContainer>
      <styled.UpContainer>
        <styled.FilterSectionContainer>
          {tableHeadDetails?.map((item, index) => {
            return (
              <styled.FilterSectionWrapper key={`filter-item--${index}`}>
                <CustomFilterButton
                  onButtonPress={handleChangeSortOrder}
                  filterData={item}
                />
              </styled.FilterSectionWrapper>
            );
          })}
        </styled.FilterSectionContainer>
        <styled.CardHistoryContainer>
          {beneficiaryList?.content?.map((card, index) => (
            <BeneficiaryCard
              cardData={card}
              key={`card-item--${index}`}
              handleCardPress={handleCardPress}
              appearance={card.isActive === true ? "enable" : "disable"}
              isFetching={isBeneficiaryListFetching}
            />
          ))}
        </styled.CardHistoryContainer>
      </styled.UpContainer>
      <styled.DownContainer>
        <CustomPagination
          page={clientBeneficiaryPaginationDetails?.pageNumber}
          count={Math.ceil(
            beneficiaryList?.["totalElements"] / sizeByResolution
          )}
          onChange={(event, value) =>
            handlePageChange(event, value, "clientBeneficiaryPagination")
          }
        />
      </styled.DownContainer>
    </styled.BeneficiaryListPageContainer>
  );
};

export default BeneficiaryListPage;
