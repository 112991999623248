// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const ActionPageContainer = styled.div``;
export const ModalContainer = styled.div`
  background-color: ${COLORS.appWhite};

  min-width: 400px;
  width: 100%;
  max-width: 720px;

  border-radius: ${BORDER_RADIUS.cardRadius};

  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};

  -webkit-box-shadow: 0px 8px 15px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 8px 15px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 8px 15px -12px rgba(0, 0, 0, 0.75);
`;
export const InputSectionContainer = styled.div`
  display: flex;
  gap: ${SIZE.max16};
  flex-direction: column;
  padding: ${SIZE.max24};

  -webkit-box-shadow: 0px 8px 15px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 8px 15px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 8px 15px -15px rgba(0, 0, 0, 0.75);

  && div {
    background-color: ${COLORS.appLightGrey + "70"};
  }
`;

export const CustomTextarea = styled.textarea`
  width: 100%;

  background-color: ${COLORS.appLightGrey + "70"};
  border: none;
  border-radius: ${BORDER_RADIUS.cardRadius};
  padding: 16px;

  outline: none;
  resize: none;

  color: ${COLORS.appBlack};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max14};

  &::placeholder {
    color: ${COLORS.appDarkGrey};
    font-size: ${FONT_SIZE.max16};
  }
`;
export const ActionSectionContainer = styled.div`
  padding: 0 ${SIZE.max24} ${SIZE.max24} ${SIZE.max24};
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 8px 12px;
  }
`;
export const AddItemButtonContainer = styled.div``;
