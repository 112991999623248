import { createSelector } from "reselect";
import { adminSelector } from "slices/AdminSlice/adminSlice";

export const operatorsPaginationSelector = createSelector(
  adminSelector,
  (admin) => admin.operatorsPagination
);
export const activitiesPaginationSelector = createSelector(
  adminSelector,
  (admin) => admin.activitiesPagination
);
export const communityCentersPaginationSelector = createSelector(
  adminSelector,
  (admin) => admin.communityCentersPagination
);
export const targetGroupsPaginationSelector = createSelector(
  adminSelector,
  (admin) => admin.targetGroupsPagination
);
export const usersLogsPaginationSelector = createSelector(
  adminSelector,
  (admin) => admin.usersLogsPagination
);
