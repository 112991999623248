import React, { useState, useEffect } from "react";

// ASSETS

// STYLES
import * as styled from "./CustomHorizontalBarChartStyled";

// LIBRARIES
import { Tooltip } from "@mui/material";

// MISC
import { IProps } from "./CustomHorizontalBarChartModel";
import { COLORS } from "constants/variables";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";

// COMPONENTS

// CONFIGURATION

const HorizontalLine = ({ percentage, color, tooltip = "" }) => {
  const [fillWidth, setFillWidth] = useState(0);

  useEffect(() => {
    setFillWidth(percentage);
  }, [percentage]);

  const containerStyle = {
    width: "100%",
    cursor: "pointer",
    borderRadius: "10px",
    overflow: "hidden",
  };

  const fillStyle = {
    height: "10px",
    width: `${fillWidth}%`,
    backgroundColor: `${color}`,
    borderRadius: "10px",
    transition: "width 0.8s ease-in-out",
  };

  return (
    <Tooltip title={`${tooltip}`} placement="top-start">
      <div style={containerStyle}>
        <div style={fillStyle}></div>
      </div>
    </Tooltip>
  );
};
const CustomBarChart = (props: IProps) => {
  // PROPERTIES
  const { data, handleSelectChange, selectedOption } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.BarChartComponentContainer>
      <styled.BarChartHeaderContainer>
        <CustomText type="H3" text={data?.title} />
        {data?.hasDropdown && (
          <styled.SelectContainer>
            <CustomSelect
              options={data?.options}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </styled.SelectContainer>
        )}
      </styled.BarChartHeaderContainer>
      <styled.BarChartBodyContainer>
        {data?.values?.map((item, index) => {
          return (
            <styled.BarChartItemContainer key={`bar-item-list--${index}`}>
              <styled.BarChartItemTitleContainer>
                <CustomText type="H4" text={item?.label} />
              </styled.BarChartItemTitleContainer>
              <styled.BarChartItemValueContainer>
                <HorizontalLine
                  percentage={item?.topValue}
                  color={COLORS.appBlack}
                  tooltip={`${item?.topTooltip} Barbati`}
                />
                <HorizontalLine
                  percentage={item?.bottomValue}
                  color={COLORS.appBlue}
                  tooltip={`${item?.bottomTooltip} Femei`}
                />
              </styled.BarChartItemValueContainer>
              <CustomText
                type="H4"
                text={`${parseInt(item?.percent).toFixed(0)}%`}
              />
            </styled.BarChartItemContainer>
          );
        })}
      </styled.BarChartBodyContainer>

      <styled.BarChartLegend>
        <styled.BarChartLegendItem>
          <styled.BarChartDot color={COLORS.appBlack} />
          <CustomText type="H7" text={data?.legend.topBarLabel} />
        </styled.BarChartLegendItem>
        <styled.BarChartLegendItem>
          <styled.BarChartDot color={COLORS.appBlue} />
          <CustomText type="H7" text={data?.legend.bottomBarLabel} />
        </styled.BarChartLegendItem>
      </styled.BarChartLegend>
    </styled.BarChartComponentContainer>
  );
};

export default CustomBarChart;
