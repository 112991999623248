// LIBRARIES
import styled from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

// MISC

export const ServicesPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;

export const ActivitiesPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AddUserButtonContainer = styled.div`
  button {
    padding: 6px 12px;

    span {
      font-size: 14px;
    }
  }
`;
export const ActivitiesPageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  height: 666px;
`;
export const ActivitiesPageTableWrapper = styled.div`
  height: 100%;
  background-color: ${COLORS.appWhite};
  overflow-y: auto;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ActivitiesPagePaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;

export const TitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  /* height: ${SIZE.max65}; */
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;

export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
`;
export const InputCustomWrapper = styled.div`
  margin-bottom: ${SIZE.max16};
`;
export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const IndividualButtonWrapper = styled.div``;
