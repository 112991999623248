import React from "react";

// ASSETS
import { ReactComponent as AddMore } from "assets/icons/AddMoreIcon.svg";

// STYLES
import * as styled from "./CustomDragNDropStyled";

// LIBRARIES
import { DragDropContext } from "react-beautiful-dnd";

// MISC
import { IProps } from "./CustomDragNDropModel";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import DndColumn from "./DndColumn/DndColumn";

// CONFIGURATION
const CustomDragNDrop = (props: IProps) => {
  // PROPERTIES
  const { dndData = [], handlePassData, handleDisplayModal } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE
  const onDragEnd = (result, columns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn];
      const destItems = [...destColumn];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      const localData = {
        ...columns,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destItems,
      };

      handlePassData(localData);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      const localData = {
        ...columns,
        [source.droppableId]: copiedItems,
      };

      handlePassData(localData);
    }
  };

  // EVENT HANDLERS
  const translateText = (text) => {
    switch (text) {
      case "planned":
        return "Planificate";
      case "inProgress":
        return "In desfasurare";
      case "done":
        return "Incheiate";
    }
  };

  return (
    <styled.CustomDragNDropContainer>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, dndData)}>
        {Object?.entries(dndData)?.map(([columnId, column]) => {
          return (
            <styled.DndContentWrapper key={columnId}>
              <styled.DndTitleContainer>
                <CustomText text={translateText(columnId)} type={"H4"} />
                <styled.DndAddMoreContainer
                  onClick={() => handleDisplayModal(columnId)}
                >
                  <AddMore />
                </styled.DndAddMoreContainer>
              </styled.DndTitleContainer>

              <DndColumn
                droppableId={columnId}
                key={columnId}
                column={column}
              />
            </styled.DndContentWrapper>
          );
        })}
      </DragDropContext>
    </styled.CustomDragNDropContainer>
  );
};

export default CustomDragNDrop;
