import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./ControlPanelPageStyled";

// LIBRARIES
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
import {
  useGetAllBeneficiariesQuery,
  useGetOperatorsCompletedFormsQuery,
  useLazyGetCompletedFormByIdQuery,
} from "api/beneficiaryApi";
import { useNavigate } from "react-router-dom";
import { clientControlPagePaginationSelector } from "slices/ClientSlice/clientSliceSelectors";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";

// MISC
import { operatorsControlPanelTableHeadValues } from "mocks/operatorMock";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomBarChart from "components/Atoms/CustomBarChart/CustomBarChart";
import CustomTable from "components/Atoms/CustomTable/CustomTable";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import BeneficiaryCard from "components/BeneficiaryCard/BeneficiaryCard";
import useTableHeadDetails from "hooks/useTableHeadHook";
import useIsRequestLoading from "hooks/useRequestLoading";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";

// CONFIGURATION

const ControlPanelPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const controlPagePaginationDetails = useAppSelector(
    clientControlPagePaginationSelector
  );

  const dispatch = useDispatch();
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(operatorsControlPanelTableHeadValues);

  const newDocumentSelector = useAppSelector(newDocumentDataSelector);

  // STATE CONSTANTS
  const navigate = useNavigate();
  const [nrOfCards, setNrOfCards] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth;
      if (innerWidth >= 1500) {
        setNrOfCards(7);
      } else {
        setNrOfCards(5);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // API REQUESTS
  const {
    data: beneficiaryList,
    isLoading: isBeneficiaryListLoading,
    isFetching: isBeneficiaryListFetching,
  } = useGetAllBeneficiariesQuery({
    page: 0,
    size: nrOfCards,
    searchQuery: "",
    sortField: "createdAt",
    sortOrder: "desc",
  });

  const {
    data: operatorsFormList,
    isLoading: isOperatorsFormListLoading,
    isFetching: isTableFetching,
  } = useGetOperatorsCompletedFormsQuery({
    page: controlPagePaginationDetails?.pageNumber - 1,
    size: 5,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const [
    getCompletedFormByIdRequest,
    { isLoading: isGetCompletedFormByIdLoading },
  ] = useLazyGetCompletedFormByIdQuery();

  const pageLoading =
    isBeneficiaryListLoading ||
    isOperatorsFormListLoading ||
    isGetCompletedFormByIdLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    clientPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        clientPage,
      })
    );
  };

  const handleCardPress = (card: object) => {
    dispatch(
      handlePagePaginationChange({
        value: 1,
        clientPage: "clientBeneficiaryActivitiesPagination",
      })
    );

    navigate("/beneficiary", { state: card });
  };

  const onRowClick = async (row: any) => {
    await getCompletedFormByIdRequest({
      formId: row["id"],
    }).then((res) => {
      let counter = 0;

      let localSections = res?.data?.sections?.map((section, index) => {
        return {
          ...section,
          completed: index === 0 ? 1 : 0,
          questions: section?.questions?.map((question) => {
            const localOptions = question?.answers?.map((option) => {
              return {
                value: option?.answer,
                key: option?.answerId,
              };
            });
            return {
              ...question,
              name: `${question?.type}-${question?.questionId}`,
              step: counter++,
              options: localOptions,
              completedAnswer: question?.completedAnswer || "",
              selectedOptions: question?.selectedOptions || [],
            };
          }),
        };
      });

      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          displayMode: "viewMode",
          hasUnsavedChanges: true,
          completionDuration: res?.data?.duration,
          title: res?.data?.title,
          currentFlow: "modelFlow",
          formId: row["id"],
          currentStep: 0,
          currentBeneficiaryData: row["beneficiary"],
          sections: [...localSections],
        })
      );
      navigate(`/new-document`, { state: { formId: row["id"] } });
    });
  };

  return (
    <styled.ControlPanelPageContainer>
      <styled.LastBeneficiaryContainer>
        <styled.TabSectionContainer>
          <CustomText type={"H3"} text={"Ultimii beneficiar introduși"} />
        </styled.TabSectionContainer>
        <styled.CardHistoryContainer>
          {beneficiaryList?.content?.map((card, index) => (
            <BeneficiaryCard
              cardData={card}
              key={`card-item--${index}`}
              handleCardPress={handleCardPress}
              appearance={card.isActive === true ? "enable" : "disable"}
              isFetching={isBeneficiaryListFetching}
            />
          ))}
        </styled.CardHistoryContainer>
      </styled.LastBeneficiaryContainer>
      <styled.DetailsContainer>
        <styled.TableSectionContainer>
          <styled.ImmediateActionsContainer>
            <styled.TableWrapper>
              {isTableFetching ? (
                <CustomSkeletonTable
                  rowCount={6}
                  columnCount={tableHeadDetails?.length}
                />
              ) : (
                <CustomTable
                  banList={["id", "beneficiary"]}
                  headerData={tableHeadDetails}
                  bodyData={operatorsFormList?.content}
                  onHeadCellPress={handleChangeSortOrder}
                  onRowClick={onRowClick}
                />
              )}
            </styled.TableWrapper>
          </styled.ImmediateActionsContainer>
          {Math.ceil(
            operatorsFormList?.["totalElements"] /
              controlPagePaginationDetails?.pageSize
          ) > 1 && (
            <styled.PaginationContainer>
              <CustomPagination
                page={controlPagePaginationDetails?.pageNumber}
                count={Math.ceil(
                  operatorsFormList?.["totalElements"] /
                    controlPagePaginationDetails?.pageSize
                )}
                onChange={(event, value) =>
                  handlePageChange(event, value, "clientControlPagePagination")
                }
              />
            </styled.PaginationContainer>
          )}
        </styled.TableSectionContainer>

        <styled.ChartContainer>
          <CustomBarChart />
        </styled.ChartContainer>
      </styled.DetailsContainer>
    </styled.ControlPanelPageContainer>
  );
};

export default ControlPanelPage;
