// LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./tempSliceModel";
import { revertAll } from "store/store";

export const tempSlice = createSlice({
  name: "temp",
  initialState,
  reducers: {
    handleSearchChange: (state, action) => {
      state.headerSearchValue = action.payload.value;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { handleSearchChange } = tempSlice.actions;

export const tempSelector = (state) => state.temp;
export default tempSlice.reducer;
