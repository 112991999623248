// ASSETS

// STYLES
import * as styled from "./CustomSkeletonTableStyled";
import "react-loading-skeleton/dist/skeleton.css";

// LIBRARIES
import Skeleton from "react-loading-skeleton";

// MISC
import { IProps } from "./CustomSkeletonTableModel";

// COMPONENTS

// CONFIGURATION

const CustomSkeletonTable = (props: IProps) => {
  // PROPERTIES
  const { rowCount = 1, columnCount = 0 } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.TableSkeletonContainer>
      <styled.ContentSkeletonContainer>
        {[...Array(columnCount)]?.map((_, index) => {
          return (
            <styled.RowSkeletonContainer key={`row-skeleton--${index}`}>
              {[...Array(rowCount)]?.map((_, index) => {
                return (
                  <styled.CellSkeletonContainer key={`cell-skeleton--${index}`}>
                    <Skeleton height={20} />
                  </styled.CellSkeletonContainer>
                );
              })}
            </styled.RowSkeletonContainer>
          );
        })}
      </styled.ContentSkeletonContainer>
    </styled.TableSkeletonContainer>
  );
};

export default CustomSkeletonTable;
