import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./ActionPageStyled";

// LIBRARIES

// MISC
import { data } from "mocks/dndData";

// COMPONENTS
import CustomDragNDrop from "components/CustomDragNDrop/CustomDragNDrop";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomButton from "components/Atoms/CustomButton/CustomButton";

// CONFIGURATION

const ActionPage = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const [columns, setColumns] = useState(data);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    name: "",
    desc: "",
    folder: "",
  });

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleAddItem = () => {
    setModalIsOpen(false);
  };
  const handleUpdateList = (newData: any) => {
    setColumns(newData);
  };

  // - MODAL -
  const handleDisplayModal = (event: any) => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);

    setModalDetails({
      name: "",
      desc: "",
      folder: "",
    });
  };
  const handleModalChange = (name: string, value: string) => {
    setModalDetails((prevLoginDetails) => ({
      ...prevLoginDetails,
      [name]: value,
    }));
  };

  return (
    <styled.ActionPageContainer>
      <CustomDragNDrop
        dndData={columns}
        handlePassData={handleUpdateList}
        handleDisplayModal={handleDisplayModal}
      />
      <CustomModal handleClose={handleModalClose} open={modalIsOpen}>
        <styled.ModalContainer>
          <styled.InputSectionContainer>
            <CustomInput
              name="name"
              onChange={(event) =>
                handleModalChange(event.target.name, event.target.value)
              }
              value={modalDetails.name}
              placeholder="Nume"
            />
            <styled.CustomTextarea
              name="desc"
              onChange={(event) =>
                handleModalChange(event.target.name, event.target.value)
              }
              value={modalDetails.desc}
              placeholder="Descriere"
              cols={40}
              rows={5}
            />
          </styled.InputSectionContainer>
          <styled.ActionSectionContainer>
            {/* <CustomAutocompleteMultipleSelection
              parseData={filesList}
              label="Alege dosar"
              value={modalDetails.folder}
              onChange={handleModalChange}
              placeholder=""
            /> */}
            <styled.AddItemButtonContainer>
              <CustomButton text="Adauga actiune" handleClick={handleAddItem} />
            </styled.AddItemButtonContainer>
          </styled.ActionSectionContainer>
        </styled.ModalContainer>
      </CustomModal>
    </styled.ActionPageContainer>
  );
};

export default ActionPage;
