// ASSETS

// STYLES
import * as styled from "./CustomFilterButtonStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomFilterButtonModel";
import CustomText from "components/Atoms/CustomText/CustomText";

// COMPONENTS

// CONFIGURATION

const CustomFilterButton = (props: IProps) => {
  // PROPERTIES
  const { onButtonPress, filterData } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CustomFilterButtonWrapper onClick={() => onButtonPress(filterData)}>
      <styled.Text>
        <CustomText type={"H6"} text={filterData.name} />
      </styled.Text>
      <styled.TableHeadCellArrowsContainer>
        <styled.TableStatusCellTopArrow
          isHighlighted={filterData.sortOrder === "asc"}
        />
        <styled.TableStatusCellBottomArrow
          isHighlighted={filterData.sortOrder === "desc"}
        />
      </styled.TableHeadCellArrowsContainer>
    </styled.CustomFilterButtonWrapper>
  );
};

export default CustomFilterButton;
