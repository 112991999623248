// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { COLORS, FONT_SIZE, FONT_WEIGHT } from "constants/variables";

export const CustomAutocompleteList = styled.li``;
export const CustomAutocompleteListContainer = styled.div``;
export const CustomAutocompleteListLabel = styled.span<{
  isHighlighted: boolean;
}>(
  ({ isHighlighted }) => css`
    font-size: ${FONT_SIZE.max16};
    font-weight: ${isHighlighted ? FONT_WEIGHT.semiBold : FONT_WEIGHT.regular};
    color: ${COLORS.appBlack};
  `
);
