import React, { useState } from "react";

// ASSETS
import { ReactComponent as Logo } from "assets/icons/Logo.svg";

// STYLES
import * as styled from "./ForgotPasswordStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "api/sessionApi";

// MISC

// COMPONENTS
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomModal from "components/Atoms/CustomModal/CustomModal";

// CONFIGURATION

const ForgotPasswordPage = () => {
  // PROPERTIES

  // API REQUESTS
  const [forgotPasswordRequest, { isLoading: isForgotPasswordLoading }] =
    useForgotPasswordMutation();

  // LIBRARY CONSTANTS
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [loginDetails, setLoginDetails] = useState({
    email: "",
  });
  const [openModal, setOpenModal] = React.useState(false);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginDetails((prevLoginDetails) => ({
      ...prevLoginDetails,
      [name]: value,
    }));
  };

  const handleSend = async () => {
    if (isForgotPasswordLoading) return;

    await forgotPasswordRequest({ email: loginDetails.email }).then(() => {
      setOpenModal(true);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  return (
    <styled.LoginPageContainer>
      <styled.LoginContentContainer>
        <styled.InfoWrapper>
          <styled.HeadWrapper>
            <styled.Title>
              <CustomText type={"H1"} text={"Ai uitat parola?"} />
            </styled.Title>

            <styled.Subtitle>
              <CustomText type={"H4"} text={"Introdu adresa ta de email"} />
            </styled.Subtitle>
          </styled.HeadWrapper>
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="primary"
              placeholder="Email"
              name="email"
              value={loginDetails.email}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </styled.InputCustomWrapper>

          <styled.CustomButtonWrapper>
            <CustomButton
              appearance="primary"
              text="Trimite"
              handleClick={handleSend}
            />
          </styled.CustomButtonWrapper>
        </styled.InfoWrapper>
      </styled.LoginContentContainer>
      <styled.LogoContentContainer>
        <styled.LogoContainer>
          <styled.LogoWrapper>
            <Logo />
          </styled.LogoWrapper>
        </styled.LogoContainer>
      </styled.LogoContentContainer>

      <CustomModal handleClose={() => setOpenModal(false)} open={openModal}>
        <styled.ModalWrapper>
          <styled.TitleContainer>
            <CustomText type={"H2"} text={"Îți mulțumim!"} />
          </styled.TitleContainer>
          <styled.ModalContent>
            <styled.Subtitle>
              <CustomText
                type={"H5"}
                text={
                  "Un email a fost trimis pe adresa ta de email. Urmează instrucțiunile primite pentru a putea seta o nouă parolă!"
                }
              />
            </styled.Subtitle>
          </styled.ModalContent>

          <styled.ButtonsWrapper>
            <CustomButton
              appearance="smallestBlue"
              text={"Am înțeles"}
              handleClick={() => {
                setOpenModal(false);
                navigate(`/login`);
              }}
            />
          </styled.ButtonsWrapper>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.LoginPageContainer>
  );
};

export default ForgotPasswordPage;
