interface ITemplateInitialState {
  isLoading: boolean;
  rightSideMenuData: {
    title: string;
    statusData: {
      text: string;
      completed: number;
      total: number;
    }[];
  };
  templateData: any;
  newDocumentData: any;
}

export const initialSection = [
  // {
  //   id: -1,
  //   title: "Date beneficiar",
  //   questions: [
  //     {
  //       id: -100,
  //       step: 0,
  //       type: "ONLY_INPUT",
  //       name: ["CNP"],
  //       question: "Adauga CNP beneficiar",
  //       options: [],
  //       selectedOptions: [],
  //     },
  //     {
  //       id: -99,
  //       step: 1,
  //       type: "ONLY_INPUT",
  //       name: ["firstName", "lastName"],
  //       question: "Nume si prenume beneficiar",
  //       options: [],
  //       selectedOptions: [],
  //     },
  //   ],
  // },
];

export const initialState: ITemplateInitialState = {
  isLoading: false,
  rightSideMenuData: {
    title: "Status",
    statusData: [
      {
        text: "Aspecte privind nevoia de asistență socială:",
        completed: 4,
        total: 4,
      },
      {
        text: "Aspecte privind starea de sănătate:",
        completed: 7,
        total: 32,
      },
      {
        text: "Aspecte privind funcționalitatea:",
        completed: 4,
        total: 12,
      },
      {
        text: "Aspecte privind situația locativă ",
        completed: 0,
        total: 5,
      },
    ],
  },
  newDocumentData: {
    displayMode: "viewMode", // createMode, editMode, viewMode
    hasUnsavedChanges: false,
    completionDuration: 0,
    formBeneficiaryId: 0,
    currentBeneficiaryData: {},
    currentStep: 0,
    currentFlow: "cnpFlow", // "cnpFlow", "legalPersonFlow", "modelFlow"
    title: "",
    sections: initialSection,
  },

  templateData: {
    displayMode: "viewMode", // createMode, editMode, viewMode
    hasUnsavedChanges: false,
    canBeEdited: true,
    currentSectionId: "",
    currentQuestionId: "",
    templateType: "",
    templateTypeId: "",
    title: "",
    sections: [
      // {
      //   title: "",
      //   sectionId: "",
      //   questions: [
      //     {
      //       answers: [],
      //       textAnswer: "",
      //       question: "",
      //       questionId: "",
      //       type: "ONLY_SHORT_ANSWER",
      //     },
      //   ],
      // },
    ],
  },
};
