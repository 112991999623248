export const ageGroupsMock = {
  title: "Grupuri de vârstă",
  hasDropdown: false,
  options: [
    { key: "1", value: "Lunar" },
    { key: "2", value: "Anual" },
  ],
  values: [
    {
      id: 0,
      leftLabel: "18 - 24",
      topBarValue: 2.2,
      bottomBarValue: 1.1,
      rightLabel: "3.3%",
    },
    {
      id: 1,
      leftLabel: "25 - 34",
      topBarValue: 16.2,
      bottomBarValue: 8.5,
      rightLabel: "24.7%",
    },
    {
      id: 2,
      leftLabel: "35 - 44",
      topBarValue: 22.2,
      bottomBarValue: 11.3,
      rightLabel: "33.5%",
    },
    {
      id: 3,
      leftLabel: "45 - 64",
      topBarValue: 12.2,
      bottomBarValue: 13.1,
      rightLabel: "25.3%",
    },
    {
      id: 4,
      leftLabel: "65+",
      topBarValue: 1.1,
      bottomBarValue: 2.1,
      rightLabel: "3.2%",
    },
  ],
  legend: {
    topBarLabel: "Bărbați",
    bottomBarLabel: "Femei",
  },
};

export const activitiesStatsMock = {
  title: "Activităţi",
  hasDropdown: true,
  options: [
    { key: "1", value: "Toate categoriile" },
    { key: "2", value: "Anual" },
  ],
  values: [
    {
      id: 0,
      leftLabel: "18 - 24",
      topBarValue: 32.2,
      bottomBarValue: 31.1,
      rightLabel: "63.3%",
    },
    {
      id: 1,
      leftLabel: "25 - 34",
      topBarValue: 16.2,
      bottomBarValue: 8.5,
      rightLabel: "24.7%",
    },
    {
      id: 2,
      leftLabel: "35 - 44",
      topBarValue: 22.2,
      bottomBarValue: 11.3,
      rightLabel: "33.5%",
    },
    {
      id: 3,
      leftLabel: "45 - 64",
      topBarValue: 32.2,
      bottomBarValue: 53.1,
      rightLabel: "85.3%",
    },
    {
      id: 4,
      leftLabel: "65+",
      topBarValue: 21.1,
      bottomBarValue: 42.1,
      rightLabel: "63.2%",
    },
  ],
  legend: {
    topBarLabel: "Bărbați",
    bottomBarLabel: "Femei",
  },
};

export const targetGroupsStatsMock = {
  title: "Grupuri ţintă",
  hasDropdown: true,
  options: [
    { key: "1", value: "Toate categoriile" },
    { key: "2", value: "Anual" },
  ],
  values: [
    {
      id: 0,
      leftLabel: "ADIZ",
      topBarValue: 8.2,
      bottomBarValue: 5.1,
      rightLabel: "13.3%",
    },
    {
      id: 1,
      leftLabel: "CDIZ",
      topBarValue: 16.2,
      bottomBarValue: 8.5,
      rightLabel: "24.7%",
    },
    {
      id: 2,
      leftLabel: "CDIF",
      topBarValue: 41.2,
      bottomBarValue: 22.3,
      rightLabel: "63.5%",
    },
    {
      id: 3,
      leftLabel: "ADIF",
      topBarValue: 22.2,
      bottomBarValue: 43.1,
      rightLabel: "65.3%",
    },
    {
      id: 4,
      leftLabel: "TRIF",
      topBarValue: 21.1,
      bottomBarValue: 22.1,
      rightLabel: "43.2%",
    },
  ],
  legend: {
    topBarLabel: "Bărbați",
    bottomBarLabel: "Femei",
  },
};

export const statisticsPageTableHeadValues = [
  {
    name: "Tip Actiune",
    sortField: "activity",
    sortOrder: "",
  },
  {
    name: "Responsabil Activitate",
    sortField: "assignee",
    sortOrder: "",
  },
  {
    name: "Număr Participanţi",
    sortField: "numberOfParticipants",
    sortOrder: "",
  },

  {
    name: "Data",
    sortField: "date",
    sortOrder: "",
  },
];

export const statisticsPageTableBodyValues = [
  {
    id: 1,
    actionType: "Grup suport - Adulţi",
    owner: "Barbu Cezara, Bertescu Andra",
    participantsNumber: "34",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 2,
    actionType: "Grup suport - Adulţi",
    owner: "Barbu Cezara, Bertescu Andra",
    participantsNumber: "55",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 3,
    actionType: "Grup suport - Adolescenţi",
    owner: "Finea Adela, Farcaş Bianca",
    participantsNumber: "14",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 4,
    actionType: "Grup suport - Adulţi",
    owner: "Barbu Cezara, Bertescu Andra",
    participantsNumber: "34",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 5,
    actionType: "Grup suport - Adolescenţi",
    owner: "Finea Adela, Farcaş Bianca",
    participantsNumber: "12",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 6,
    actionType: "Grup suport - Adulţi",
    owner: "Barbu Cezara, Bertescu Andra",
    participantsNumber: "62",
    createdAt: "17.09.2021",
    isActive: true,
  },
];
