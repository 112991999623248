import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomSelectWithCheckBoxStyled";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  COLORS,
} from "constants/variables";

// LIBRARIES
import { Checkbox, MenuItem } from "@mui/material";

// MISC
import { IProps } from "./CustomSelectWithCheckBoxModel";

// REDUX

// COMPONENTS

// CONFIGURATION

const CustomSelectWithCheckBox = (props: IProps) => {
  // PROPERTIES
  const {
    options = [
      {
        key: "",
        value: "",
      },
    ],
    value = [],
    placeholder = "",
    appearance = "primary",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.SelectComponent
      {...props}
      variant="standard"
      fullWidth
      appearance={appearance}
      disableUnderline
      displayEmpty
      multiple
      inputProps={{
        placeholder: placeholder,
      }}
      MenuProps={{
        MenuListProps: {
          style: {
            backgroundColor: COLORS.appWhite,
            fontSize: FONT_SIZE.max16,
            fontWeight: FONT_WEIGHT.regular,
            color: COLORS.appBlack,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: BORDER_RADIUS.cardRadius,
            borderBottomRightRadius: BORDER_RADIUS.cardRadius,
            boxShadow: "none",
          },
        },
        PaperProps: {
          elevation: 0,
        },
      }}
    >
      {options?.map((item, index) => {
        return (
          <MenuItem
            key={`component-developer-dropdown--${index}`}
            value={item?.key}
          >
            {/*@ts-ignore*/}
            <Checkbox checked={value.indexOf(item.key) > -1} />

            {item?.value}
          </MenuItem>
        );
      })}
    </styled.SelectComponent>
  );
};

export default CustomSelectWithCheckBox;
