export const COLORS = {
  appWhite: "#F5F5F5",
  appLightGrey: "#EBEBEB",
  appHover: "#dadada",
  appDarkGrey: "#B0B3BF",
  appBlack: "#14131B",
  appYellow: "#F2BF24",
  appGreen: "#0ABF77",
  appRed: "#DA2133",
  appBlue: "#214CD9",
};

export const APP_ROLES = {
  OPERATOR: "OPERATOR",
  CENTER_MANAGER: "CENTER_MANAGER",
  ZONE_MANAGER: "ZONE_MANAGER",
  ADMIN: "ADMIN",
};

export const USER_LOGS_TYPES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  ACTIVATE: "ACTIVATE",
  INACTIVATE: "INACTIVATE",
  UPLOAD: "UPLOAD",
  DOWNLOAD: "DOWNLOAD",
  COMPLETE_DOCUMENT: "COMPLETE_DOCUMENT",
  CREATE_ACTIVITY: "CREATE_ACTIVITY",
  SHARE_BENEFICIARY: "SHARE_BENEFICIARY",
};

export const TAB_VALUE = {
  DOCUMENTS: "DOCUMENTS",
  ACTIVITIES: "ACTIVITIES",
};
export const QUESTION_TYPES = {
  NEW_SECTION: "NEW_SECTION", // Secţiune nouă
  ONLY_RADIO_BUTTON: "ONLY_RADIO_BUTTON", // Selecţie unică
  ONLY_CHECKBOX_BUTTONS: "ONLY_CHECKBOX_BUTTONS", // Selecţie multiplă
  ONLY_SHORT_ANSWER: "ONLY_SHORT_ANSWER", // Scurtă descriere (text)
  RADIO_BUTTON_AND_SHORT_ANSWER: "RADIO_BUTTON_AND_SHORT_ANSWER", // Selecţie unică şi scurtă descriere
  CHECKBOX_BUTTONS_AND_SHORT_ANSWER: "CHECKBOX_BUTTONS_AND_SHORT_ANSWER", // Selecţie multiplă şi scurtă descriere
  ONLY_LONG_ANSWER: "ONLY_LONG_ANSWER", // Descriere paragraf

  // Custom types
  ONLY_INPUT: "ONLY_INPUT",
};

export const DEVICE_TYPE = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
  NONE: "none",
};

export const DEVICE_SIZE = {
  MOBILE: "599px",
  TABLET_MIN: "600px",
  TABLET_MED: "1000px",
  TABLET_MAX: "1279px",
  DESKTOP: "1280px",
  NONE: "none",
};

export const FONT_FAMILY = {
  poppins: "Poppins",
};

export const FONT_WEIGHT = {
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
};

export const FONT_SIZE = {
  max12: "clamp(10px, 0.65vw, 12px)",
  max14: "clamp(12px, 0.75vw, 14px)",
  max16: "clamp(14px, 0.83vw, 16px)",
  max20: "clamp(15px, 1.14vw, 20px)",
  max26: "clamp(15px, 1.35vw, 26px)",
  max56: "clamp(30px, 2.6vw, 56px)",
};

export const SIZE = {
  max8: "clamp(6px, 0.45vw, 8px)",
  max16: "clamp(14px, 0.83vw, 16px)",
  max24: "clamp(16px, 1.25vw, 24px)",
  max32: "clamp(16px, 1.66vw, 32px)",
  max40: "clamp(30px, 2.1vw, 40px)",
  max55: "clamp(30px, 2.8vw, 55px)",
  max60: "clamp(30px, 3.1vw, 60px)",
  max65: "clamp(35px, 3.1vw, 65px)",
  max80: "clamp(35px, 4.16vw, 80px)",
  max104: "clamp(50px, 5.41vw, 104px)",
  max160: "clamp(100px, 8.3vw, 160px)",
  max200: "clamp(130px, 10vw, 200px)",
  max280: "clamp(170px, 15vw, 280px)",
  max360: "clamp(160px, 18vw, 360px)",
  max480: "clamp(250px, 25vw, 480px)",
  max550: "clamp(350px, 35vw, 550px)",
  max650: "clamp(400px, 40vw, 650px)",
  max900: "clamp(600px, 50vw, 900px)",
};

export const BORDER_RADIUS = {
  cardRadius: "8px",
};

export const SHADOW = {
  modalShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
};
