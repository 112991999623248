import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./ServicesPageStyled";

// LIBRARIES
import {
  useActivateInactivateActivityCategoryMutation,
  useActivateInactivateCActivityMutation,
  useCreateActivityCategoryMutation,
  useCreateActivityMutation,
  useGetAllActivitiesQuery,
  useUpdateActivityCategoryMutation,
  useUpdateActivityMutation,
} from "api/adminApi";
import { handlePagePaginationChange } from "slices/AdminSlice/adminSlice";
import { activitiesPaginationSelector } from "slices/AdminSlice/adminSliceSelectors";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";

// MISC
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { serviceHeadValues } from "mocks/adminMock";
import useTableHeadDetails from "hooks/useTableHeadHook";

// COMPONENTS
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomAccordionTable from "components/Atoms/CustomAccordionTable/CustomAccordionTable";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import useIsRequestLoading from "hooks/useRequestLoading";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";

// CONFIGURATION
const ServicesPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const activitiesPaginationDetails = useAppSelector(
    activitiesPaginationSelector
  );

  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);

  // STATE CONSTANTS
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(serviceHeadValues);

  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isActivityPressed, setIsActivityPressed] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    id: 0,
    name: "",
    activityCategoryId: 0,
  });
  const [activityType, setActivityType] = useState("service");

  // API REQUESTS
  const {
    data: activitiesList,
    isLoading: isAllActivitiesLoading,
    isFetching: isAllActivitiesFetching,
  } = useGetAllActivitiesQuery({
    page: activitiesPaginationDetails.pageNumber - 1,
    size: activitiesPaginationDetails.pageSize,
    searchQuery: headerSearchValueDetails,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const [createActivityRequest, { isLoading: isCreateActivitiesLoading }] =
    useCreateActivityMutation();
  const [
    createActivityActivityRequest,
    { isLoading: isCreateActivitiesCategoryLoading },
  ] = useCreateActivityCategoryMutation();
  const [updateActivityRequest, { isLoading: isUpdateActivitiesLoading }] =
    useUpdateActivityMutation();
  const [
    updateActivityCategoryRequest,
    { isLoading: iUpdateActivitiesCategoryLoading },
  ] = useUpdateActivityCategoryMutation();

  const [
    activateInactivateActivityCategoryRequest,
    { isLoading: isDeleteActivitiesCategoryLoading },
  ] = useActivateInactivateActivityCategoryMutation();
  const [
    activateInactivateActivityRequest,
    { isLoading: isDeleteActivitiesLoading },
  ] = useActivateInactivateCActivityMutation();

  const pageLoading =
    isAllActivitiesLoading ||
    isCreateActivitiesLoading ||
    isCreateActivitiesCategoryLoading ||
    isUpdateActivitiesLoading ||
    iUpdateActivitiesCategoryLoading ||
    isDeleteActivitiesCategoryLoading ||
    isDeleteActivitiesLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleAddService = async () => {
    setIsEditMode(false);
    setOpenModal(true);
    setIsActivityPressed(false);
    setActivityType("service");
    setModalDetails({
      id: 0,
      name: "",
      activityCategoryId: 0,
    });
  };

  const handleAddActivity = (row: any) => {
    setIsEditMode(false);
    setOpenModal(true);
    setActivityType("activity");
    setIsActivityPressed(true);
    setModalDetails({
      id: row["id"],
      name: "",
      activityCategoryId: 0,
    });
  };

  const handleCreateTask = async () => {
    if (isEditMode) {
      if (activityType === "service") {
        await updateActivityCategoryRequest({
          name: modalDetails["name"],
          id: modalDetails["activityCategoryId"],
        }).then(() => {
          setOpenModal(false);
        });
      } else if (activityType === "activity") {
        await updateActivityRequest({
          name: modalDetails["name"],
          id: modalDetails["id"],
          activityCategoryId: modalDetails["activityCategoryId"],
        }).then(() => {
          setOpenModal(false);
        });
      }
    } else {
      if (isActivityPressed) {
        await createActivityRequest({
          name: modalDetails["name"],
          activityCategoryId: modalDetails["id"],
        }).then(() => {
          setOpenModal(false);
        });
      } else {
        await createActivityActivityRequest({
          name: modalDetails["name"],
        }).then(() => {
          setOpenModal(false);
        });
      }
    }
  };
  const handleEditUser = (row: object, activityRow: object, type: string) => {
    setIsEditMode(true);
    setOpenModal(true);

    const localName = type === "service" ? row["name"] : activityRow["name"];

    setModalDetails({
      id: activityRow?.["id"],
      name: localName,
      activityCategoryId: row["id"],
    });
    setActivityType(type);
  };
  const handleDeleteUser = (row: object, activityRow: object, type: string) => {
    if (type === "service") {
      activateInactivateActivityCategoryRequest({
        activityCategoryId: row["id"],
        isActive: !row["isActive"],
      });
    } else {
      activateInactivateActivityRequest({
        activityId: activityRow["id"],
        isActive: !activityRow["isActive"],
      });
    }
  };

  const handleModalChange = (name: string, value: any) => {
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };
  const handleClose = () => {
    setIsEditMode(false);
    setOpenModal(false);
    setActivityType("service");
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    adminPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        adminPage,
      })
    );
  };

  return (
    <styled.ServicesPageContainer>
      <styled.ActivitiesPageHeader>
        <CustomText type="H3" text="Servicii si activitati" />
        <styled.AddUserButtonContainer>
          <CustomButton
            text={"Adauga categorie"}
            handleClick={handleAddService}
          />
        </styled.AddUserButtonContainer>
      </styled.ActivitiesPageHeader>
      <styled.ActivitiesPageTableContainer>
        <styled.ActivitiesPageTableWrapper>
          {isAllActivitiesFetching ? (
            <CustomSkeletonTable
              columnCount={tableHeadDetails.length}
              rowCount={10}
            />
          ) : (
            <CustomAccordionTable
              onEditPress={handleEditUser}
              onDeletePress={handleDeleteUser}
              onHeadCellPress={handleChangeSortOrder}
              hasAction={true}
              hasAccordion={true}
              onAddPress={handleAddActivity}
              headerData={tableHeadDetails}
              bodyData={activitiesList?.content}
              banList={["id", "subCategories", "activities", "isActive"]}
            />
          )}
        </styled.ActivitiesPageTableWrapper>

        <styled.ActivitiesPagePaginationContainer>
          <CustomPagination
            page={activitiesPaginationDetails?.pageNumber}
            count={Math.ceil(
              activitiesList?.["totalElements"] /
                activitiesPaginationDetails?.pageSize
            )}
            onChange={(event, value) =>
              handlePageChange(event, value, "activitiesPagination")
            }
          />
        </styled.ActivitiesPagePaginationContainer>
      </styled.ActivitiesPageTableContainer>
      <CustomModal handleClose={handleClose} open={openModal}>
        <styled.ModalWrapper>
          <styled.TitleContainer>
            <CustomText
              type="H6"
              text={
                activityType === "activity"
                  ? "Detalii activitate"
                  : "Detalii categorie"
              }
            />
          </styled.TitleContainer>

          <styled.ModalContent>
            <styled.InputCustomWrapper>
              <CustomInput
                appearance="secondary"
                placeholder={"Denumire"}
                name={"name"}
                value={modalDetails.name}
                onChange={(event) =>
                  handleModalChange(
                    (event.target as HTMLInputElement).name,
                    (event.target as HTMLInputElement).value
                  )
                }
              />
            </styled.InputCustomWrapper>
          </styled.ModalContent>
          <styled.ButtonsContainer>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={handleClose}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance={
                  modalDetails.name === "" ? "smallestDisabled" : "smallestBlue"
                }
                text={
                  isEditMode
                    ? activityType === "activity"
                      ? "Modifica activitate"
                      : "Modifica categorie"
                    : activityType === "activity"
                    ? "Adauga activitate"
                    : "Adauga categorie"
                }
                handleClick={
                  modalDetails.name === "" ? () => {} : handleCreateTask
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsContainer>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.ServicesPageContainer>
  );
};

export default ServicesPage;
