// LIBRARIES
import { configureStore, createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// SLICES
import { apiSlice } from "api/apiSlice";
import sessionSlice from "slices/SessionSlice/sessionSlice";
import templateSlice from "slices/TemplateSlice/templateSlice";
import adminSlice from "slices/AdminSlice/adminSlice";
import tempSlice from "slices/TempSlice/tempSlice";
import clientSlice from "slices/ClientSlice/clientSlice";

export const revertAll = createAction("REVERT_ALL");

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "template", "admin", "client"], // will be persisted
  blacklist: [`${apiSlice.reducerPath}`, "temp"], // will NOT be persisted
};

const reducers = combineReducers({
  session: sessionSlice,
  template: templateSlice,
  admin: adminSlice,
  temp: tempSlice,
  client: clientSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  // devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
