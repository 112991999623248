import { useState } from "react";

const useTableHeadDetails = (initialHeadValues) => {
  const [tableHeadDetails, setTableHeadDetails] = useState(initialHeadValues);
  const [tableHeadCell, setTableHeadCell] = useState({
    name: "",
    sortField: "",
    sortOrder: "",
  });

  const handleChangeSortOrder = (row) => {
    let updatedDetails = tableHeadDetails.map((detail) => {
      if (detail.sortField === row["sortField"]) {
        if (detail.sortOrder === "asc") {
          return { ...detail, sortOrder: "desc" };
        } else {
          return { ...detail, sortOrder: "asc" };
        }
      } else {
        return { ...detail, sortOrder: "" };
      }
    });
    setTableHeadCell(row);
    setTableHeadDetails(updatedDetails);
  };

  return [tableHeadDetails, tableHeadCell, handleChangeSortOrder];
};

export default useTableHeadDetails;
