import { apiSlice } from "../api/apiSlice";

export const sessionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: "auth/login",
        method: "POST",
        body: { username, password },
      }),
      invalidatesTags: [],
    }),

    forgotPassword: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: `user/forgot-password?email=${email}`,
        method: "POST",
      }),
      invalidatesTags: [],
    }),
    createPassword: builder.mutation<any, { userId: number; password: string }>(
      {
        query: ({ userId, password }) => ({
          url: "user/create-password",
          method: "POST",
          body: { userId, password },
        }),
        invalidatesTags: [],
      }
    ),

    changePassword: builder.mutation<
      any,
      { currentPassword: string; newPassword: string }
    >({
      query: ({ currentPassword, newPassword }) => ({
        url: "user/change-password",
        method: "POST",
        body: { currentPassword, newPassword },
      }),
      invalidatesTags: [],
    }),

    getUser: builder.query<any, { id: string | number }>({
      query: ({ id }) => `user/get-user-by-id?userId=${id}`,
      providesTags: [],
      keepUnusedDataFor: 4,
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreatePasswordMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
} = sessionApi;
