import React from "react";

// STYLES
import * as styled from "./CustomTableStyled";

// LIBRARIES
import moment from "moment";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";

// MISC
import { IProps } from "./CustomTableModel";
import { APP_ROLES, COLORS } from "constants/variables";
import {
  TableCrossIcon,
  TableDeleteIcon,
  TableEditIcon,
} from "./CustomTableStyled";
import { translateAppRoles } from "helpers/globalFunc";
import { useDecodedJwt } from "hooks/useDecodedJwt";
import { ReactComponent as MoveToIcon } from "assets/icons/MoveToIcon.svg";

// REDUX

// COMPONENTS

const CustomTable = (props: IProps) => {
  // PROPS
  const {
    hasAction = false,
    onEditPress = () => {},
    onDeletePress = () => {},
    onMovePress = () => {},
    onHeadCellPress = () => {},
    onRowClick = () => {},
    headerData = [],
    bodyData = [],
    banList = [],
  } = props;

  // CONSTANTS USING LIBRARIES
  const { role } = useDecodedJwt();

  // STATE CONSTANTS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  let formats = [moment.ISO_8601, "MM/DD/YYYY  :)  HH*mm*ss"];

  const displayStatusType = (value: string = "", row: any = "") => {
    let textColor =
      row?.isActive === undefined || row?.isActive
        ? COLORS.appBlack
        : COLORS.appDarkGrey;

    let statusText =
      value === "true" ? "Activ" : value === "false" ? "Inactiv" : value;

    const typeOfDateByPage =
      window.location.pathname.split("/")[1] === "operator-logs"
        ? moment(statusText).format("DD.MM.YYYY - HH:mm:ss")
        : moment(statusText).format("DD/MM/YYYY");

    const formatTime = moment(statusText, formats, true).isValid()
      ? typeOfDateByPage
      : statusText;

    if (value === "false") {
      textColor = COLORS.appRed;
    } else if (value === "true") {
      textColor = COLORS.appGreen;
    }

    return (
      <styled.TableStatusCellContainer>
        <styled.TableStatusCellText value={textColor}>
          {translateAppRoles(formatTime || "-")}
        </styled.TableStatusCellText>
      </styled.TableStatusCellContainer>
    );
  };

  return (
    <styled.TableContainer>
      <TableHead className="custom-table-head">
        <TableRow className="custom-table-head-row">
          {headerData.map((cell, index) => {
            return (
              <TableCell
                className="custom-table-head-cell"
                key={`custom-table-row-header-cell--${index}`}
                style={{ width: `calc(100% / ${headerData?.length})` }}
              >
                <styled.TableHeadCellContainer
                  onClick={() => onHeadCellPress(cell)}
                >
                  {cell.name}
                  <styled.TableHeadCellArrowsContainer>
                    <styled.TableStatusCellTopArrow
                      isHighlighted={cell.sortOrder === "asc"}
                    />
                    <styled.TableStatusCellBottomArrow
                      isHighlighted={cell.sortOrder === "desc"}
                    />
                  </styled.TableHeadCellArrowsContainer>
                </styled.TableHeadCellContainer>
              </TableCell>
            );
          })}
          {hasAction && (
            <TableCell className="custom-table-head-cell" align="right" />
          )}
        </TableRow>
      </TableHead>
      <TableBody className="custom-table-body">
        {bodyData?.map((row, index) => (
          <styled.TableRowComp key={`custom-table-row-body-cell--${index}`}>
            {Object.entries(row).map(([key, value]) => {
              return (
                !banList.includes(key) && (
                  <styled.TableCellComp
                    key={`custom-table-row-cell--${key}`}
                    onClick={() => onRowClick(row)}
                  >
                    {displayStatusType(value?.toString(), row)}
                  </styled.TableCellComp>
                )
              );
            })}
            {hasAction &&
              ([APP_ROLES.CENTER_MANAGER, APP_ROLES.ZONE_MANAGER].includes(
                role
              ) ? (
                <TableCell
                  className="custom-table-body-action-cell"
                  align="right"
                >
                  <styled.TableActionIcons>
                    <styled.TableActionEditIconContainer
                      onClick={() => onMovePress(row)}
                    >
                      <MoveToIcon />
                    </styled.TableActionEditIconContainer>
                  </styled.TableActionIcons>
                </TableCell>
              ) : (
                <TableCell
                  className="custom-table-body-action-cell"
                  align="right"
                >
                  <styled.TableActionIcons>
                    <styled.TableActionEditIconContainer
                      onClick={() => onEditPress(row)}
                    >
                      <TableEditIcon />
                    </styled.TableActionEditIconContainer>

                    {row["isActive"] ? (
                      <styled.TableActionDeleteIconContainer
                        onClick={() => onDeletePress(row)}
                      >
                        <TableDeleteIcon />
                      </styled.TableActionDeleteIconContainer>
                    ) : (
                      <styled.TableActionCrossIconContainer
                        onClick={() => onDeletePress(row)}
                      >
                        <TableCrossIcon />
                      </styled.TableActionCrossIconContainer>
                    )}
                  </styled.TableActionIcons>
                </TableCell>
              ))}
          </styled.TableRowComp>
        ))}
      </TableBody>
    </styled.TableContainer>
  );
};

export default CustomTable;
