import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./LegalPersonFlowStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// MISC
import { legalPersonFlowData } from "mocks/newDocumentData";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomRadioButton from "components/Atoms/CustomRadioButton/CustomRadioButton";
import CustomHeader from "components/CustomHeader/CustomHeader";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";

// CONFIGURATION

const LegalPersonFlow = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // STATE CONSTANTS
  const [currentStep, setCurrentStep] = useState("step1");
  const [isCNPValid, setIsCNPValid] = useState(false);
  const [formState, setFormState] = useState({});

  // LIFE CYCLE
  useEffect(() => {
    const cnpValidator =
      /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;

    if (cnpValidator.test(formState?.["step1"]?.["CNP"])) {
      setIsCNPValid(true);
    } else {
      setIsCNPValid(false);
    }
    // eslint-disable-next-line
  }, [formState?.["step1"]]);

  // EVENT HANDLERS
  const displayInputTypes = (item: any) => {
    switch (item.type) {
      case "customText":
        return <CustomText type="H3" text={item.value} />;
      case "customInput":
        return (
          <CustomInput
            appearance="secondary"
            placeholder={item?.placeholder}
            name={item?.name}
            onChange={(event) =>
              handleElemsChange(event.target.name, event.target.value)
            }
            value={formState?.[currentStep]?.[item.name]}
          />
        );
      case "customCheckbox":
        return (
          <CustomRadioButton
            options={item?.options}
            onClick={(event) => handleElemsChange(item.name, event)}
            selectedOption={formState?.[currentStep]?.[item.name]}
          />
        );
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCurrentStep = (step: string) => {
    let localCurrentStep = currentStep;

    let updatedStep: string = localCurrentStep.replace(
      /\d+/,
      (match: string): string => {
        return String(
          step === "next" ? parseInt(match) + 1 : parseInt(match) - 1
        );
      }
    );

    if (step === "back" && currentStep === "step1") {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          hasUnsavedChanges: true,
          currentStep: 0,
          currentFlow: "cnpFlow",
        })
      );
      setCurrentStep("step1");

      return;
    }

    if (step === "next" && currentStep === "step5") {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          hasUnsavedChanges: true,
          currentStep: 0,
          currentFlow: "modelFlow",
        })
      );
      setCurrentStep("step1");

      return;
    }

    setCurrentStep(updatedStep);
  };

  const handleElemsChange = (name: string, value: string | boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [currentStep]: {
        ...prevState[currentStep],
        [name]: value,
      },
    }));
  };

  const isCurrentStepEmpty = () => {
    const currentStepData = formState[currentStep];

    if (currentStep === "step1") {
      return isCNPValid;
    } else if (currentStepData && typeof currentStepData === "object") {
      return Object.values(currentStepData).some((value) => !!value);
    }

    return false;
  };

  return (
    <styled.NewDocumentPageContainer>
      <CustomHeader text={"Inapoi la Sablon"} handleGoBack={handleGoBack} />

      <styled.NewDocumentPageContentWrapper>
        <styled.SubtitleText>
          <CustomText type="H2" text={newDocumentSelector?.title} />
        </styled.SubtitleText>

        {Object.entries(legalPersonFlowData).map(([key, value]) => {
          return value.map((item, index) => {
            return (
              currentStep === key && (
                <styled.ComponentWrapper key={`component-wrapper--${index}`}>
                  {displayInputTypes(item)}
                </styled.ComponentWrapper>
              )
            );
          });
        })}
      </styled.NewDocumentPageContentWrapper>
      <styled.StepButtonContainer>
        <CustomIconButton
          handleClick={() => handleCurrentStep("back")}
          appearance="backButton"
        />

        <styled.NextButtonContainer>
          <CustomButton
            text="Mergi mai departe"
            handleClick={
              !isCurrentStepEmpty() ? () => {} : () => handleCurrentStep("next")
            }
            appearance={!isCurrentStepEmpty() ? "disabled" : "primary"}
          />
        </styled.NextButtonContainer>
      </styled.StepButtonContainer>
    </styled.NewDocumentPageContainer>
  );
};

export default LegalPersonFlow;
