// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { BORDER_RADIUS, SIZE } from "constants/variables";

export const DndColumnContainer = styled.div``;

export const DndCardContainer = styled.span<{ isDraggingOver: boolean }>(
  ({ isDraggingOver }) => css`
    background-color: ${isDraggingOver ? "lightBlue" : "transparent"};
    border-radius: ${BORDER_RADIUS.cardRadius};
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: ${SIZE.max24};

    min-width: 256px;
    width: 100%;
    max-width: 328px;
  `
);
