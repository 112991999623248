import React, { Fragment, useEffect, useState } from "react";
// ASSETS

// STYLES
import * as styled from "./ActivityModalStyled";

// LIBRARIES
import {
  useCreateBeneficiaryActivityMutation,
  useCreateMultipleBeneficiaryActivityMutation,
  useDeleteBeneficiaryActivityMutation,
  useEditBeneficiaryActivityMutation,
  useGetActivityByServiceIdQuery,
  useGetAllActivityCategoriesQuery,
  useGetCommunityCenterBeneficiariesByIdQuery,
} from "api/beneficiaryApi";
import moment from "moment";
import { useDecodedJwt } from "hooks/useDecodedJwt";

// MISC
import { IProps } from "./ActivityModalModel";
import { formatDateTime } from "helpers/globalFunc";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomTextArea from "components/Atoms/CustomTextArea/CustomTextArea";
import CustomDatePicker from "components/Atoms/CustomDatePicker/CustomDatePicker";
import CustomTimePicker from "components/Atoms/CustomTimePicker/CustomTimePicker";
import CustomAutocompleteMultipleSelection from "components/Atoms/CustomAutocompleteMultipleSelection/CustomAutocompleteMultipleSelection";

// CONFIGURATION

const ActivityModal = (props: IProps) => {
  // PROPERTIES
  const { modalOpen, closeModal, beneficiaryId, activityDetails, isEditMode } =
    props;

  // LIBRARY CONSTANTS
  const { communityCenterId } = useDecodedJwt();

  // STATE CONSTANTS
  const [formData, setFormData] = useState({
    activityCategoryId: 0,
    activityId: 0,
    assignee: "",
    beneficiaryId: beneficiaryId,
    description: "",
    endAt: "",
    startAt: "",
    dateValue: "",
    beneficiaryIds: [],
  });
  // API REQUESTS
  useEffect(() => {
    if (isEditMode) {
      setFormData({
        activityCategoryId: activityDetails?.activity?.category?.id,
        activityId: activityDetails?.activity?.id,
        assignee: activityDetails?.assignee,
        beneficiaryId: activityDetails?.beneficiary?.id,
        description: activityDetails?.description,
        dateValue: activityDetails?.startAt,
        endAt: activityDetails?.endtAt,
        startAt: activityDetails?.startAt,
        beneficiaryIds: [],
      });
    } else {
      setFormData({
        activityCategoryId: 0,
        activityId: 0,
        assignee: "",
        beneficiaryId: beneficiaryId,
        description: "",
        endAt: "",
        startAt: "",
        dateValue: "",
        beneficiaryIds: [],
      });
    }
    // eslint-disable-next-line
  }, [isEditMode]);

  const { data: activityCategoriesList } = useGetAllActivityCategoriesQuery();

  let localActivities = activityCategoriesList?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const { data: subCategoriesList } = useGetActivityByServiceIdQuery(
    {
      activityCategoryId: formData.activityCategoryId,
    },
    { skip: !formData.activityCategoryId }
  );

  let localSubcategories = subCategoriesList?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });

  const { data: communityCenterBeneficiaries } =
    useGetCommunityCenterBeneficiariesByIdQuery({
      communityCenterId: communityCenterId,
    });
  let localCommunityCenterBeneficiaries = communityCenterBeneficiaries?.map(
    (item) => {
      return {
        key: item.id,
        value: item.fullName,
      };
    }
  );

  const [createBeneficiaryActivityRequest] =
    useCreateBeneficiaryActivityMutation();
  const [createMultipleBeneficiaryActivityRequest] =
    useCreateMultipleBeneficiaryActivityMutation();

  const [editBeneficiaryActivityRequest] = useEditBeneficiaryActivityMutation();
  const [deleteBeneficiaryActivityRequest] =
    useDeleteBeneficiaryActivityMutation();

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleChange = (name: string, value: string) => {
    if (name === "endAt") {
      if (formData.startAt > value) return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProcessAddButton = async () => {
    const formattedEndDate = formatDateTime(
      moment(formData.dateValue).format("YYYY-MM-DD"),

      moment(formData.endAt).format("HH:mm")
    );
    const formattedStartDate = formatDateTime(
      moment(formData.dateValue).format("YYYY-MM-DD"),

      moment(formData.startAt).format("HH:mm")
    );

    if (formData?.beneficiaryIds?.length > 0) {
      const localBeneficiaryIds = [...formData.beneficiaryIds];
      if (!formData.beneficiaryIds.includes(beneficiaryId)) {
        localBeneficiaryIds.push(beneficiaryId);
      }

      await createMultipleBeneficiaryActivityRequest({
        activityId: formData.activityId,
        assignee: formData.assignee,
        beneficiaryIds: localBeneficiaryIds,
        description: formData.description,
        endAt: formattedEndDate,
        startAt: formattedStartDate,
      }).then(() => {
        setFormData({
          activityCategoryId: 0,
          activityId: 0,
          assignee: "",
          beneficiaryId: beneficiaryId,
          description: "",
          endAt: "",
          startAt: "",
          dateValue: "",
          beneficiaryIds: [],
        });
        closeModal();
      });
    } else {
      await createBeneficiaryActivityRequest({
        activityId: formData.activityId,
        assignee: formData.assignee,
        beneficiaryId: formData.beneficiaryId,
        description: formData.description,
        endtAt: formattedEndDate,
        startAt: formattedStartDate,
      }).then(() => {
        setFormData({
          activityCategoryId: 0,
          activityId: 0,
          assignee: "",
          beneficiaryId: beneficiaryId,
          description: "",
          endAt: "",
          startAt: "",
          dateValue: "",
          beneficiaryIds: [],
        });

        closeModal();
      });
    }
  };

  const handleProcessEditButton = async () => {
    const formattedEndDate = formatDateTime(
      moment(formData.dateValue).format("YYYY-MM-DD"),

      moment(formData.endAt).format("HH:mm")
    );
    const formattedStartDate = formatDateTime(
      moment(formData.dateValue).format("YYYY-MM-DD"),

      moment(formData.startAt).format("HH:mm")
    );

    await editBeneficiaryActivityRequest({
      activityId: formData.activityId,
      assignee: formData.assignee,
      beneficiaryId: formData.beneficiaryId,
      description: formData.description,
      endtAt: formattedEndDate,
      startAt: formattedStartDate,
      id: activityDetails?.id,
    }).then(() => {
      setFormData({
        activityCategoryId: 0,
        activityId: 0,
        assignee: "",
        beneficiaryId: beneficiaryId,
        description: "",
        endAt: "",
        startAt: "",
        dateValue: "",
        beneficiaryIds: [],
      });
      closeModal();
    });
  };

  const handleDeleteActivity = async () => {
    await deleteBeneficiaryActivityRequest({
      activityId: activityDetails?.id,
      isActive: false,
    }).then(() => {
      setFormData({
        activityCategoryId: 0,
        activityId: 0,
        assignee: "",
        beneficiaryId: beneficiaryId,
        description: "",
        endAt: "",
        startAt: "",
        dateValue: "",
        beneficiaryIds: [],
      });
      closeModal();
    });
  };

  const handleCloseModal = () => {
    setFormData({
      activityCategoryId: 0,
      activityId: 0,
      assignee: "",
      beneficiaryId: beneficiaryId,
      description: "",
      endAt: "",
      startAt: "",
      dateValue: "",
      beneficiaryIds: [],
    });
    closeModal();
  };

  const renderData = (element) => {
    switch (element.type) {
      case "input":
        return (
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="secondary"
              placeholder={element.placeholder}
              name={element.name}
              value={formData[element.name]}
              onChange={(event) =>
                handleChange(
                  (event.target as HTMLInputElement).name,
                  (event.target as HTMLInputElement).value
                )
              }
            />
          </styled.InputCustomWrapper>
        );
      case "dropdown":
        return (
          <styled.InputCustomWrapper>
            <CustomSelect
              options={element.options}
              value={formData[element.name]}
              placeholder={element.placeholder}
              onChange={(event) =>
                handleChange(element.name, event.target.value)
              }
            />
          </styled.InputCustomWrapper>
        );

      case "textArea":
        return (
          <styled.InputCustomWrapper>
            <CustomTextArea
              value={formData[element.name]}
              placeholder={element.placeholder}
              name={element.name}
              onChange={(event) =>
                handleChange(element.name, event.target.value)
              }
            />
          </styled.InputCustomWrapper>
        );

      case "picker":
        return (
          <styled.PickerWrapper>
            <CustomDatePicker
              name={element.dateValue}
              value={formData[element.dateValue]}
              handleChange={(event) => handleChange(element.dateValue, event)}
              placeholder="Alege data"
              disablePast={false}
            />
            <CustomTimePicker
              name={element.startAt}
              value={formData[element.startAt]}
              handleChange={(event) => handleChange(element.startAt, event)}
              placeholder="-- : --"
            />

            <CustomTimePicker
              name={element.endAt}
              value={formData[element.endAt]}
              handleChange={(event) => handleChange(element.endAt, event)}
              placeholder="-- : --"
            />
          </styled.PickerWrapper>
        );
      case "autocomplete":
        return (
          !isEditMode && (
            <styled.AutocompleteWrapper>
              <CustomAutocompleteMultipleSelection
                parseData={element?.options}
                // value={formData[element.name]}
                value={""}
                onChange={handleChange}
                name={element.name}
                placeholder={element.placeholder}
              />
            </styled.AutocompleteWrapper>
          )
        );
      default:
        return <></>;
    }
  };

  const dataToRender = [
    {
      type: "dropdown",
      placeholder: "Alege categorie",
      name: "activityCategoryId",
      select: true,
      options: localActivities,
    },
    {
      type: "dropdown",
      placeholder: "Activitate",
      name: "activityId",
      select: true,
      options: localSubcategories,
    },
    {
      type: "picker",
      dateValue: "dateValue",
      startAt: "startAt",
      endAt: "endAt",

      placeholder: "Descriere opțională",
    },
    {
      type: "input",
      name: "assignee",
      placeholder: "Moderator / formator / lector",
    },
    {
      type: "autocomplete",
      name: "beneficiaryIds",
      placeholder:
        formData?.["beneficiaryIds"].length > 0
          ? ""
          : "(Optional) Seleacteaza alti beneficiari",
      options: localCommunityCenterBeneficiaries,
    },

    {
      type: "textArea",
      name: "description",
      placeholder: "Descriere opțională",
    },
  ];

  return (
    <styled.ModalComponent open={modalOpen} onClose={handleCloseModal}>
      <styled.ModalWrapper>
        <styled.TitleContainer>
          <CustomText
            type="H6"
            text={isEditMode ? "Modifică activitate" : "Programează activitate"}
          />
        </styled.TitleContainer>
        <styled.ModalContent>
          {dataToRender.map((item, index) => {
            return (
              <Fragment key={`data-activity-modal--${index}`}>
                {renderData(item)}
              </Fragment>
            );
          })}
        </styled.ModalContent>
        <styled.ButtonsContainer>
          <styled.IndividualButtonWrapper>
            <CustomButton
              appearance="cancel"
              text="Anuleaza"
              handleClick={closeModal}
            />
          </styled.IndividualButtonWrapper>

          <styled.ModalBtnsContainer>
            {isEditMode && (
              <styled.DeleteButtonWrapper>
                <CustomButton
                  appearance="middleBlue"
                  text={"Sterge activitate"}
                  handleClick={handleDeleteActivity}
                />
              </styled.DeleteButtonWrapper>
            )}

            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="middleBlue"
                text={isEditMode ? "Modifică activitate" : "Adaugă activitate"}
                handleClick={
                  isEditMode ? handleProcessEditButton : handleProcessAddButton
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ModalBtnsContainer>
        </styled.ButtonsContainer>
      </styled.ModalWrapper>
    </styled.ModalComponent>
  );
};

export default ActivityModal;
