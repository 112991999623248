import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./OperatorsPageStyled";

// LIBRARIES
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { operatorsPaginationSelector } from "slices/AdminSlice/adminSliceSelectors";
import { handlePagePaginationChange } from "slices/AdminSlice/adminSlice";
import { useNavigate } from "react-router-dom";
import {
  useActivateInactivateUserMutation,
  useCreateUserMutation,
  useEditUserMutation,
  useGetAllCommunityCentersQuery,
  useGetAllCountiesQuery,
  useGetAllUsersPageableQuery,
  useGetAllUsersQuery,
} from "api/adminApi";
import { useTransferBeneficiaryMutation } from "api/beneficiaryApi";
import { renderOperatorData } from "helpers/globalFunc";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";
import useIsRequestLoading from "hooks/useRequestLoading";
import { handleChangeSnackBar } from "slices/SessionSlice/sessionSlice";
import { useDecodedJwt } from "hooks/useDecodedJwt";

// MISC
import { operatorsTableHeadValues } from "mocks/adminMock";
import { APP_ROLES } from "constants/variables";
import useTableHeadDetails from "hooks/useTableHeadHook";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomTable from "components/Atoms/CustomTable/CustomTable";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import CustomAutocomplete from "components/Atoms/CustomAutocomplete/CustomAutocomplete";
import CustomSkeletonTable from "skeletons/CustomSkeletonTable/CustomSkeletonTable";

// CONFIGURATION
const OperatorsPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorPaginationDetails = useAppSelector(operatorsPaginationSelector);
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);
  const { role } = useDecodedJwt();

  // STATE CONSTANTS
  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(operatorsTableHeadValues);

  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formState, setFormState] = React.useState({
    user: "",
    oldUserId: 0,
    userId: 0,
  });

  const [modalDetails, setModalDetails] = useState<{
    username: string;
    id?: number;
    userStatus?: string;
    role: string;
    fullName: string;
    countyId?: string;
    communityCenterIds?: number[];
  }>({
    fullName: "",
    username: "",
    role: "",
    countyId: "",
    communityCenterIds: [],
    id: 0,
    userStatus: "ACTIVE",
  });

  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  // API REQUESTS

  const {
    data: userListPageable,
    isLoading: isUserListPageableLoading,
    isFetching: isUserListFetching,
  } = useGetAllUsersPageableQuery({
    page: operatorPaginationDetails.pageNumber - 1,
    size: operatorPaginationDetails.pageSize,
    searchQuery: headerSearchValueDetails,
    sortField: tableHeadCell.sortField,
    sortOrder: tableHeadCell.sortOrder,
  });

  const {
    data: communityCentersList,
    isLoading: isCommunityCentersListLoading,
  } = useGetAllCommunityCentersQuery(null, { skip: role !== APP_ROLES.ADMIN });

  const { data: countiesList, isLoading: isCountiesListListLoading } =
    useGetAllCountiesQuery(null, { skip: role !== APP_ROLES.ADMIN });

  const { data: userList, isLoading: isUsersListLoading } = useGetAllUsersQuery(
    null,
    { skip: role === APP_ROLES.ADMIN }
  );

  const [
    createUserRequest,
    {
      isLoading: isCreateUserLoading,
      isError: isCreateUserError,
      error: createUserError,
    },
  ] = useCreateUserMutation();

  const [
    editUserRequest,
    {
      isLoading: isEditUserLoading,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();

  const [activateInactivateUserRequest, { isLoading: isDeleteUserLoading }] =
    useActivateInactivateUserMutation();
  const [transferBeneficiaryRequest, { isLoading: isTransferUserLoading }] =
    useTransferBeneficiaryMutation();

  const userListNames = userList?.map((user) => {
    return {
      key: user?.id,
      value: user?.fullName,
    };
  });

  const localCommunityCentersList = communityCentersList?.map((community) => {
    return {
      key: community?.id,
      value: community?.name,
    };
  });
  const localCountiesList = countiesList?.map((county) => {
    return {
      key: county?.id,
      value: county?.name,
    };
  });

  // const pageLoading =
  //   isUserListPageableLoading ||
  //   (isCommunityCentersListLoading && isCountiesListListLoading) ||
  //   isUsersListLoading ||
  //   isCreateUserLoading ||
  //   isEditUserLoading ||
  //   isDeleteUserLoading ||
  //   isTransferUserLoading;
  const pageLoading =
    isUserListPageableLoading ||
    isCommunityCentersListLoading ||
    isCountiesListListLoading ||
    isUsersListLoading ||
    isCreateUserLoading ||
    isEditUserLoading ||
    isDeleteUserLoading ||
    isTransferUserLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  useEffect(() => {
    if (isCreateUserError || isEditUserError) {
      const message = isCreateUserError
        ? createUserError?.["data"]?.details?.[0]
        : editUserError?.["data"]?.details?.[0];
      let translatedMsg;

      if (message?.includes("already exists")) {
        translatedMsg = "Un utilizator cu aceasta adresa de email exista deja!";
      } else {
        translatedMsg = "A apărut o eroare!";
      }

      dispatch(
        handleChangeSnackBar({
          snackBar: {
            open: true,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
          snackBarAlert: {
            message: translatedMsg,
            severity: "error",
            elevation: 6,
            variant: "filled",
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [isCreateUserError, isEditUserError]);

  useEffect(() => {
    if (!isEmailValid) {
      setTimeout(() => {
        setEmailError("");
      }, 3000);
    }
    // eslint-disable-next-line
  }, [isEmailValid]);

  // EVENT HANDLERS

  const handleRowPress = (row: object) => {
    if (role === APP_ROLES.ADMIN) {
      navigate("/operator-logs", { state: row });
    }
  };
  const handleAddUser = () => {
    setIsEditMode(false);
    setOpenModal(true);

    setModalDetails({
      fullName: "",
      username: "",
      role: "",
      countyId: "",
      communityCenterIds: [],
    });
  };
  const handleCreateUser = async () => {
    if (isEditMode) {
      const bodyOperatorAndZoneManager = {
        fullName: modalDetails["fullName"],
        username: modalDetails["username"],
        role: modalDetails["role"],
        communityCenterIds: modalDetails["communityCenterIds"],
        id: modalDetails["id"],
        userStatus: modalDetails["userStatus"],
      };
      const bodyCenterManager = {
        fullName: modalDetails["fullName"],
        username: modalDetails["username"],
        role: modalDetails["role"],
        countyId: modalDetails["countyId"],
        id: modalDetails["id"],
        userStatus: modalDetails["userStatus"],
        communityCenterIds: [],
      };

      await editUserRequest(
        modalDetails["role"] === APP_ROLES.ZONE_MANAGER
          ? bodyCenterManager
          : bodyOperatorAndZoneManager
      ).then(() => {
        setOpenModal(false);
      });
    } else {
      const bodyOperatorAndZoneManager = {
        fullName: modalDetails["fullName"],
        username: modalDetails["username"],
        role: modalDetails["role"],
        communityCenterIds: modalDetails["communityCenterIds"],
      };
      const bodyCenterManager = {
        fullName: modalDetails["fullName"],
        username: modalDetails["username"],
        role: modalDetails["role"],
        countyId: modalDetails["countyId"],
      };

      await createUserRequest(
        modalDetails["role"] === APP_ROLES.ZONE_MANAGER
          ? bodyCenterManager
          : bodyOperatorAndZoneManager
      ).then(() => {
        setOpenModal(false);
      });
    }
  };
  const handleEditUser = (row: object) => {
    setIsEditMode(true);
    setOpenModal(true);

    setModalDetails({
      fullName: row["fullName"],
      username: row["username"],
      role: row["role"],
      countyId: row["countyId"],
      communityCenterIds: row["communityCenterIds"],
      id: row["id"],
      userStatus: row["status"],
    });
  };
  const handleDeleteUser = async (row: object) => {
    await activateInactivateUserRequest({
      userId: row["id"],
      isActive: !row["isActive"],
    });
  };
  const handleClose = () => {
    setIsEditMode(false);
    setOpenModal(false);
    setEmailError("");
  };

  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    adminPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        adminPage,
      })
    );
  };
  const handleOpenTransferModal = (row: any) => {
    setFormState((prevState) => ({
      ...prevState,
      oldUserId: row?.id,
    }));
    setOpenModal(true);
  };

  const handleTransferBeneficiaryFolder = async () => {
    await transferBeneficiaryRequest({
      oldAssigneeId: formState?.oldUserId,
      newAssigneeId: formState?.userId,
    }).then(() => {
      setOpenModal(false);
      setFormState({
        user: "",
        oldUserId: 0,
        userId: 0,
      });
    });
  };

  const handleModalChange = (name: string, value: any) => {
    if (name === "username") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(value);
      setIsEmailValid(isValid);
      setEmailError(isValid ? "" : "Format invalid al adresei de email");
    }
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };

  const handleAutcompleteChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      ...value,
    }));
  };

  const formDataArr = [
    {
      type: "input",
      name: "fullName",
      placeholder: "Nume",
    },
    {
      type: "input",
      name: "username",
      placeholder: "Email",
    },
    {
      type: "dropdown",
      name: "role",
      placeholder: "Rol",
      options: [
        { key: APP_ROLES.OPERATOR, value: "Operator" },
        { key: APP_ROLES.CENTER_MANAGER, value: "Manager de centru" },
        { key: APP_ROLES.ZONE_MANAGER, value: "Manager județean" },
      ],
    },
    {
      type: "dropdownOperator",
      name: "communityCenterIds",
      placeholder: "Centru Comunitar",
      options: localCommunityCentersList,
    },

    {
      type: "dropdownCounty",
      name: "countyId",
      placeholder: "Judet",
      options: localCountiesList,
    },
    {
      type: "dropdownCenters",
      name: "communityCenterIds",
      placeholder: "Centru Comunitar",
      options: localCommunityCentersList,
    },
  ];

  return (
    <styled.OperatorsPageContainer>
      <styled.OperatorsPageHeader>
        <CustomText type="H3" text="Utilizatori" />
        {role === APP_ROLES.ADMIN && (
          <styled.AddUserButtonContainer>
            <CustomButton
              text="Adauga Utilizatori"
              handleClick={handleAddUser}
            />
          </styled.AddUserButtonContainer>
        )}
      </styled.OperatorsPageHeader>
      <styled.OperatorsPageTableContainer>
        <styled.OperatorsPageTableWrapper>
          {isUserListFetching ? (
            <CustomSkeletonTable
              columnCount={tableHeadDetails.length}
              rowCount={10}
            />
          ) : (
            <CustomTable
              onEditPress={handleEditUser}
              onDeletePress={handleDeleteUser}
              onRowClick={handleRowPress}
              onHeadCellPress={handleChangeSortOrder}
              hasAction={true}
              headerData={tableHeadDetails}
              bodyData={userListPageable?.content}
              banList={["id", "status", "communityCenterIds", "countyId"]}
              onMovePress={handleOpenTransferModal}
            />
          )}
        </styled.OperatorsPageTableWrapper>

        <styled.OperatorsPagePaginationContainer>
          <CustomPagination
            page={operatorPaginationDetails?.pageNumber}
            count={Math.ceil(
              userListPageable?.["totalElements"] /
                operatorPaginationDetails?.pageSize
            )}
            onChange={(event, value) =>
              handlePageChange(event, value, "operatorsPagination")
            }
          />
        </styled.OperatorsPagePaginationContainer>
      </styled.OperatorsPageTableContainer>

      <CustomModal handleClose={handleClose} open={openModal}>
        {role === APP_ROLES.ZONE_MANAGER ||
        role === APP_ROLES.CENTER_MANAGER ? (
          <styled.ModalWrapper>
            <styled.TitleContainer>
              <CustomText type="H6" text="Transferă dosare" />
            </styled.TitleContainer>

            <styled.ModalContent>
              <styled.InputCustomWrapper>
                <CustomAutocomplete
                  placeholder="Alege utilizator"
                  parseData={userListNames?.map((item) => ({
                    userId: item.key,
                    user: item.value,
                  }))}
                  label=""
                  name="operatorId"
                  value={formState.user}
                  onChange={handleAutcompleteChange}
                />
              </styled.InputCustomWrapper>
            </styled.ModalContent>
            <styled.ButtonsContainer>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="cancel"
                  text="Anuleaza"
                  handleClick={handleClose}
                />
              </styled.IndividualButtonWrapper>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="smallestBlue"
                  text="Transferă acum"
                  handleClick={handleTransferBeneficiaryFolder}
                />
              </styled.IndividualButtonWrapper>
            </styled.ButtonsContainer>
          </styled.ModalWrapper>
        ) : (
          <styled.ModalWrapper>
            <styled.TitleContainer>
              <CustomText type="H6" text="Detalii Operator" />
            </styled.TitleContainer>

            <styled.ModalContent>
              {formDataArr?.map((item, index) => (
                <styled.InputCustomWrapper
                  key={`modal-elem-list--${item.name}--${index}`}
                >
                  {renderOperatorData(item, modalDetails, handleModalChange)}
                  {item.name === "username" && !isEmailValid && (
                    <styled.ErrorMsgText>
                      <CustomText type="H8" text={emailError} />
                    </styled.ErrorMsgText>
                  )}
                </styled.InputCustomWrapper>
              ))}
            </styled.ModalContent>
            <styled.ButtonsContainer>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="cancel"
                  text="Anuleaza"
                  handleClick={handleClose}
                />
              </styled.IndividualButtonWrapper>
              <styled.IndividualButtonWrapper>
                <CustomButton
                  appearance="smallestBlue"
                  text={
                    isEditMode ? "Modifica Utilizator" : "Adauga Utilizator"
                  }
                  handleClick={handleCreateUser}
                />
              </styled.IndividualButtonWrapper>
            </styled.ButtonsContainer>
          </styled.ModalWrapper>
        )}
      </CustomModal>
    </styled.OperatorsPageContainer>
  );
};

export default OperatorsPage;
