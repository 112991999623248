// LIBRARIES
import styled from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

// MISC

export const BarChartComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 350px;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: ${SIZE.max16} ${SIZE.max8};
  border-radius: ${BORDER_RADIUS.cardRadius};
  background-color: ${COLORS.appWhite};
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  text-transform: capitalize;
  padding: ${SIZE.max16};
`;
export const SelectContainer = styled.div`
  width: 85px;

  div > div {
    padding: 4px 0px 4px 8px !important;
    margin: 0 !important;
    background-color: ${COLORS.appLightGrey} !important;

    &:focus {
      background-color: ${COLORS.appLightGrey} !important;
    }
  }
`;
