import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./CustomBarChartStyled";

// LIBRARIES
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useGetStatusStatisticsQuery } from "api/statisticsApi";

// MISC
import { COLORS } from "constants/variables";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";

// CONFIGURATION
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const CustomBarChart = () => {
  // PROPERTIES

  // API REQUESTS
  const [selectedOption, setSelectedOption] = useState("MONTHLY");

  const { data: chartDataList } = useGetStatusStatisticsQuery({
    type: selectedOption,
  });

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  const monthlyLabels = [
    "ian",
    "feb",
    "mar",
    "apr",
    "mai",
    "iun",
    "iul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  let yearlyLabels = chartDataList?.map((item) => String(item.monthOrYear));

  const labelsToRender =
    selectedOption === "MONTHLY" ? monthlyLabels : yearlyLabels;

  const dataToRenderForBeneficiary =
    selectedOption === "MONTHLY"
      ? Array.from({ length: 12 }, (_, index) => {
          const monthData = chartDataList?.find(
            (item) => item.monthOrYear === index + 1
          );
          return monthData ? monthData.beneficiaryCount : 0;
        })
      : chartDataList?.map((item) => item.beneficiaryCount);

  const dataToRenderForActivities =
    selectedOption === "MONTHLY"
      ? Array.from({ length: 12 }, (_, index) => {
          const monthData = chartDataList?.find(
            (item) => item.monthOrYear === index + 1
          );
          return monthData ? monthData.activityCount : 0;
        })
      : chartDataList?.map((item) => item.activityCount);

  const chartData = {
    labels: labelsToRender,

    datasets: [
      {
        label: "Beneficiari",
        data: dataToRenderForBeneficiary,
        backgroundColor: COLORS.appBlack,
        borderRadius: 15,
        borderWidth: 5,
        barThickness: 15,
        maxBarThickness: 20,
        borderColor: "transparent",
        categoryPercentage: 1.0,
        barPercentage: 0.5,
      },
      {
        label: "Activități",
        data: dataToRenderForActivities,
        backgroundColor: COLORS.appBlue,
        borderRadius: 15,
        borderWidth: 5,
        barThickness: 15,
        maxBarThickness: 20,
        borderColor: "transparent",
        categoryPercentage: 1.0,
        barPercentage: 0.5,
      },
    ],
  };

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <styled.BarChartComponentContainer>
      <styled.TitleContainer>
        <CustomText type="H3" text="Status" />
        <styled.SelectContainer>
          <CustomSelect
            options={[
              { key: "MONTHLY", value: "Lunar" },
              { key: "YEARLY", value: "Anual" },
            ]}
            value={selectedOption}
            onChange={handleSelectChange}
          />
        </styled.SelectContainer>
      </styled.TitleContainer>
      <Bar
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              align: "start",
              labels: {
                boxWidth: 10,
                boxHeight: 10,
                useBorderRadius: true,
                borderRadius: 5,
                color: COLORS.appDarkGrey,
                font: {
                  size: 14,
                },
              },
            },
          },
          scales: {
            y: {
              ticks: {
                color: COLORS.appDarkGrey,
                font: {
                  size: 14,
                },
                stepSize: 5,
              },
            },
            x: {
              ticks: {
                color: COLORS.appDarkGrey,
                font: {
                  size: 14,
                },
                stepSize: 5,
              },
            },
          },
        }}
        data={chartData}
        style={{}}
      />
    </styled.BarChartComponentContainer>
  );
};

export default CustomBarChart;
