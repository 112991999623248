import React from "react";

// ASSETS
import { ReactComponent as PersonIcon } from "assets/icons/PersonIcon.svg";
import { ReactComponent as RedCloseIcon } from "assets/icons/RedCloseIcon.svg";

// STYLES
import * as styled from "./BeneficiaryPageStyled";

// LIBRARIES
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useActivateInactivateClientBeneficiaryMutation,
  useGetBeneficiaryByIdQuery,
  useShareBeneficiaryMutation,
  useRemoveSharedOperatorForBeneficiaryMutation,
} from "api/beneficiaryApi";
import {
  useGetComunityCenterUsersByIdQuery,
  useGetUsersWithAccesToBeneficiaryByIdQuery,
} from "api/adminApi";
import moment from "moment/moment";

// MISC
import { tabListData } from "mocks/beneficiaryPageMocks";
import { TAB_VALUE } from "constants/variables";

// COMPONENTS
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import SideBeneficiaryStatus from "components/SideBeneficiaryStatus/SideBeneficiaryStatus";
import DocumentsTab from "view/BeneficiaryPage/components/DocumentsTab/DocumentsTab";
import ActivitiesTab from "view/BeneficiaryPage/components/ActivitiesTab/ActivitiesTab";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomTextArea from "components/Atoms/CustomTextArea/CustomTextArea";
import CustomAutocomplete from "components/Atoms/CustomAutocomplete/CustomAutocomplete";
import useIsRequestLoading from "hooks/useRequestLoading";

// CONFIGURATION
const BeneficiaryPage = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const { state } = useLocation();

  // STATE CONSTANTS
  const [formState, setFormState] = React.useState({
    user: "",
    userId: 0,
    inactivationReason: "",
  });
  const [value, setValue] = React.useState("DOCUMENTS");
  const [openModal, setOpenModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("share");

  // API REQUESTS
  const {
    data: beneficiaryData,
    isSuccess,
    isLoading: isGetBeneficiaryLoading,
  } = useGetBeneficiaryByIdQuery({
    beneficiaryId: state?.id,
  });

  const { data: usersWithAccesToBeneficiary, isLoading: isGetUserWithAccess } =
    useGetUsersWithAccesToBeneficiaryByIdQuery({
      beneficiaryId: state?.id,
    });

  const listOfOperatorsWithAccess = usersWithAccesToBeneficiary?.map((user) => {
    return {
      key: user?.id,
      value: user?.fullName,
    };
  });

  const {
    data: comunityCenterUsersData,
    isLoading: isGetCommunityCenterLoading,
  } = useGetComunityCenterUsersByIdQuery(
    {
      communityCenterId: beneficiaryData?.communityCenter?.id,
    },
    {
      skip: !beneficiaryData?.communityCenter?.id,
    }
  );

  const localComunityCenterUsers = comunityCenterUsersData?.map((user) => {
    return {
      key: user?.id,
      value: user?.fullName,
    };
  });

  const [
    activateInactivateBeneficiaryRequest,
    { isLoading: isDeleteBeneficiaryLoading },
  ] = useActivateInactivateClientBeneficiaryMutation();

  const [shareBeneficiaryRequest, { isLoading: isShareBeneficiaryLoading }] =
    useShareBeneficiaryMutation();
  const [removeBeneficiaryRequest, { isLoading: isRemoveBeneficiaryLoading }] =
    useRemoveSharedOperatorForBeneficiaryMutation();

  // const pageLoading =
  //   (isGetUserWithAccess &&
  //     isGetCommunityCenterLoading &&
  //     isGetBeneficiaryLoading) ||
  //   isDeleteBeneficiaryLoading ||
  //   isShareBeneficiaryLoading ||
  //   isRemoveBeneficiaryLoading;

  const pageLoading =
    isGetUserWithAccess ||
    isGetCommunityCenterLoading ||
    isGetBeneficiaryLoading ||
    isDeleteBeneficiaryLoading ||
    isShareBeneficiaryLoading ||
    isRemoveBeneficiaryLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleInactivateBeneficiary = async () => {
    await activateInactivateBeneficiaryRequest({
      beneficiaryId: beneficiaryData["id"],
      isActive: !beneficiaryData["isActive"],
      inactivationReason: formState.inactivationReason,
    }).then(() => {
      setOpenModal(false);

      setFormState({
        user: "",
        userId: 0,
        inactivationReason: "",
      });
    });
  };

  const handleOpenInactivateModal = () => {
    setModalType("inactivate");
    setOpenModal(true);
  };

  const handleShareBeneficiaryFolder = async () => {
    await shareBeneficiaryRequest({
      assigneeId: formState.userId,
      beneficiaryId: beneficiaryData?.id,
    }).then(() => {
      setOpenModal(false);

      setFormState({
        user: "",
        userId: 0,
        inactivationReason: "",
      });
    });
  };

  const handleOpenShareBeneficiaryFolderModal = () => {
    setModalType("share");
    setOpenModal(true);
  };

  const handleDeleteSharedUser = async (assigneeId: number) => {
    await removeBeneficiaryRequest({
      assigneeId: assigneeId,
      beneficiaryId: beneficiaryData?.id,
    }).then(() => {
      setFormState({
        user: "",
        userId: 0,
        inactivationReason: "",
      });
    });
  };

  const handleChange = (name, value) => {
    setFormState((formState) => ({
      ...formState,
      [name]: value,
    }));
  };

  const handleAutcompleteChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      ...value,
    }));
  };

  return (
    <styled.BeneficiaryPageContainer>
      <styled.ButtonsContainer>
        <CustomIconButton
          children={beneficiaryData?.fullName}
          handleClick={() => {
            navigate(-1);
          }}
          appearance="simpleArrowBackWithText"
        />
        <styled.RightHalfContainer>
          {beneficiaryData?.["isActive"] && (
            <CustomButton
              text={"Partajează"}
              handleClick={handleOpenShareBeneficiaryFolderModal}
              appearance={"smallestBlue"}
            />
          )}
          <CustomButton
            text={
              beneficiaryData?.["isActive"]
                ? "Inactivează beneficiar"
                : "Activează beneficiar"
            }
            handleClick={handleOpenInactivateModal}
            appearance={"black"}
          />
        </styled.RightHalfContainer>
      </styled.ButtonsContainer>
      {beneficiaryData?.["isActive"] ? (
        <styled.ContentWrapper>
          {isSuccess && (
            <styled.BeneficiaryStatusWrapper>
              <SideBeneficiaryStatus
                handleEdit={() => {}}
                beneficiaryData={beneficiaryData}
              />
            </styled.BeneficiaryStatusWrapper>
          )}
          <styled.RightPageContainer>
            <TabContext value={value}>
              <TabList
                onChange={handleTabChange}
                className="tab-list-mui"
                TabIndicatorProps={{
                  style: {
                    // width: 64,
                    height: 3,
                  },
                }}
              >
                {tabListData.map((tabData, index) => {
                  return (
                    <Tab
                      key={`tab-list-manage-file-page--${index}`}
                      className="tab-mui"
                      value={tabData.value}
                      icon={tabData.title}
                    />
                  );
                })}
              </TabList>

              {tabListData.map((tabData, index) => {
                return (
                  tabData.value === TAB_VALUE.DOCUMENTS &&
                  value === TAB_VALUE.DOCUMENTS && (
                    <TabPanel
                      value={tabData.value}
                      hidden={true}
                      className="tab-panel-mui"
                      key={`tab-panel-manage-file-page--${index}`}
                    >
                      <DocumentsTab beneficiaryData={beneficiaryData} />
                    </TabPanel>
                  )
                );
              })}

              {tabListData.map((tabData, index) => {
                return (
                  tabData.value === TAB_VALUE.ACTIVITIES &&
                  value === TAB_VALUE.ACTIVITIES && (
                    <TabPanel
                      value={tabData.value}
                      className="tab-panel-mui"
                      key={`tab-panel-manage-file-page--${index}`}
                    >
                      <ActivitiesTab
                        beneficiaryData={beneficiaryData}
                        beneficiaryDetails={state}
                      />
                    </TabPanel>
                  )
                );
              })}
            </TabContext>
          </styled.RightPageContainer>
        </styled.ContentWrapper>
      ) : (
        <styled.InactivityContentWrapper>
          <CustomText type="H3" text="Acest beneficiar este inactiv" />
          <CustomText
            type="H4"
            text={`DE CATRE : ${
              beneficiaryData?.["inactivatedBy"]?.["fullName"] || "-"
            }`}
          />
          <CustomText
            type="H4"
            text={`LA DATA : ${
              moment(beneficiaryData?.["inactivatedAt"]).format(
                "DD.MM.YYYY - HH:mm:ss"
              ) || "-"
            }`}
          />
          <CustomText
            type="H4"
            text={`MOTIVUL : ${beneficiaryData?.["inactivationReason"] || "-"}`}
          />
        </styled.InactivityContentWrapper>
      )}
      <CustomModal handleClose={() => setOpenModal(false)} open={openModal}>
        <styled.ModalWrapper>
          <styled.TitleContainer>
            <CustomText
              type="H6"
              text={
                modalType === "inactivate"
                  ? beneficiaryData?.["isActive"]
                    ? "Inactivează beneficiar"
                    : "Activează beneficiar"
                  : "Partajează dosar"
              }
            />
          </styled.TitleContainer>

          <styled.ModalContent>
            {modalType === "share" && (
              <styled.ShareModalContentContainer>
                <styled.ShareModalInputContainer>
                  <CustomAutocomplete
                    placeholder="Nume"
                    parseData={localComunityCenterUsers?.map((item) => ({
                      userId: item.key,
                      user: item.value,
                    }))}
                    label=""
                    name="user"
                    value={formState.user}
                    onChange={handleAutcompleteChange}
                  />
                </styled.ShareModalInputContainer>
                <styled.ShareModalUserListContainer>
                  <CustomText type="H7" text="Utilizatori cu acces" />
                  <styled.ShareModalUserListItemContainer>
                    {listOfOperatorsWithAccess?.map((user, index) => {
                      return (
                        <styled.ShareModalUserListItem
                          key={`share-modal-user-list-item--${index}`}
                        >
                          <styled.ShareModalUserNameContainer>
                            <PersonIcon />
                            {user?.value}
                          </styled.ShareModalUserNameContainer>
                          <RedCloseIcon
                            onClick={() => handleDeleteSharedUser(user.key)}
                          />
                        </styled.ShareModalUserListItem>
                      );
                    })}
                  </styled.ShareModalUserListItemContainer>
                </styled.ShareModalUserListContainer>
              </styled.ShareModalContentContainer>
            )}
            {modalType === "inactivate" && (
              <styled.InactivateModalContent>
                <CustomTextArea
                  name={"inactivationReason"}
                  onChange={(event) =>
                    handleChange("inactivationReason", event.target.value)
                  }
                  value={formState.inactivationReason}
                />
              </styled.InactivateModalContent>
            )}
          </styled.ModalContent>

          <styled.ButtonsWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={() => setOpenModal(false)}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="smallestBlue"
                text={
                  modalType !== "inactivate"
                    ? "Adauga Utilizator"
                    : beneficiaryData?.["isActive"]
                    ? "Inactivează beneficiar"
                    : "Activează beneficiar"
                }
                handleClick={
                  modalType === "inactivate"
                    ? handleInactivateBeneficiary
                    : handleShareBeneficiaryFolder
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsWrapper>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.BeneficiaryPageContainer>
  );
};

export default BeneficiaryPage;
