// LIBRARIES
import styled, { css } from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";
import { ReactComponent as EditIcon } from "assets/icons/tableEditIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/tableDeleteIcon.svg";

export const SideMenuStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
  width: 100%;
  overflow: hidden;
`;
export const SideMenuFormDataContainer = styled.div`
  height: 100%;
`;
export const SideMenuAddButtonContainer = styled.div``;
export const SideMenuStatusTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
`;
export const SideMenuStatusTitle = styled.span`
  color: ${COLORS.appBlue};
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.semiBold};
`;
export const SideMenuEditIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SideMenuTemplateSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max32};
  overflow: auto;
  max-height: calc(100vh - 300px);
  scroll-behavior: smooth;
`;

export const QuestionWrapper = styled.div<{ isSelected?: boolean }>(
  ({ isSelected }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    div > div > span {
      color: ${isSelected && COLORS.appBlue} !important;
    }
    &:hover > div > div > svg {
      display: block;
    }
  `
);

export const QuestionContainer = styled.div<{ isSelected?: boolean }>(
  ({ isSelected }) => css`
    padding: 0 ${SIZE.max24};
  `
);

export const QuestionTitleContainer = styled.div`
  cursor: pointer;
  padding: 7px 0px;
`;

export const SideMenuTemplateNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max32};
`;

export const SectionContainer = styled.div<{ isSelected?: boolean }>(
  ({ isSelected }) => css`
    display: flex;
    flex-direction: column;
  `
);
export const SectionWrapper = styled.div<{ isSelected?: boolean }>(
  ({ isSelected }) => css`
    display: flex;
    justify-content: space-between;

    align-items: center;
    div > div > span {
      color: ${isSelected && COLORS.appBlue} !important;
    }
    &:hover > div > svg {
      display: block;
    }
  `
);

export const QuestionButtonsContainer = styled.div`
  display: flex;
`;
export const EditIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: none;
  }
`;
export const DeleteIconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DeleteIconSvg = styled(DeleteIcon)`
  display: none;

  filter: brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(3274%)
    hue-rotate(329deg) brightness(78%) contrast(128%);
`;
export const SectionTitleContainer = styled.div`
  cursor: pointer;
  width: 80%;
  div > span {
    font-size: ${FONT_SIZE.max14};
    font-weight: ${FONT_WEIGHT.semiBold};
    text-transform: uppercase;
  }
`;

export const SideMenuEditIcon = styled(EditIcon)`
  filter: brightness(0) saturate(100%) invert(38%) sepia(100%) saturate(6775%)
    hue-rotate(228deg) brightness(89%) contrast(90%);
`;
export const SideMenuStatusListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SideMenuStatusCardWrapper = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: space-between;
`;
export const SideMenuStatusCardTextWrapper = styled.div`
  max-width: 180px;
  text-transform: uppercase;
`;
export const ProgressBarContainer = styled.div`
  position: relative;
`;
export const CircularProgressComp = styled(CircularProgress)``;
export const ProgressBarWrapper = styled.div`
  top: -5px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProgressBarText = styled.span`
  font-size: ${FONT_SIZE.max12};
  color: ${COLORS.appDarkGrey};
`;

// MODAL

export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;

export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
`;

export const InputCustomWrapper = styled.div`
  margin-bottom: ${SIZE.max16};
`;
export const CheckboxListContainer = styled.div``;
export const BoundedLineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: ${SIZE.max16};
`;
export const BoundedText = styled.span`
  color: ${COLORS.appDarkGrey};
  font-size: ${FONT_SIZE.max12};
  white-space: nowrap;
`;
export const BoundedLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.appDarkGrey};
`;

export const ModalTitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  /* height: ${SIZE.max65}; */
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const IndividualButtonWrapper = styled.div``;
