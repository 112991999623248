import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomTextStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomTextModel";

// REDUX

// COMPONENTS

// CONFIGURATION
const CustomText = (props: IProps) => {
  // PROPERTIES
  const { type = "H1", text = "" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CustomTextContainer>
      {text && (
        <styled.CustomTextLabel type={type}>{text}</styled.CustomTextLabel>
      )}
    </styled.CustomTextContainer>
  );
};
export default CustomText;
