// LIBRARIES
import styled from "styled-components";
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

// MISC

export const TemplatePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  // &::-webkit-scrollbar {
  //   width: 6px;
  //   border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  //   border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  // }
  //
  // &::-webkit-scrollbar-thumb {
  //   background-color: ${COLORS.appDarkGrey};
  //   border-radius: ${BORDER_RADIUS.cardRadius};
  // }
  //
  // &:hover {
  //   &::-webkit-scrollbar-thumb {
  //     background-color: ${COLORS.appBlue};
  //   }
  // }
`;
export const PageContentContainer = styled.div`
  padding: 0px ${SIZE.max80};
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};
`;
export const TitleContainer = styled.div`
  // width: ${SIZE.max160};
`;
export const TemplatesCardContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  margin-top: ${SIZE.max24};
  flex-wrap: wrap;
  padding-bottom: ${SIZE.max24};

  max-height: 70vh;
  overflow-y: auto;
`;
export const TemplateCard = styled.div``;

export const RightPageContainer = styled.div`
  width: 100%;
  height: 100%;

  .tab-list-mui {
    background-color: ${COLORS.appWhite};
    border-radius: ${BORDER_RADIUS.cardRadius};
    width: 100%;
    .MuiTabs-flexContainer {
      display: flex;
      flex-wrap: wrap;

      .Mui-selected {
        color: ${COLORS.appBlack};
      }
    }
  }
  .tab-mui {
    color: ${COLORS.appDarkGrey};
    font-weight: ${FONT_WEIGHT.semiBold};
    font-size: ${FONT_SIZE.max16};
    text-transform: capitalize;
  }
  .tab-panel-mui {
    padding: 0;
    margin: 0;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  gap: ${SIZE.max40};

  @media (max-width: 575px) {
    flex-direction: column;
  }
`;
export const ButtonContainer = styled.div`
  button {
    height: 45px;
  }
`;
