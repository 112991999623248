import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomSelectStyled";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  COLORS,
  FONT_FAMILY,
} from "constants/variables";

// LIBRARIES
import { MenuItem } from "@mui/material";

// MISC
import { IProps } from "./CustomSelectModel";

// REDUX

// COMPONENTS

// CONFIGURATION

const CustomSelect = (props: IProps) => {
  // PROPERTIES
  const {
    options = [
      {
        key: "",
        value: "",
      },
    ],
    value = "",
    placeholder = "",
    appearance = "primary",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.SelectComponent
      {...props}
      variant="standard"
      fullWidth
      appearance={appearance}
      disableUnderline
      displayEmpty
      renderValue={
        value && value !== ""
          ? undefined
          : () => (
              <span
                style={{
                  fontSize: FONT_SIZE.max16,
                  fontWeight: FONT_WEIGHT.regular,
                  color: COLORS.appDarkGrey,
                  fontFamily: FONT_FAMILY.poppins,
                }}
              >
                {placeholder}
              </span>
            )
      }
      MenuProps={{
        MenuListProps: {
          style: {
            backgroundColor: COLORS.appWhite,
            fontSize: FONT_SIZE.max16,
            fontWeight: FONT_WEIGHT.regular,
            color: COLORS.appBlack,
            fontFamily: FONT_FAMILY.poppins,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: BORDER_RADIUS.cardRadius,
            borderBottomRightRadius: BORDER_RADIUS.cardRadius,
            boxShadow: "none",
            maxHeight: "200px",
            overflow: "auto",
          },
        },
        PaperProps: {
          elevation: 0,
        },
      }}
    >
      {options?.map((item, index) => {
        return (
          <MenuItem
            key={`component-developer-dropdown--${index}`}
            value={item?.key}
            style={{ backgroundColor: COLORS.appWhite }}
          >
            {item?.value}
          </MenuItem>
        );
      })}
    </styled.SelectComponent>
  );
};

export default CustomSelect;
