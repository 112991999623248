import React from "react";

// ASSETS
import { ReactComponent as ArrowLeft } from "assets/icons/ArrowLeft.svg";
import { ReactComponent as CircleArrowLeft } from "assets/icons/CircleArrowLeft.svg";
import { ReactComponent as SimpleBackArrow } from "assets/icons/SimpleBackArrow.svg";

// STYLES
import * as styled from "./CustomIconButtonStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomIconButtonModel";

// COMPONENTS

// CONFIGURATION

const CustomIconButton = (props: IProps) => {
  // PROPERTIES
  const {
    children,
    isSelected = false,
    handleClick,
    appearance = "primary",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CustomIconButtonComponent
      onClick={handleClick}
      appearance={appearance}
    >
      <styled.CustomIconButtonWrapper
        isSelected={isSelected}
        appearance={appearance}
      >
        {appearance === "backButton" && <ArrowLeft />}
        {appearance === "headerBackButton" && <CircleArrowLeft />}
        {appearance === "simpleArrowBackWithText" && <SimpleBackArrow />}
        {children}
      </styled.CustomIconButtonWrapper>
    </styled.CustomIconButtonComponent>
  );
};

export default CustomIconButton;
