// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "./CustomCheckedBoxModel";
import {
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  BORDER_RADIUS,
  FONT_FAMILY,
} from "constants/variables";

export const CheckBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
export const LabelWrapper = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 3px solid ${COLORS.appBlue};
  border-radius: ${BORDER_RADIUS.cardRadius};
  background-color: ${COLORS.appWhite};
  &:checked {
    background-color: ${COLORS.appBlue};
    color: ${COLORS.appWhite};
    position: relative;
  }
  &:checked::before {
    content: "✔";
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${FONT_SIZE.max16};
    font-weight: ${FONT_WEIGHT.semiBold};
    color: ${COLORS.appWhite};
  }
`;
export const Text = styled.span<IStyled>(
  ({ appearance, checked }) => css`
    margin-left: 8px;
    font-family: ${FONT_FAMILY.poppins};
    font-size: ${FONT_SIZE.max16};
    font-weight: ${FONT_WEIGHT.semiBold};
    color: ${appearance === "simple"
      ? COLORS.appBlack
      : checked === true
      ? COLORS.appBlack
      : COLORS.appDarkGrey};
  `
);
