// LIBRARIES
import styled from "styled-components";
import { Pagination } from "@mui/material";

// MISC
import {
  COLORS,
  FONT_SIZE,
  FONT_FAMILY,
  FONT_WEIGHT,
} from "constants/variables";

export const CustomPaginationContainer = styled.div``;

export const CustomPaginationComp = styled(Pagination).attrs((props) => ({
  size: "small",
}))`
  button {
    background-color: transparent;
    font-size: ${FONT_SIZE.max16};
  }
  .MuiSvgIcon-root {
    width: 26px;
    height: 26px;
    fill: ${COLORS.appBlue};
  }
  .MuiButtonBase-root {
    background-color: transparent;
    font-family: ${FONT_FAMILY.poppins};
    font-size: ${FONT_SIZE.max14};
    font-weight: ${FONT_WEIGHT.semiBold};
    &.Mui-selected {
      &:hover {
        background-color: ${COLORS.appBlue};
        color: ${COLORS.appWhite};
      }
      background-color: ${COLORS.appBlue};
      color: ${COLORS.appWhite};
    }
    &:hover {
      background-color: transparent;
    }
  }
  .MuiPaginationItem-root {
    font-size: ${FONT_SIZE.max16};
  }

  .MuiPagination-text {
  }
`;
