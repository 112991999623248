import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./AttachmentFileStyled";
import { ReactComponent as AddFileIcon } from "assets/icons/AddFileIcon.svg";
import { ReactComponent as FileIcon } from "assets/icons/FileIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/DeleteIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/DownloadIcon.svg";

// LIBRARIES

// MISC
import { IProps } from "components/AttachmentFile/AttachmentFileModel";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import {
  useDeleteFileMutation,
  useGetAllFilesQuery,
  useUploadFileMutation,
} from "api/beneficiaryApi";
import { baseUrl } from "api/apiSlice";
import { useAppSelector } from "hooks/reduxHooks";
import { tokenSelector } from "slices/SessionSlice/sessionSliceSelectors";

// CONFIGURATION
const AttachmentFile = (props: IProps) => {
  // PROPERTIES
  const { beneficiaryData } = props;

  // API REQUESTS
  const { data: allBeneficiaryFiles } = useGetAllFilesQuery({
    beneficiaryId: beneficiaryData?.["id"],
  });

  const [uploadBeneficiaryFileRequest] = useUploadFileMutation();
  const [deleteBeneficiaryFileRequest] = useDeleteFileMutation();

  // LIBRARY CONSTANTS
  const currentToken = useAppSelector(tokenSelector);

  // STATE CONSTANTS
  const [file, setFile] = useState(null);

  // LIFE CYCLE

  // EVENT HANDLERS

  const uploadFiles = async (event) => {
    console.log("file", file);
    let files = [];
    files = event.target.files;

    const formData = new FormData();
    formData.append("file", files[0]);

    setFile(files[0]);

    await uploadBeneficiaryFileRequest({
      beneficiaryId: beneficiaryData?.["id"],
      formData,
    }).then(() => {
      setFile(null);
    });
  };

  const handleDownloadFile = async (fileId) => {
    const url = `${baseUrl}file/download?fileId=${fileId}`;

    const options = {
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/json",
      },
    };
    // fetch(url, options)
    //   .then((res) => {
    //     return res.blob();
    //   })
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "file.pdf");
    //     document.body.appendChild(link);
    //     link.click();
    //
    //     link.parentNode.removeChild(link);
    //   });
    // this version will try to get from the response the filename

    fetch(url, options)
      .then((res) => {
        const contentDisposition = res.headers.get("Content-Disposition");
        const matches = /filename="(.+?\.\w+)"/.exec(contentDisposition);
        const filename = matches ? matches[1] : "file.pdf";
        return res.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const handleDeleteFile = async (fileId) => {
    await deleteBeneficiaryFileRequest({
      fileId: fileId,
    }).then(() => {
      console.log("file.tsx deleted");
    });
  };

  return (
    <styled.AttachmentFileContainer>
      <styled.TitleContainer>
        <CustomText type="H3" text={"Fișiere anexate"} />
        <styled.AddFileContainer>
          <AddFileIcon />
          <styled.LabelForInput htmlFor="file">
            Adaugă fișier
          </styled.LabelForInput>
          <styled.InputToHide
            id="file"
            type="file"
            value={[]}
            onChange={uploadFiles}
            accept=".pdf,.doc,.docx,image/*"
          />
        </styled.AddFileContainer>
      </styled.TitleContainer>
      <styled.FilesContainer>
        {allBeneficiaryFiles?.length > 0 ? (
          allBeneficiaryFiles?.map((file, index) => (
            <styled.FileItem key={index + file.id}>
              <styled.FileDetailsContainer>
                <styled.IconWrapper>
                  <FileIcon />
                </styled.IconWrapper>

                <styled.Span>{file.fileName}</styled.Span>
              </styled.FileDetailsContainer>
              <styled.HandleFilesWrapper>
                <DownloadIcon onClick={() => handleDownloadFile(file.id)} />
                <DeleteIcon onClick={() => handleDeleteFile(file.id)} />
              </styled.HandleFilesWrapper>
            </styled.FileItem>
          ))
        ) : (
          <styled.FileItem>
            <styled.NoFilesWrapper>
              <styled.IconWrapper>
                <FileIcon />
              </styled.IconWrapper>
              <styled.Span>Niciun fișier anexat</styled.Span>
            </styled.NoFilesWrapper>
          </styled.FileItem>
        )}
      </styled.FilesContainer>
    </styled.AttachmentFileContainer>
  );
};
export default AttachmentFile;
