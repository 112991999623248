// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  SIZE,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from "constants/variables";

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${SIZE.max8};

  svg {
    aspect-ratio: 1;
    width: ${SIZE.max24};
    height: ${SIZE.max24};
  }
`;

export const SearchInput = styled.input`
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0 ${SIZE.max8};
  font-family: ${FONT_FAMILY.poppins};
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.max16};
  color: ${COLORS.appBlack};
  &::placeholder {
    font-weight: ${FONT_WEIGHT.semiBold};
    font-size: ${FONT_SIZE.max14};
    color: ${COLORS.appDarkGrey};
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
