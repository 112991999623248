// LIBRARIES
import styled from "styled-components";
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

// MISC

export const ControlPanelPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  //overflow-x: hidden;
  gap: ${SIZE.max24};
  height: 100%;
`;

export const LastBeneficiaryContainer = styled.div`
  gap: ${SIZE.max24};
  display: flex;
  flex-direction: column;
`;
export const TabSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardHistoryContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  justify-content: flex-start;
  //flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 12px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: ${SIZE.max24};
  padding-bottom: 12px;
  height: 100%;
  max-height: 450px;
  width: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const TableSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 15px;
  width: 60%;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;
export const ImmediateActionsContainer = styled.div`
  gap: ${SIZE.max16};
  overflow: auto;
  height: 100%;
  width: 100%;
  border-radius: ${BORDER_RADIUS.cardRadius};
  background-color: ${COLORS.appWhite};

  @media (max-width: 1280px) {
    width: 100%;
  }
`;
export const TableWrapper = styled.div`
  display: flex;
  background-color: ${COLORS.appWhite};
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};

  width: 100%;
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ChartContainer = styled.div`
  //height: 100%;
  min-height: 400px;
  width: 40%;
  display: flex;
  //justify-content: center;
  //align-items: center;

  @media (max-width: 1280px) {
    width: 100%;
  }
  background-color: ${COLORS.appWhite};

  border-radius: ${BORDER_RADIUS.cardRadius};
`;
