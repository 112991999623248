// LIBRARIES
import { DesktopDatePicker } from "@mui/x-date-pickers";
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
} from "constants/variables";

export const DatePickerComponent = styled(DesktopDatePicker)`
  width: 100%;
  background-color: ${COLORS.appWhite};

  .MuiOutlinedInput-notchedOutline {
    border: none !important ;
  }
  &.MuiFormControl-root {
    border: none;
    border-radius: ${BORDER_RADIUS.cardRadius};
    button {
      svg {
        //filter: invert(19%) sepia(85%) saturate(2249%) hue-rotate(218deg)
        //  brightness(93%) contrast(103%);
      }
    }
    input {
      border: none;

      color: ${COLORS.appBlack} !important;
      font-size: ${FONT_SIZE.max16} !important;
      opacity: 1 !important;
      font-family: ${FONT_FAMILY.poppins} !important;
    }
    input::placeholder {
      color: ${COLORS.appDarkGrey} !important;
      font-size: ${FONT_SIZE.max16} !important;
      opacity: 1 !important;
      font-family: ${FONT_FAMILY.poppins} !important;
    }
    label {
    }
  }
`;
