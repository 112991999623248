import jwt from "jwt-decode";
import { store } from "store/store";

export const useDecodedJwt = () => {
  const accessToken = store.getState().session.token;
  let decode = {
    id: "",
    sub: "",
    role: "",
    communityCenterId: 0,
    fullName: "",
  };
  if (accessToken) {
    decode = jwt(accessToken);
  }

  return {
    id: decode.id,
    userCode: decode.sub,
    role: decode.role,
    fullName: decode.fullName,
    communityCenterId: decode?.communityCenterId,
  };
};
