// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const NavContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-size: ${SIZE.max16};
  font-weight: ${FONT_WEIGHT.semiBold};
`;

export const NavMainBtnsContainer = styled.div<{ showLast?: boolean }>(
  ({ showLast }) => css`
    display: flex;
    flex-direction: column;
    gap: ${SIZE.max24};
    font-size: ${SIZE.max16};
    font-weight: ${FONT_WEIGHT.semiBold};
    ${showLast &&
    css`
      button:last-child {
        margin-top: 75px;
        background-color: ${COLORS.appBlue} !important;
        border-radius: ${BORDER_RADIUS.cardRadius};
        span {
          color: ${COLORS.appWhite};
          font-family: ${FONT_FAMILY.poppins};
          font-size: ${SIZE.max16};
          font-weight: ${FONT_WEIGHT.semiBold};
        }
      }
    `}
  `
);

export const LogoutButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const LogoutButtonText = styled.span`
  color: ${COLORS.appBlack};
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const LogOutContainer = styled.div`
  cursor: pointer;
`;
export const DownloadManualContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
export const DownloadText = styled.div`
  font-family: ${FONT_FAMILY.poppins};
  color: ${COLORS.appDarkGrey};
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.semiBold};
`;
