import { APP_ROLES, USER_LOGS_TYPES } from "constants/variables";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomSelect from "components/Atoms/CustomSelect/CustomSelect";
import CustomSelectWithCheckBox from "components/Atoms/CustomSelectWithCheckBox/CustomSelectWithCheckBox";

// const customDebounce = (func, timeout = 300) => {
//   let timer;
//   return (...args) => {
//     if (timer) {
//       clearTimeout(timer);
//     }
//     timer = setTimeout(() => {
//       func.apply(this, args);
//     }, timeout);
//   };
// };
//
// export const displayInitials = (value) => {
//   if (!value) return "";
//
//   const names = value.split(" ");
//   let initials = "";
//   names.forEach((name) => {
//     if (name) initials += name.charAt(0);
//   });
//
//   return initials;
// };

// export const formatDateTime = (dateInput, timeInput) => {
//   const [year, month, day] = dateInput.split("-");
//   const [hours, minutes] = timeInput.split(":");
//
//   const newDate = new Date(year, month - 1, day, hours, minutes);
//
//   const formattedDate = newDate.toISOString();
//
//   return formattedDate;
// };

export const formatDateTime = (dateInput, timeInput) => {
  const [year, month, day] = dateInput.split("-");
  const [hours, minutes] = timeInput.split(":");

  const newDate = new Date(year, month - 1, day, hours, minutes);

  // Get the timezone offset in minutes and convert it to hours and minutes
  const offset = newDate.getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;

  // Construct the timezone offset string
  const offsetSign = offset > 0 ? "-" : "+";
  const offsetString = `${offsetSign}${String(offsetHours).padStart(
    2,
    "0"
  )}:${String(offsetMinutes).padStart(2, "0")}`;

  // Construct the formatted date string with local timezone offset
  const formattedDate = `${newDate.getFullYear()}-${String(
    newDate.getMonth() + 1
  ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}T${String(
    newDate.getHours()
  ).padStart(2, "0")}:${String(newDate.getMinutes()).padStart(2, "0")}:${String(
    newDate.getSeconds()
  ).padStart(2, "0")}${offsetString}`;

  return formattedDate;
};

export const translateAppRoles = (value) => {
  switch (value) {
    case APP_ROLES.OPERATOR:
      return "Operator";
    case APP_ROLES.CENTER_MANAGER:
      return "Manager de centru";
    case APP_ROLES.ZONE_MANAGER:
      return "Manager judetean";
    case USER_LOGS_TYPES.CREATE:
      return "Creare";
    case USER_LOGS_TYPES.EDIT:
      return "Modificare";
    case USER_LOGS_TYPES.DELETE:
      return "Stergere";
    case USER_LOGS_TYPES.ACTIVATE:
      return "Activare";
    case USER_LOGS_TYPES.INACTIVATE:
      return "Dezactivare";
    case USER_LOGS_TYPES.UPLOAD:
      return "Incarcare";
    case USER_LOGS_TYPES.DOWNLOAD:
      return "Descarcare";
    case USER_LOGS_TYPES.COMPLETE_DOCUMENT:
      return "Completare document";
    case USER_LOGS_TYPES.CREATE_ACTIVITY:
      return "Creare activitate";
    case USER_LOGS_TYPES.SHARE_BENEFICIARY:
      return "Partajare beneficiar";
    default:
      return value;
  }

  // if (value === APP_ROLES.OPERATOR) {
  //   return "Operator";
  // } else if (value === APP_ROLES.CENTER_MANAGER) {
  //   return "Manager de centru";
  // } else if (value === APP_ROLES.ZONE_MANAGER) {
  //   return "Manager judetean";
  // }
  // return value;
};

export const renderData = (element, modalDetails, handleModalChange) => {
  switch (element.type) {
    case "input":
      return (
        <CustomInput
          appearance="secondary"
          placeholder={element.placeholder}
          name={element.name}
          value={modalDetails[element.name]}
          onChange={(event) =>
            handleModalChange(
              (event.target as HTMLInputElement).name,
              (event.target as HTMLInputElement).value
            )
          }
        />
      );
    case "dropdown":
      return (
        <CustomSelect
          options={element.options}
          value={modalDetails[element.name]}
          placeholder={element.placeholder}
          onChange={(event) =>
            handleModalChange(element.name, event.target.value)
          }
        />
      );
    default:
      return <></>;
  }
};

export const renderOperatorData = (
  element,
  modalDetails,
  handleModalChange
) => {
  switch (element.type) {
    case "input":
      return (
        <CustomInput
          appearance="secondary"
          placeholder={element.placeholder}
          name={element.name}
          value={modalDetails[element.name]}
          onChange={(event) =>
            handleModalChange(
              (event.target as HTMLInputElement).name,
              (event.target as HTMLInputElement).value
            )
          }
        />
      );
    case "dropdown":
      return (
        element.name === "role" && (
          <CustomSelect
            options={element.options}
            value={modalDetails[element.name]}
            placeholder={element.placeholder}
            onChange={(event) =>
              handleModalChange(element.name, event.target.value)
            }
          />
        )
      );

    case "dropdownOperator":
      return (
        modalDetails["role"] === APP_ROLES.OPERATOR && (
          <CustomSelect
            options={element.options}
            value={modalDetails[element.name]}
            placeholder={element.placeholder}
            onChange={(event) =>
              handleModalChange(element.name, [event.target.value])
            }
          />
        )
      );
    case "dropdownCounty":
      return (
        modalDetails["role"] === APP_ROLES.ZONE_MANAGER && (
          <CustomSelect
            options={element.options}
            value={modalDetails[element.name]}
            placeholder={element.placeholder}
            onChange={(event) =>
              handleModalChange(element.name, event.target.value)
            }
          />
        )
      );

    case "dropdownCenters":
      return (
        modalDetails["role"] === APP_ROLES.CENTER_MANAGER && (
          <CustomSelectWithCheckBox
            options={element.options}
            value={modalDetails[element.name]}
            placeholder={element.placeholder}
            onChange={(event) =>
              handleModalChange(element.name, event.target.value)
            }
          />
        )
      );
    default:
      return <></>;
  }
};
