import React from "react";

// ASSETS
import { ReactComponent as LogOutIcon } from "assets/icons/LogoutIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/DownloadIcon.svg";

// STYLES
import * as styled from "./NavContentStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// MISC
import { APP_ROLES } from "constants/variables";
import { useDecodedJwt } from "hooks/useDecodedJwt";
import { IButtonStyle } from "components/Atoms/CustomIconButton/CustomIconButtonModel";
import { handleSetToken } from "slices/SessionSlice/sessionSlice";
import { useAppDispatch } from "hooks/reduxHooks";

// COMPONENTS
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import { revertAll } from "store/store";
import { handleSearchChange } from "slices/TempSlice/tempSlice";

// CONFIGURATION
const NavContent = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role } = useDecodedJwt();
  const pdfUrl = "https://backend.asigur.cpss.ro/api/file/download-manual-file";

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const sideMenuNavData = [
    {
      icon: null,
      path: "control-panel",
      text: "Panou",
      isAvailable: [APP_ROLES.OPERATOR, APP_ROLES.CENTER_MANAGER].includes(
        role
      ),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "beneficiary-list",
      text: "Beneficiari",
      isAvailable: [APP_ROLES.OPERATOR, APP_ROLES.CENTER_MANAGER].includes(
        role
      ),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "operators",
      text: "Operatori",
      isAvailable: [
        APP_ROLES.ADMIN,
        APP_ROLES.ZONE_MANAGER,
        APP_ROLES.CENTER_MANAGER,
      ].includes(role),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "statistics",
      text: "Statistici",
      isAvailable: [
        APP_ROLES.OPERATOR,
        APP_ROLES.ZONE_MANAGER,
        APP_ROLES.CENTER_MANAGER,
      ].includes(role),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "services",
      text: "Servicii",
      isAvailable: [APP_ROLES.ADMIN].includes(role),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "community-centers",
      text: "Centre Comunitare",
      isAvailable: [APP_ROLES.ADMIN].includes(role),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "target-groups",
      text: "Grupuri țintă",
      isAvailable: [APP_ROLES.ADMIN].includes(role),
      appearance: "primary" as IButtonStyle,
    },

    {
      icon: null,
      path: "templates-list",
      text: "Șabloane",
      isAvailable: [APP_ROLES.ADMIN].includes(role),
      appearance: "primary" as IButtonStyle,
    },
    {
      icon: null,
      path: "new-document-list",
      text: "Document nou",
      isAvailable: [APP_ROLES.OPERATOR, APP_ROLES.CENTER_MANAGER].includes(
        role
      ),
      appearance: "primary" as IButtonStyle,
    },
  ];
  const handleSideMenuNav = (path: string) => {
    navigate(`/${path}`);
    dispatch(handleSearchChange({ value: "" }));
  };

  const handleLogout = () => {
    dispatch(handleSetToken(""));
    dispatch(revertAll());
    navigate("/login");
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Manual utilizare ASIGUR.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <styled.NavContentContainer>
      <styled.NavMainBtnsContainer
        showLast={[APP_ROLES.OPERATOR, APP_ROLES.CENTER_MANAGER].includes(role)}
      >
        {sideMenuNavData?.map((navData) => {
          return (
            navData.isAvailable && (
              <CustomIconButton
                appearance={navData.appearance}
                handleClick={() => handleSideMenuNav(navData.path)}
                isSelected={window.location.pathname
                  .split("/")
                  .includes(navData.path)}
                key={`side-menu-custom-btn-list--${navData.path}`}
              >
                {navData.text}
              </CustomIconButton>
            )
          );
        })}
      </styled.NavMainBtnsContainer>
      <styled.BottomContainer>
        <styled.DownloadManualContainer onClick={handleDownload}>
          <DownloadIcon />
          <styled.DownloadText>Descarcă manualul</styled.DownloadText>
        </styled.DownloadManualContainer>
        <styled.LogOutContainer onClick={handleLogout}>
          <styled.LogoutButtonTextContainer>
            <LogOutIcon />
            <styled.LogoutButtonText>Inchide</styled.LogoutButtonText>
          </styled.LogoutButtonTextContainer>
        </styled.LogOutContainer>
      </styled.BottomContainer>
    </styled.NavContentContainer>
  );
};

export default NavContent;
