import { apiSlice } from "../api/apiSlice";

export const newDocumentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTargetGroupsList: builder.query<any, void>({
      query: () => `target-group/get-all-target-groups`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllActivityCategories: builder.query<any, void>({
      query: () => `activity/get-all-activity-categories`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getExportedData: builder.query<Blob, void>({
      query: () => `export/activities-excel`,

      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllActivityStatistics: builder.query<
      any,
      { activityCategoryId: number }
    >({
      query: ({ activityCategoryId }) =>
        `statistics/get-activity-statistics?activityCategoryId=${activityCategoryId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllAgeGroupsStatistics: builder.query<any, void>({
      query: () => `statistics/get-age-groups-statistics`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllTargetGroupsStatistics: builder.query<any, void>({
      query: () => `statistics/get-target-group-statistics`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getStatusStatistics: builder.query<
      any,
      {
        type: string;
      }
    >({
      query: ({ type }) => `statistics/get-status?dateType=${type}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllStatisticsTable: builder.query<
      any,
      {
        page: number;
        size: number;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, sortField, sortOrder }) =>
        `statistics/get-activities-table?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetAllTargetGroupsListQuery,
  useGetAllStatisticsTableQuery,
  useGetAllActivityCategoriesQuery,
  useGetAllActivityStatisticsQuery,
  useGetAllAgeGroupsStatisticsQuery,
  useGetAllTargetGroupsStatisticsQuery,
  useLazyGetExportedDataQuery,
  useGetStatusStatisticsQuery,
} = newDocumentApi;
