export const data = {
  planned: [
    {
      id: 0,
      name: "Aliodor Manolea",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
      id: 1,
      name: "Aristide Teică",
      description:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  ],
  inProgress: [
    {
      id: 2,
      name: "uwuwewewe onyetenwewe ugweuhem osas",
      description:
        "Text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
      id: 3,
      name: "Leopoldina Bălănuţă",
      description:
        "The industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
  ],
  done: [
    {
      id: 4,
      name: "Vasile San Siro Ciocoi",
      description:
        "Publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
      id: 5,
      name: "Tom-Mac-Bil-Bob-Constantin Cojocaru",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    },
  ],
};

export const filesList = [
  { id: 0, folder: "The Shawshank Redemption" },
  { id: 1, folder: "The Godfather" },
  { id: 2, folder: "May The Force Be with You" },
  { id: 3, folder: "I Am Your Father" },
  { id: 4, folder: "Night Museum" },
  { id: 5, folder: "Orange Juice" },
  { id: 6, folder: "NotYour Type" },
  { id: 7, folder: "Try Again" },
  { id: 8, folder: "Later Aligator" },
  { id: 9, folder: "Midnight Blue" },
  { id: 10, folder: "Sunshine Beauty" },
  { id: 11, folder: "Juice Jar" },
  { id: 12, folder: "HereWe GoAgain" },
];
