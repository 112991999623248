interface IPaginationDetails {
  pageCount: number; // total number of pages
  pageSize: number; // how many elem page should have
  pageNumber: number; // Current page
}

interface ITemplateInitialState {
  isLoading: boolean;
  clientBeneficiaryPagination: IPaginationDetails;
  clientBeneficiaryActivitiesPagination: IPaginationDetails;
  clientControlPagePagination: IPaginationDetails;
  statisticsPagePagination: IPaginationDetails;
  clientBeneficiaryPageDocumentsPagination: IPaginationDetails;
}

export const initialState: ITemplateInitialState = {
  isLoading: false,
  clientBeneficiaryPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  clientBeneficiaryActivitiesPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  clientControlPagePagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  clientBeneficiaryPageDocumentsPagination: {
    pageCount: 30, // total number of pages
    pageSize: 10, // how many elem page should have
    pageNumber: 1, // Current page
  },
  statisticsPagePagination: {
    pageCount: 30, // total number of pages
    pageSize: 5, // how many elem page should have
    pageNumber: 1, // Current page
  },
};
