export const operatorsControlPanelTableHeadValues = [
  {
    name: "Tip Document",
    sortField: "type",
    sortOrder: "",
  },
  {
    name: "Denumire",
    sortField: "name",
    sortOrder: "",
  },
  {
    name: "Benificiar",
    sortField: "beneficiary",
    sortOrder: "",
  },

  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "",
  },
];

export const operatorsControlPanelTableBodyValues = [
  {
    id: 1,
    documentType: "Fisa",
    name: "Fișă de consiliere și informare socialădgas.ro",
    beneficiary: "Reti Ana",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 2,
    documentType: "Fisa",
    name: "Raport evaluare psihiologică",
    beneficiary: "Roca Boldog Iosif",
    createdAt: "04.09.2021",
    isActive: true,
  },
  {
    id: 3,
    documentType: "Formular",
    name: "Formular consimțământ",
    beneficiary: "Cochioarca Marcu Damian",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 4,
    documentType: "Raport",
    name: "Raport vizită",
    beneficiary: "Antal Alexandra Ștefania",
    createdAt: "02.07.2021",
    isActive: true,
  },
];

export const operatorsTableHeadValues = [
  {
    name: "Tip Document",
    sortField: "username",
    sortOrder: "",
  },
  {
    name: "Denumire",
    sortField: "fullName",
    sortOrder: "",
  },
  {
    name: "Operator",
    sortField: "role",
    sortOrder: "",
  },

  {
    name: "Creat La Data",
    sortField: "createdAt",
    sortOrder: "",
  },
];

export const operatorsTableBodyValues = [
  {
    id: 1,
    documentType: "Fisa",
    name: "Fișă de consiliere și informare socialădgas.ro",
    operator: "Farcaș Bianca",
    createdAt: "17.09.2021",
    isActive: true,
  },
  {
    id: 2,
    documentType: "Fisa",
    name: "Raport evaluare psihiologică",
    operator: "Farcaș Bianca",
    createdAt: "04.09.2021",
    isActive: true,
  },
  {
    id: 3,
    documentType: "Formular",
    name: "Formular consimțământ",
    operator: "Farcaș Bianca",
    createdAt: "19.02.2023",
    isActive: true,
  },
  {
    id: 4,
    documentType: "Raport",
    name: "Raport vizită",
    operator: "Farcaș Bianca",
    createdAt: "02.07.2021",
    isActive: true,
  },
  {
    id: 5,
    documentType: "Raport",
    name: "Raport evaluare psihiologică",
    operator: "Farcaș Bianca",
    createdAt: "03.08.2021",
    isActive: true,
  },
  {
    id: 6,
    documentType: "Fișă",
    name: "Fișă de consiliere și informare socială",
    operator: "Farcaș Bianca",
    createdAt: "17.09.2021",
    isActive: true,
  },
];

export const operatorsTableBodyContent = [
  {
    id: 1,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
  {
    id: 2,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
  {
    id: 3,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
  {
    id: 4,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
  {
    id: 5,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
  {
    id: 6,
    duration: { h: 2, m: 45 },
    objectiv:
      "Dignissim diam quis enim lobortis scelerisque. Ultrices vitae auctor eu augue. Neque gravida in fermentum et sollicitudin. Auctor elit sed vulputate mi sit. Neque convallis a cras semper auctor neque vitae tempus. Gravida in fermentum et sollicitudin acorci. Erat pellentesque adipiscing commodo elit at imperdiet. Massa tincidunt dui ut ornare lectus sit amet est. Gravida rutrumquisque non tellus.",
    content:
      "Luctus accumsan tortor posuere ac. Sit amet consectetur adipiscing elit. Arcu non odio euismod lacinia at quis risus. Amet risus nullam eget felis. Dui ut ornare lectus sit amet est placerat in. Nunc sed augue lacus viverra vitae congue. In dictum non consectetur a erat nam at lectus urna. Urna cursus eget nunc scelerisque viverra mauris in. Ac placerat vestibulum lectus mauris. Aliquam id diam maecenas ultricies. Auctor neque vitae tempus quam pellentesque nec nam aliquam sem. Vitae purus faucibus ornare suspendisse sed nisi. Sodales ut eu sem integer vitae justo eget. Suscipit tellus mauris a diam. Tristi que risus nec feugiat in fermentum posuere. Et egestas quis ipsum suspendisse ultrices. Mattis enim ut tellus elementum. Sit amet volutpat consequat mauris nunc. Cursus risus at ultrices mi tempus. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Augue ut lectus arcu bibendum at varius vel pharetra. Accumsan lacus vel facilisis volutpat est. Quis commodo odio aenean sed. Lobortis elementum nibh tellus molestie nunc non. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus ultricies tristique nulla aliquet enim tortor. Enim tortor at auctor urna nunc id cursus. Tincidunt dui ut ornare lectus sit. Enim neque volutpat ac tincidunt vitae. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique senectus et netus et. Proin sed libero enim sed faucibus turpis in eu mi. Ante in nibh mauris cursus mattis molestie.Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien nec. Convallis aenean et tortor at risus viverra adipiscing. Curabitur vitae nunc sed velit dignissim sodales ut eu sem. Nulla porttitor massa id neque aliquam vestibulum morbi. Id semper risus in hendrerit gravida rutrum quisque non. Dui id ornare arcu odio ut sem. Adipiscing elit duis tristique sollicitudin. Consequat semper viverra nam libero justo laoreet sit.",
    conclusion:
      "Erat imperdiet sed euismod nisi porta lorem. In fermentum et sollicitudin ac orci. ",
  },
];
