// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const ActivitiesTabListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};

  background-color: ${COLORS.appWhite};
  padding: ${SIZE.max24};
  width: 100%;
  height: 100%;

  overflow-y: auto;
  border-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ActivitiesTabListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.appLightGrey};
`;
export const ActivitiesTabListTitle = styled.span``;
export const ActivitiesModalContainer = styled.div``;
export const ActivitiesModalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SIZE.max8};
`;
export const ActivitiesModalBtnText = styled.span`
  color: ${COLORS.appBlue};
`;

export const CustomTextWarningContainer = styled.div``;

export const ActivitiesTabListBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: ${SIZE.max24};
`;
export const ActivityItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;
`;
// MARKER SECTION
export const ActivityMarkerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
`;
export const ActivityMarkerDot = styled.div`
  min-width: 12px;
  min-height: 12px;

  border-radius: 50%;
  border: 2px solid ${COLORS.appBlue};
  background-color: ${COLORS.appWhite};
`;
export const ActivityMarkerLine = styled.div`
  width: 2px;
  background-color: ${COLORS.appDarkGrey};
  height: 100%;
`;

// DATE SECTION
export const DateMarkerSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  width: 90px;
`;
export const DateMarkerDate = styled.span`
  font-size: ${FONT_SIZE.max14};
  color: ${COLORS.appBlack};
`;
export const DateMarkerTime = styled.span`
  font-size: ${FONT_SIZE.max14};
  color: ${COLORS.appDarkGrey};
  white-space: nowrap;
`;

// DETAILS SECTION
export const DetailsMarkerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const DetailsMarkerTitle = styled.span`
  font-size: ${FONT_SIZE.max16};
  font-weight: ${FONT_WEIGHT.semiBold};
  color: ${COLORS.appBlack};
`;
export const DetailsMarkerAssignee = styled.span`
  font-size: ${FONT_SIZE.max16};
  color: ${COLORS.appDarkGrey};
`;
export const DetailsMarkerDesc = styled.span`
  font-size: ${FONT_SIZE.max16};
  color: ${COLORS.appBlack};
`;
