import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomAutocompleteMultipleSelectionStyled";

// LIBRARIES
import { Autocomplete, TextField } from "@mui/material";

// MISC
import { IProps } from "./CustomAutocompleteMultipleSelectionModel";
import { COLORS, FONT_SIZE, FONT_WEIGHT } from "constants/variables";

// REDUX

// COMPONENTS

// CONFIGURATION
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const CustomAutocompleteMultipleSelection = (props: IProps) => {
  // PROPERTIES
  const {
    parseData = [],
    label = "",
    onChange = () => {},
    name = "",
    placeholder = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const [value, setValue] = React.useState<any>([]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      options={parseData}
      multiple
      disableClearable
      freeSolo={false}
      popupIcon={""}
      forcePopupIcon={false}
      inputValue={value}
      onChange={(_e, value) => {
        const listOfIds = value?.map((item) => item?.key);
        onChange(name, listOfIds);
      }}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      getOptionLabel={(option) => option?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="none"
          placeholder={placeholder}
          InputLabelProps={{
            style: {
              color: COLORS.appBlack,
              textTransform: "uppercase",
              fontSize: FONT_SIZE.max12,
              fontWeight: FONT_WEIGHT.bold,
              fontFamily: "Poppins",
            },
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option?.value, inputValue, {
          insideWords: true,
        });
        const parts = parse(option?.value, matches);

        return (
          <styled.CustomAutocompleteList {...props}>
            <styled.CustomAutocompleteListContainer>
              {parts?.map((part, index) => (
                <styled.CustomAutocompleteListLabel
                  key={index}
                  isHighlighted={part.highlight}
                >
                  {part.text}
                </styled.CustomAutocompleteListLabel>
              ))}
            </styled.CustomAutocompleteListContainer>
          </styled.CustomAutocompleteList>
        );
      }}
    />
  );
};
export default CustomAutocompleteMultipleSelection;
