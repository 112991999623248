// LIBRARIES
import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "assets/icons/ArrowLeft.svg";

// MISC
import { BORDER_RADIUS, COLORS, SIZE } from "constants/variables";

export const OperatorLogsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;

export const OperatorsPageHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
`;
export const ArrowLeftBack = styled(ArrowLeft)`
  transform: scale(1.4);
  filter: brightness(0) saturate(100%) invert(38%) sepia(100%) saturate(6775%)
    hue-rotate(228deg) brightness(89%) contrast(90%);
`;

export const OperatorsPageTableContainer = styled.div`
  height: 100%;
`;
export const OperatorsPagePaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OperatorsPageTableWrapper = styled.div`
  background-color: ${COLORS.appWhite};
  height: 589px;
  overflow-y: auto;
  border-radius: ${BORDER_RADIUS.cardRadius};
`;

export const TitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  /* height: ${SIZE.max65}; */
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
