// LIBRARIES
import styled from "styled-components";

// MISC
import {
  COLORS,
  FONT_WEIGHT,
  SIZE,
  BORDER_RADIUS,
  FONT_FAMILY,
  FONT_SIZE,
} from "constants/variables";

export const QuestionnaireCardContainer = styled.div`
  position: relative;
  background-color: ${COLORS.appWhite};
  width: 200px;
  height: ${SIZE.max280};
  border-radius: ${BORDER_RADIUS.cardRadius};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: ${SIZE.max24};
  :hover {
    box-shadow: 3px 3px 30px rgba(176, 179, 191, 0.25);
    transition: 0.5s;
    cursor: pointer;
  }
`;
export const TitleContainer = styled.div`
  display: inline;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: ${SIZE.max104};
  width: 85%;
`;
export const DateContainer = styled.div`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max12};
`;
export const Date = styled.div`
  font-weight: ${FONT_WEIGHT.bold};
  color: ${COLORS.appBlack};
`;
export const Span = styled.span`
  font-weight: ${FONT_WEIGHT.medium};
  color: ${COLORS.appDarkGrey};
`;
export const TemplateListCardCloneButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const TextOnHover = styled.div`
  z-index: 100;
  line-height: 1;
  background-color: ${COLORS.appWhite};
  padding: 10px;
  border-radius: ${BORDER_RADIUS.cardRadius};
  position: absolute;
  left: 50%;
  top: 20%;
  box-shadow: 3px 3px 30px rgba(176, 179, 191, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;
