import { apiSlice } from "../api/apiSlice";

export const beneficiaryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------- OPERATORS ------------------ //
    getAllBeneficiaries: builder.query<
      any,
      {
        page: number;
        size: number;
        searchQuery: string;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, searchQuery, sortField, sortOrder }) =>
        `beneficiary/get-all-beneficiaries-pageable?page=${page}&searchQuery=${searchQuery}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getAllActivityCategories: builder.query<any, void>({
      query: () => `activity/get-all-activity-categories`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getBeneficiaryById: builder.query<any, { beneficiaryId: number }>({
      query: ({ beneficiaryId }) =>
        `beneficiary/get-beneficiary?beneficiaryId=${beneficiaryId}`,
      providesTags: ["ALL_BENEFICIARIES"],
      keepUnusedDataFor: 0,
    }),

    getBeneficiaryActivities: builder.query<
      any,
      {
        beneficiaryId: number;
        activityCategoryIds: string;
        page: number;
        size: number;
        searchQuery: string;
      }
    >({
      query: ({
        beneficiaryId,
        activityCategoryIds,
        page = 0,
        size,
        searchQuery,
      }) =>
        `beneficiary/get-beneficiary-activities?activityCategoryIds=${activityCategoryIds}&beneficiaryId=${beneficiaryId}&page=${page}&searchQuery=${searchQuery}&size=${size}`,
      providesTags: ["ALL_BENEFICIARY_ACTIVITIES"],
      keepUnusedDataFor: 0,
    }),

    getActivityByServiceId: builder.query<any, { activityCategoryId: number }>({
      query: ({ activityCategoryId }) =>
        `activity/get-all-activity-categories-activities?activityCategoryId=${activityCategoryId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    activateInactivateClientBeneficiary: builder.mutation<
      any,
      {
        isActive: boolean;
        beneficiaryId: number;
        inactivationReason: string;
      }
    >({
      query: ({ isActive, beneficiaryId, inactivationReason }) => ({
        url: `beneficiary/activate-or-inactivate-beneficiary?isActive=${isActive}&beneficiaryId=${beneficiaryId}&inactivationReason=${inactivationReason}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_BENEFICIARIES"],
    }),

    getCommunityCenterBeneficiariesById: builder.query<
      any,
      {
        communityCenterId: number;
      }
    >({
      query: ({ communityCenterId }) =>
        `beneficiary/get-community-center-beneficiaries?communityCenterId=${communityCenterId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    createBeneficiaryActivity: builder.mutation<
      any,
      {
        activityId: number;
        assignee: string;
        beneficiaryId: number;
        description: string;
        endtAt: string;
        startAt: string;
      }
    >({
      query: ({
        activityId,
        assignee,
        beneficiaryId,
        description,
        endtAt,
        startAt,
      }) => ({
        url: `beneficiary/create-beneficiary-activity`,
        method: "POST",
        body: {
          activityId,
          assignee,
          beneficiaryId,
          description,
          endtAt,
          startAt,
        },
      }),
      invalidatesTags: ["ALL_BENEFICIARY_ACTIVITIES"],
    }),

    createMultipleBeneficiaryActivity: builder.mutation<
      any,
      {
        activityId: number;
        assignee: string;
        description: string;
        endAt: string;
        beneficiaryIds: number[];
        startAt: string;
      }
    >({
      query: ({
        activityId,
        assignee,
        description,
        endAt,
        beneficiaryIds,
        startAt,
      }) => ({
        url: `beneficiary/create-beneficiaries-activity`,
        method: "POST",
        body: {
          activityId,
          assignee,
          beneficiaryIds,
          description,
          endAt,
          startAt,
        },
      }),
      invalidatesTags: ["ALL_BENEFICIARY_ACTIVITIES"],
    }),

    editBeneficiaryActivity: builder.mutation<
      any,
      {
        activityId: number;
        assignee: string;
        beneficiaryId: number;
        description: string;
        endtAt: string;
        startAt: string;
        id: number;
      }
    >({
      query: ({
        activityId,
        assignee,
        beneficiaryId,
        description,
        endtAt,
        startAt,
        id,
      }) => ({
        url: `beneficiary/update-beneficiary-activity`,
        method: "PUT",
        body: {
          activityId,
          assignee,
          beneficiaryId,
          description,
          endtAt,
          startAt,
          id,
        },
      }),
      invalidatesTags: ["ALL_BENEFICIARY_ACTIVITIES"],
    }),

    deleteBeneficiaryActivity: builder.mutation<
      any,
      {
        activityId: number;
        isActive: boolean;
      }
    >({
      query: ({ activityId, isActive }) => ({
        url: `beneficiary/activate-or-inactivate-beneficiary-activity?beneficiaryActivityId=${activityId}&isActive=${isActive}`,
        method: "PUT",
      }),
      invalidatesTags: ["ALL_BENEFICIARY_ACTIVITIES"],
    }),

    getOperatorsCompletedForms: builder.query<
      any,
      {
        page: number;
        size: number;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, sortField, sortOrder }) =>
        `form/get-operator-completed-forms-pageable?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getBeneficiaryCompletedForms: builder.query<
      any,
      {
        page: number;
        size: number;
        sortField: string;
        sortOrder: string;
        beneficiaryId: number;
      }
    >({
      query: ({ page, size, sortField, sortOrder, beneficiaryId }) =>
        `beneficiary/get-beneficiary-completed-forms-pageable?beneficiaryId=${beneficiaryId}&page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getUsersLogs: builder.query<
      any,
      {
        page: number;
        size: number;
        userId: number;
        sortField: string;
        sortOrder: string;
      }
    >({
      query: ({ page, size, sortField, sortOrder, userId }) =>
        `log/get-user-logs-pageable?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&userId=${userId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    getCompletedFormById: builder.query<
      any,
      {
        formId: number;
      }
    >({
      query: ({ formId }) => `beneficiary/get-completed-form?formId=${formId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),

    shareBeneficiary: builder.mutation<
      any,
      {
        assigneeId: number;
        beneficiaryId: number;
      }
    >({
      query: ({ assigneeId, beneficiaryId }) => ({
        url: `beneficiary/share-beneficiary?assigneeId=${assigneeId}&beneficiaryId=${beneficiaryId}`,
        method: "POST",
      }),
      invalidatesTags: ["ALL_USERS_WITH_ACCESS"],
    }),

    transferBeneficiary: builder.mutation<
      any,
      {
        newAssigneeId: number;
        oldAssigneeId: number;
      }
    >({
      query: ({ newAssigneeId, oldAssigneeId }) => ({
        url: `beneficiary/transfer-beneficiaries-to-new-assignee?newAssigneeId=${newAssigneeId}&oldAssigneeId=${oldAssigneeId}`,
        method: "POST",
      }),
      invalidatesTags: [],
    }),

    removeSharedOperatorForBeneficiary: builder.mutation<
      any,
      {
        assigneeId: number;
        beneficiaryId: number;
      }
    >({
      query: ({ assigneeId, beneficiaryId }) => ({
        url: `beneficiary/remove-shared-operator-for-beneficiary?assigneeId=${assigneeId}&beneficiaryId=${beneficiaryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ALL_USERS_WITH_ACCESS"],
    }),

    // -------------------- BENEFICIARY DETAILS ------------------ //
    updateBeneficiaryDetails: builder.mutation<
      any,
      {
        birthDate: string;
        city: string;
        cnp: string;
        communityCenterId: number;
        conditionsDescription: string;
        fullName: string;
        gender: string;
        id: number;
        targetGroupId: number;
      }
    >({
      query: ({
        birthDate,
        city,
        cnp,
        communityCenterId,
        conditionsDescription,
        fullName,
        gender,
        id,
        targetGroupId,
      }) => ({
        url: `beneficiary/update-beneficiary`,
        method: "PUT",
        body: {
          birthDate,
          city,
          cnp,
          communityCenterId,
          conditionsDescription,
          fullName,
          gender,
          id,
          targetGroupId,
        },
      }),
      invalidatesTags: ["ALL_BENEFICIARIES"],
    }),

    // -------------------- FILES ------------------ //
    getAllFiles: builder.query<
      any,
      {
        beneficiaryId: number;
      }
    >({
      query: ({ beneficiaryId }) =>
        `beneficiary/get-beneficiary-files?beneficiaryId=${beneficiaryId}`,
      providesTags: ["ALL_FILES"],
      keepUnusedDataFor: 0,
    }),
    downloadFile: builder.query<
      any,
      {
        fileId: number;
      }
    >({
      query: ({ fileId }) => `file/download?fileId=${fileId}`,
      providesTags: [],
      keepUnusedDataFor: 0,
    }),
    uploadFile: builder.mutation<
      any,
      {
        beneficiaryId: number;
        formData: FormData;
      }
    >({
      query: ({ beneficiaryId, formData }) => ({
        url: `file/upload?beneficiaryId=${beneficiaryId}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ALL_FILES"],
    }),

    deleteFile: builder.mutation<
      any,
      {
        fileId: number;
      }
    >({
      query: ({ fileId }) => ({
        url: `file/delete?fileId=${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ALL_FILES"],
    }),
  }),
});

export const {
  useGetAllBeneficiariesQuery,
  useGetAllActivityCategoriesQuery,
  useGetBeneficiaryByIdQuery,
  useGetBeneficiaryActivitiesQuery,
  useLazyGetBeneficiaryActivitiesQuery,
  useGetActivityByServiceIdQuery,

  useActivateInactivateClientBeneficiaryMutation,
  useGetOperatorsCompletedFormsQuery,
  useGetBeneficiaryCompletedFormsQuery,
  useGetUsersLogsQuery,
  useShareBeneficiaryMutation,
  useRemoveSharedOperatorForBeneficiaryMutation,
  useTransferBeneficiaryMutation,
  useLazyGetCompletedFormByIdQuery,

  useCreateMultipleBeneficiaryActivityMutation,
  useGetCommunityCenterBeneficiariesByIdQuery,

  // BENEFICIARY DETAILS
  useCreateBeneficiaryActivityMutation,
  useUpdateBeneficiaryDetailsMutation,
  useEditBeneficiaryActivityMutation,
  useDeleteBeneficiaryActivityMutation,

  // FILES
  useGetAllFilesQuery,
  useLazyDownloadFileQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
} = beneficiaryApi;
