// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomIconButton/CustomIconButtonModel";
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const CustomIconButtonComponent = styled.button<IStyled>(
  ({ appearance }) => css`
    font-family: ${FONT_FAMILY.poppins};
    ${appearance === "primary" &&
    css`
      width: 100%;
      background-color: transparent;
      border: none;
    `}
    ${appearance === "secondary" &&
    css`
      width: 100%;
      background-color: transparent;
      border: none;
      height: 100%;
    `}
    ${appearance === "backButton" &&
    css`
      border: none;
      border-radius: ${BORDER_RADIUS.cardRadius};
      display: flex;
      justify-content: center;
      align-items: center;
    `} 
    ${appearance === "headerBackButton" &&
    css`
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: ${FONT_SIZE.max14};
      color: ${COLORS.appDarkGrey};
      font-weight: ${FONT_WEIGHT.semiBold};
    `}
    ${appearance === "simpleArrowBackWithText" &&
    css`
      width: 100%;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: ${FONT_FAMILY.poppins};
      font-size: ${FONT_SIZE.max20};
      color: ${COLORS.appBlack};
      font-weight: ${FONT_WEIGHT.bold};
      text-transform: uppercase;
    `}
  `
);

export const CustomIconButtonWrapper = styled.span<IStyled>(
  ({ appearance, isSelected }) => css`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    ${appearance === "primary" &&
    css`
      color: ${COLORS.appDarkGrey};
      font-size: ${FONT_SIZE.max16};
      font-weight: ${FONT_WEIGHT.semiBold};
      padding: 8px ${SIZE.max16};
      border-radius: ${BORDER_RADIUS.cardRadius};

      &:hover {
        color: ${COLORS.appBlack};
        transition: 0.5s;
      }

      ${isSelected &&
      css`
        color: ${COLORS.appBlack};
        background-color: ${COLORS.appWhite};
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      `}
      ${!isSelected &&
      css`
        background-color: transparent;
      `}
    `}
    ${appearance === "secondary" && css``}
    ${appearance === "backButton" &&
    css`
      font-size: ${FONT_SIZE.max16};
      svg {
        aspect-ratio: 1;
      }
    `}
    ${appearance === "headerBackButton" &&
    css`
      svg {
        aspect-ratio: 1;
        width: 40px;
        border-radius: 50%;
        background-color: ${COLORS.appWhite};
      }
    `}
    ${appearance === "simpleArrowBackWithText" &&
    css`
      svg {
        aspect-ratio: 1;
        width: 40px;
        height: 40px;
        background-color: transparent;
        margin-right: 8px;
      }
    `}
  `
);
