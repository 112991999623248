// @ts-nocheck
import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./CustomTextAreaStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomTextAreaModel";

// COMPONENTS

// CONFIGURATION

const CustomTextArea = (props: IProps) => {
  // PROPERTIES
  const {
    validation = "",
    wordLength = 200,
    type = "long", // short, long
    value = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  const [inputValue, setInputValue] = useState(value);

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (value.length > wordLength) {
      return;
    } else {
      setInputValue(value);
    }
    props.onChange(event);
  };

  return (
    <styled.InputComponentWrapper validation={validation} type={type}>
      <styled.Textarea
        {...props}
        value={value || inputValue}
        onChange={handleInputChange}
      />
      <styled.CharacterCount>
        {wordLength - inputValue.length}
      </styled.CharacterCount>
    </styled.InputComponentWrapper>
  );
};

export default CustomTextArea;
