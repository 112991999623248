import React, { useEffect, useState } from "react";

// ASSETS
import { ReactComponent as Logo } from "assets/icons/Logo.svg";

// STYLES
import * as styled from "./LoginPageStyled";

// LIBRARIES
import { useAppDispatch } from "hooks/reduxHooks";
import { handleSetToken } from "slices/SessionSlice/sessionSlice";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "api/sessionApi";

// MISC

// COMPONENTS
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION

const LoginPage = () => {
  // PROPERTIES

  // API REQUESTS
  const [loginRequest, { isError, error }] = useLoginMutation();

  // LI;BRARY CONSTANTS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  // const [emailError, setEmailError] = useState("");
  // const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // LIFE CYCLE
  useEffect(() => {
    if (isError) {
      const message = error?.["data"]?.details?.[0];
      if (message?.includes("Wrong credentials!")) {
        setErrorMessage("Parola introdusă este greșită");
      } else if (message?.includes("User not found!")) {
        setErrorMessage("Utilizator inexistent");
      } else if (message?.includes("User is inactive!")) {
        setErrorMessage("Utilizatorul este inactiv");
      } else {
        setErrorMessage("A apărut o eroare. Vă rugăm încercați mai târziu!");
      }
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    // eslint-disable-next-line
  }, [isError]);

  // EVENT HANDLERS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // if (name === "username") {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   const isValid = emailRegex.test(value);
    //   setIsEmailValid(isValid);
    //   setEmailError(isValid ? "" : "Format invalid al adresei de email");
    // }
    setLoginDetails((prevLoginDetails) => ({
      ...prevLoginDetails,
      [name]: value,
    }));
  };

  const handleRecoverPassword = () => {
    navigate(`/forgot-password`);
  };
  const handleConnect = () => {
    loginRequest({
      username: loginDetails.username,
      password: loginDetails.password,
    }).then((res) => {
      if (res?.["data"]?.token) {
        dispatch(handleSetToken(res?.["data"]?.token));

        navigate("/");
      }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleConnect();
    }
  };

  return (
    <styled.LoginPageContainer>
      <styled.LoginContentContainer>
        <styled.InfoWrapper>
          <styled.Title>
            <CustomText type={"H1"} text={"Login"} />
          </styled.Title>
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="primary"
              placeholder="Email"
              name="username"
              value={loginDetails.username}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </styled.InputCustomWrapper>
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="primary"
              placeholder="Parola"
              name="password"
              type="password"
              value={loginDetails.password}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </styled.InputCustomWrapper>

          {errorMessage && <CustomText type="H8" text={errorMessage} />}
          {/*{!isEmailValid && (*/}
          {/*  <styled.ErrorMsgText>*/}
          {/*    <CustomText type="H8" text={emailError} />*/}
          {/*  </styled.ErrorMsgText>*/}
          {/*)}*/}
          <styled.CustomButtonWrapper>
            <CustomButton
              appearance="secondary"
              text="Am uitat parola!"
              handleClick={handleRecoverPassword}
            />
          </styled.CustomButtonWrapper>
          <styled.CustomButtonWrapper>
            <CustomButton
              appearance="primary"
              text="Conectează-te"
              handleClick={handleConnect}
            />
          </styled.CustomButtonWrapper>
        </styled.InfoWrapper>
      </styled.LoginContentContainer>
      <styled.LogoContentContainer>
        <styled.LogoContainer>
          <styled.LogoWrapper>
            <Logo />
          </styled.LogoWrapper>
        </styled.LogoContainer>
      </styled.LogoContentContainer>
      {/* <button onClick={handleAddToken}>Add Token</button> */}
    </styled.LoginPageContainer>
  );
};

export default LoginPage;
