// LIBRARIES
import styled from "styled-components";

// MISC
import { COLORS, SIZE } from "constants/variables";

export const UnauthorizedPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.appWhite};
`;

export const LoginContentContainer = styled.div`
  background-color: ${COLORS.appWhite};
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  padding: ${SIZE.max60};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.appLightGrey};
`;
export const InputCustomWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: ${SIZE.max550};
  margin-bottom: ${SIZE.max32};
  display: flex;
  justify-content: center;
`;
export const CustomButtonWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  max-width: ${SIZE.max550};
  margin-bottom: ${SIZE.max32};
`;

export const Title = styled.div`
  min-width: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${SIZE.max32};
`;
