import React, { useRef, useState } from "react";

// ASSETS

// STYLES
import * as styled from "./SideMenuStatusStyled";

// LIBRARIES
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { v4 as uuidv4 } from "uuid";

// MISC
import {
  newDocumentDataSelector,
  rightSideMenuDataSelector,
  templateDataSelector,
} from "slices/TemplateSlice/templateSliceSelectors";
import { COLORS, QUESTION_TYPES } from "constants/variables";
import { renderData } from "helpers/globalFunc";
import { handleUpdateTemplateData } from "slices/TemplateSlice/templateSlice";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomRadioButton from "components/Atoms/CustomRadioButton/CustomRadioButton";
import { useGetAllFormCategoriesQuery } from "api/adminApi";

// CONFIGURATION
const SideMenuStatus = () => {
  // PROPERTIES

  // API REQUESTS
  const { data: formCategoriesData } = useGetAllFormCategoriesQuery();

  const localListOfCategories = formCategoriesData?.map((category) => {
    return {
      key: category?.id,
      value: category?.name,
    };
  });

  // LIBRARY CONSTANTS
  const dispatch = useAppDispatch();
  const templateDateSelector = useAppSelector(templateDataSelector);
  const rightSideMenuData = useAppSelector(rightSideMenuDataSelector);
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);

  // STATE CONSTANTS
  const sectionsContainerRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [localSelectedSectionId, setLocalSelectedSectionId] = useState("");
  const [localSelectedQuestionId, setLocalSelectedQuestionId] = useState("");
  const [modalType, setModalType] = useState("details"); //details, add, deleteSection, editQuestionType

  const currentPage =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [modalDetails, setModalDetails] = useState({
    documentName: templateDateSelector["title"],
    documentTypeId: templateDateSelector["templateTypeId"],
    documentTypeValue: templateDateSelector["templateType"],
    selectionType: "",
  });

  // LIFE CYCLE

  // EVENT HANDLERS
  const formDataArr = [
    {
      type: "input",
      name: "documentName",
      placeholder: "Titlu sablon",
    },
    {
      type: "dropdown",
      name: "documentTypeId",
      placeholder: "Tip document",
      options: localListOfCategories,
    },
  ];

  const handleOpenModal = (type, question?: any) => {
    setOpenModal(true);
    setModalType(type);

    if (type === "editQuestionType") {
      setModalDetails({
        ...modalDetails,
        selectionType: question?.type,
      });
    }
  };
  const handleClose = () => {
    setOpenModal(false);
    setModalDetails({
      ...modalDetails,
      selectionType: "",
    });
  };
  const handleModalChange = (name: string, value: string) => {
    setModalDetails({
      ...modalDetails,
      [name]: value,
    });

    if (name === "documentTypeId") {
      const localValue = localListOfCategories.find(
        (item) => item.key === value
      );

      setModalDetails({
        ...modalDetails,
        [name]: value,
        documentTypeValue: localValue.value,
      });
    }
  };

  const scrollToBottom = () => {
    const container = sectionsContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const handleEditTemplateDetails = () => {
    if (modalType === "details") {
      dispatch(
        handleUpdateTemplateData({
          ...templateDateSelector,
          templateType: modalDetails.documentTypeValue,
          templateTypeId: modalDetails.documentTypeId,
          title: modalDetails.documentName,
          hasUnsavedChanges: true,
        })
      );
    } else if (modalType === "add") {
      if (modalDetails.selectionType === QUESTION_TYPES.NEW_SECTION) {
        let localTemplateData = { ...templateDateSelector };

        const sectionID = uuidv4();

        localTemplateData = {
          ...localTemplateData,
          currentSectionId: sectionID,
          currentQuestionId: "",
          hasUnsavedChanges: true,
          sections: [
            ...localTemplateData.sections,
            {
              sectionId: sectionID,
              title: "",
              questions: [],
            },
          ],
        };

        dispatch(handleUpdateTemplateData(localTemplateData));
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      } else {
        let localTemplateData = { ...templateDateSelector };

        const questionID = uuidv4();
        const sectionID = uuidv4();

        let sectionIndex;

        if (
          localTemplateData.currentSectionId &&
          localTemplateData.sections.length > 0
        ) {
          sectionIndex = localTemplateData.sections.findIndex(
            (item) => item.sectionId === localTemplateData.currentSectionId
          );
        } else if (localTemplateData.sections.length === 0) {
          localTemplateData = {
            ...localTemplateData,
            currentQuestionId: questionID,
            currentSectionId: "",
            sections: [
              ...localTemplateData.sections,
              {
                sectionId: sectionID,
                title: "",
                questions: [],
              },
            ],
          };
          sectionIndex = localTemplateData.sections.findIndex(
            (item) => item.sectionId === sectionID
          );
        } else {
          const questionIndex = localTemplateData.sections.findIndex((item) =>
            item.questions.find(
              (question) =>
                question.questionId === localTemplateData.currentQuestionId
            )
          );
          sectionIndex = questionIndex;
        }

        localTemplateData = {
          ...localTemplateData,
          currentQuestionId: questionID,
          currentSectionId: "",
        };

        const localQuestions = [
          ...localTemplateData.sections[sectionIndex].questions,
        ];

        localQuestions.push({
          answers: [""],
          question: "",
          questionId: questionID,
          type: modalDetails.selectionType,
        });

        const updatedSection = { ...localTemplateData.sections[sectionIndex] };
        updatedSection.questions = localQuestions;

        const updatedSections = [...localTemplateData.sections];
        updatedSections[sectionIndex] = updatedSection;

        const updatedTemplateData = {
          ...localTemplateData,
          hasUnsavedChanges: true,
          sections: updatedSections,
        };

        dispatch(handleUpdateTemplateData(updatedTemplateData));
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    } else if (modalType === "editQuestionType") {
      let localTemplateData = { ...templateDateSelector };

      const questionIndex = localTemplateData.sections.findIndex((item) =>
        item.questions.find(
          (question) =>
            question.questionId === localTemplateData.currentQuestionId
        )
      );

      const updatedSection = { ...localTemplateData.sections[questionIndex] };
      updatedSection.questions = updatedSection.questions.map((question) => {
        if (question.questionId === localTemplateData.currentQuestionId) {
          return {
            ...question,
            type: modalDetails.selectionType,
          };
        }
        return question;
      });

      const updatedSections = [...localTemplateData.sections];
      updatedSections[questionIndex] = updatedSection;

      localTemplateData = {
        ...localTemplateData,
        hasUnsavedChanges: true,
        sections: updatedSections,
      };

      dispatch(handleUpdateTemplateData(localTemplateData));
    } else {
      let localTemplateData = { ...templateDateSelector };

      if (modalType === "deleteSection") {
        localTemplateData.sections = localTemplateData.sections.filter(
          (item) => item.sectionId !== localSelectedSectionId
        );

        localTemplateData = {
          ...localTemplateData,
          hasUnsavedChanges: true,
          currentSectionId:
            localTemplateData.sections[localTemplateData.sections.length - 1]
              ?.sectionId,
        };
      } else if (modalType === "deleteQuestion") {
        const sectionIndex = localTemplateData.sections.findIndex(
          (item) => item.sectionId === localSelectedSectionId
        );

        if (sectionIndex !== -1) {
          const updatedSection = {
            ...localTemplateData.sections[sectionIndex],
          };
          updatedSection.questions = updatedSection.questions.filter(
            (item) => item.questionId !== localSelectedQuestionId
          );

          const updatedSections = [...localTemplateData.sections];
          updatedSections[sectionIndex] = updatedSection;

          localTemplateData = {
            ...localTemplateData,
            sections: updatedSections,
            hasUnsavedChanges: true,
            currentSectionId:
              updatedSection.questions.length === 0
                ? updatedSection?.sectionId
                : "",
            currentQuestionId:
              updatedSection.questions[updatedSection.questions.length - 1]
                ?.questionId,
          };
        }
      }

      dispatch(handleUpdateTemplateData(localTemplateData));
    }

    handleClose();
  };

  const handleOnSectionClick = (sectionId) => {
    dispatch(
      handleUpdateTemplateData({
        ...templateDateSelector,
        currentSectionId: sectionId,
        currentQuestionId: "",
      })
    );
  };

  const handleOnQuestionClick = (questionId) => {
    dispatch(
      handleUpdateTemplateData({
        ...templateDateSelector,
        currentQuestionId: questionId,
        currentSectionId: "",
      })
    );
  };

  const handleOnDeleteSectionPress = (sectionId) => {
    setOpenModal(true);
    setModalType("deleteSection");
    setLocalSelectedSectionId(sectionId);
  };

  const handleOnDeleteQuestionPress = (sectionId, questionId) => {
    setOpenModal(true);
    setModalType("deleteQuestion");
    setLocalSelectedSectionId(sectionId);
    setLocalSelectedQuestionId(questionId);
  };

  return (
    <>
      {currentPage === "template" ? (
        <styled.SideMenuStatusContainer>
          <styled.SideMenuFormDataContainer>
            <styled.SideMenuStatusTitleContainer>
              <styled.SideMenuStatusTitle>
                {templateDateSelector?.templateType}
              </styled.SideMenuStatusTitle>
              {templateDateSelector?.canBeEdited && (
                <styled.SideMenuEditIconContainer
                  onClick={() => handleOpenModal("details")}
                >
                  <styled.SideMenuEditIcon />
                </styled.SideMenuEditIconContainer>
              )}
            </styled.SideMenuStatusTitleContainer>

            <styled.SideMenuTemplateNameContainer>
              <CustomText type={"H3"} text={templateDateSelector?.title} />
              <styled.SideMenuTemplateSectionsContainer
                ref={sectionsContainerRef}
              >
                {templateDateSelector?.sections?.map((section, index) => {
                  return (
                    <styled.SectionContainer
                      key={`section-container--${index}`}
                    >
                      <styled.SectionWrapper
                        isSelected={
                          templateDateSelector.currentSectionId ===
                          section.sectionId
                        }
                        onClick={() => handleOnSectionClick(section.sectionId)}
                      >
                        <styled.SectionTitleContainer>
                          <CustomText
                            type={"H6"}
                            text={`${index + 1}. ${section.title}`}
                          />
                        </styled.SectionTitleContainer>

                        {templateDateSelector?.canBeEdited && (
                          <styled.DeleteIconContainer
                            onClick={() =>
                              handleOnDeleteSectionPress(section.sectionId)
                            }
                          >
                            <styled.DeleteIconSvg />
                          </styled.DeleteIconContainer>
                        )}
                      </styled.SectionWrapper>
                      <styled.QuestionContainer>
                        {section?.questions?.map((question, index) => {
                          return (
                            <styled.QuestionWrapper
                              key={`question-container--${index}`}
                              isSelected={
                                templateDateSelector.currentQuestionId ===
                                question.questionId
                              }
                              onClick={() =>
                                handleOnQuestionClick(question.questionId)
                              }
                            >
                              <styled.QuestionTitleContainer>
                                <CustomText
                                  type={"H6"}
                                  text={`${index + 1}. ${question.question}`}
                                />
                              </styled.QuestionTitleContainer>

                              {templateDateSelector?.canBeEdited && (
                                <styled.QuestionButtonsContainer>
                                  <styled.EditIconContainer
                                    onClick={() =>
                                      handleOpenModal(
                                        "editQuestionType",
                                        question
                                      )
                                    }
                                  >
                                    <styled.SideMenuEditIcon />
                                  </styled.EditIconContainer>

                                  <styled.DeleteIconContainer
                                    onClick={() =>
                                      handleOnDeleteQuestionPress(
                                        section.sectionId,
                                        question.questionId
                                      )
                                    }
                                  >
                                    <styled.DeleteIconSvg />
                                  </styled.DeleteIconContainer>
                                </styled.QuestionButtonsContainer>
                              )}
                            </styled.QuestionWrapper>
                          );
                        })}
                      </styled.QuestionContainer>
                    </styled.SectionContainer>
                  );
                })}
              </styled.SideMenuTemplateSectionsContainer>
            </styled.SideMenuTemplateNameContainer>
          </styled.SideMenuFormDataContainer>

          {templateDateSelector?.canBeEdited && (
            <styled.SideMenuAddButtonContainer>
              <CustomButton
                appearance={"primary"}
                text={"Adauga"}
                handleClick={() => handleOpenModal("add")}
              />
            </styled.SideMenuAddButtonContainer>
          )}
        </styled.SideMenuStatusContainer>
      ) : (
        <styled.SideMenuStatusContainer>
          <styled.SideMenuStatusTitleContainer>
            <styled.SideMenuStatusTitle>
              {rightSideMenuData?.title}
            </styled.SideMenuStatusTitle>
          </styled.SideMenuStatusTitleContainer>
          <styled.SideMenuStatusListContainer>
            {newDocumentSelector?.sections?.map((item, index) => {
              const sectionProgress =
                (item.completed / item.questions.length) * 100;

              return (
                <styled.SideMenuStatusCardWrapper
                  key={`status-card-wrapper--${index}`}
                >
                  <styled.SideMenuStatusCardTextWrapper>
                    <CustomText type={"H6"} text={item?.title} />
                  </styled.SideMenuStatusCardTextWrapper>
                  <styled.ProgressBarContainer>
                    <styled.CircularProgressComp
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[
                            theme.palette.mode === "light" ? 400 : 800
                          ],
                      }}
                      variant="determinate"
                      size={56}
                      thickness={2.5}
                      value={100}
                    />
                    <styled.CircularProgressComp
                      variant="determinate"
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? COLORS.appBlue
                            : COLORS.appDarkGrey,
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                      }}
                      size={56}
                      thickness={2.5}
                      value={sectionProgress}
                    />
                    <styled.ProgressBarWrapper>
                      <styled.ProgressBarText>
                        {`${item.completed}/${item?.questions?.length}`}
                      </styled.ProgressBarText>
                    </styled.ProgressBarWrapper>
                  </styled.ProgressBarContainer>
                </styled.SideMenuStatusCardWrapper>
              );
            })}
          </styled.SideMenuStatusListContainer>
        </styled.SideMenuStatusContainer>
      )}

      <CustomModal handleClose={handleClose} open={openModal}>
        <styled.ModalWrapper>
          <styled.ModalTitleContainer>
            <CustomText
              type="H6"
              text={
                modalType === "details"
                  ? "Modifica Sablon"
                  : modalType === "add"
                  ? "Alege"
                  : "ATENTIE!"
              }
            />
          </styled.ModalTitleContainer>
          <styled.ModalContent>
            {modalType === "details" &&
              formDataArr.map((item) => (
                <styled.InputCustomWrapper key={item.name}>
                  {renderData(item, modalDetails, handleModalChange)}
                </styled.InputCustomWrapper>
              ))}
            {(modalType === "add" || modalType === "editQuestionType") && (
              <styled.CheckboxListContainer>
                {modalType === "add" && (
                  <CustomRadioButton
                    appearance="secondary"
                    options={[QUESTION_TYPES.NEW_SECTION]}
                    onClick={(event) =>
                      handleModalChange("selectionType", event)
                    }
                    selectedOption={modalDetails["selectionType"]}
                  />
                )}
                <styled.BoundedLineContainer>
                  <styled.BoundedText>tip de răspuns</styled.BoundedText>
                  <styled.BoundedLine />
                </styled.BoundedLineContainer>
                <CustomRadioButton
                  appearance="secondary"
                  options={[
                    QUESTION_TYPES.ONLY_RADIO_BUTTON,
                    QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS,
                    QUESTION_TYPES.ONLY_SHORT_ANSWER,
                    QUESTION_TYPES.ONLY_LONG_ANSWER,
                    QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER,
                    QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER,
                  ]}
                  onClick={(event) => handleModalChange("selectionType", event)}
                  selectedOption={modalDetails["selectionType"]}
                />
              </styled.CheckboxListContainer>
            )}

            {modalType === "deleteSection" && (
              <>
                <CustomText
                  type="H4"
                  text="Sunteti sigur ca doriti sa stergeti aceasta sectiune?"
                />
              </>
            )}
            {modalType === "deleteQuestion" && (
              <>
                <CustomText
                  type="H4"
                  text="Sunteti sigur ca doriti sa stergeti aceasta intrebare?"
                />
              </>
            )}
          </styled.ModalContent>
          <styled.ButtonsContainer>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={handleClose}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance={
                  modalType === "add" && !modalDetails.selectionType
                    ? "smallestDisabled"
                    : "smallestBlue"
                }
                text={
                  modalType === "details"
                    ? "Modifica Sablon"
                    : modalType === "add"
                    ? "Adauga"
                    : modalType === "editQuestionType"
                    ? "Modifica"
                    : "Sterge"
                }
                handleClick={
                  modalType === "add" && !modalDetails.selectionType
                    ? () => {}
                    : handleEditTemplateDetails
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsContainer>
        </styled.ModalWrapper>
      </CustomModal>
    </>
  );
};

export default SideMenuStatus;
