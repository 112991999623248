import React, { useEffect, useState } from "react";

// ASSETS
import { ReactComponent as Logo } from "assets/icons/Logo.svg";

// STYLES
import * as styled from "./PasswordSettingPageStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// MISC

// COMPONENTS
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomInput from "components/Atoms/CustomInput/CustomInput";
import CustomText from "components/Atoms/CustomText/CustomText";
import { useCreatePasswordMutation } from "api/sessionApi";

// CONFIGURATION

const PasswordSetting = () => {
  // PROPERTIES

  // API REQUESTS
  const userIdFromUrl =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const [createPasswordRequest] = useCreatePasswordMutation();

  // LIBRARY CONSTANTS
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({
    lowercase: false,
    uppercase: false,
    digit: false,
    length: false,
  });

  // LIFE CYCLE
  useEffect(() => {
    setTimeout(() => {
      setErrorMsg("");
    }, 5000);
  }, [errorMsg]);

  // EVENT HANDLERS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordDetails((prevPasswordDetails) => ({
      ...prevPasswordDetails,
      [name]: value,
    }));
    const isValidLowercase = /[a-z]/.test(value);
    const isValidUppercase = /[A-Z]/.test(value);
    const isValidDigit = /\d/.test(value);
    const isValidLength = value.length >= 6;
    setPasswordErrors({
      lowercase: !isValidLowercase,
      uppercase: !isValidUppercase,
      digit: !isValidDigit,
      length: !isValidLength,
    });
  };

  const handleConnect = async () => {
    const errors = [];
    if (passwordErrors.lowercase) {
      errors.push("Parola trebuie să conțină cel puțin o literă mică.");
    }
    if (passwordErrors.uppercase) {
      errors.push("Parola trebuie să conțină cel puțin o literă mare.");
    }
    if (passwordErrors.digit) {
      errors.push("Parola trebuie să conțină cel puțin o cifră.");
    }
    if (passwordErrors.length) {
      errors.push("Parola trebuie să conțină cel puțin 6 caractere.");
    }

    if (errors.length > 0) {
      setErrorMsg(errors.join(" "));
      return;
    }
    if (passwordDetails.password !== passwordDetails.confirmPassword) {
      setErrorMsg("Parolele nu se potrivesc");
      return;
    } else {
      await createPasswordRequest({
        userId: parseInt(userIdFromUrl),
        password: passwordDetails.password,
      }).then(() => {
        navigate("/login");
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleConnect();
    }
  };

  return (
    <styled.PasswordSettingContainer>
      <styled.PasswordContentContainer>
        <styled.InfoWrapper>
          <styled.Title>
            <CustomText type={"H1"} text={"Setare parolă"} />
          </styled.Title>
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="primary"
              placeholder="Parola"
              name="password"
              type="password"
              value={passwordDetails.password}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </styled.InputCustomWrapper>
          <styled.InputCustomWrapper>
            <CustomInput
              appearance="primary"
              placeholder="Confirmă parola"
              name="confirmPassword"
              type="password"
              value={passwordDetails.confirmPassword}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          </styled.InputCustomWrapper>
          <styled.ErrorMsgText>
            <CustomText type="H8" text={errorMsg} />
          </styled.ErrorMsgText>
          <styled.CustomButtonWrapper>
            <CustomButton
              appearance="primary"
              text="Setează parolă"
              handleClick={handleConnect}
            />
          </styled.CustomButtonWrapper>
        </styled.InfoWrapper>
      </styled.PasswordContentContainer>
      <styled.LogoContentContainer>
        <styled.LogoContainer>
          <styled.LogoWrapper>
            <Logo />
          </styled.LogoWrapper>
        </styled.LogoContainer>
      </styled.LogoContentContainer>
      {/* <button onClick={handleAddToken}>Add Token</button> */}
    </styled.PasswordSettingContainer>
  );
};

export default PasswordSetting;
