import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomCheckedBoxtStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomCheckedBoxModel";

// COMPONENTS

// CONFIGURATION

const CustomCheckedBox = (props: IProps) => {
  // PROPERTIES
  const {
    appearance = "simple",
    name = "",
    checked = false,
    onChange = (event) => {},
    label = "",
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.CheckBoxWrapper onChange={onChange}>
      <styled.LabelWrapper>
        <styled.Input name={name} type="checkbox" checked={checked} />
        <styled.Text appearance={appearance} checked={checked}>
          {label}
        </styled.Text>
      </styled.LabelWrapper>
    </styled.CheckBoxWrapper>
  );
};

export default CustomCheckedBox;
