import { TAB_VALUE } from "constants/variables";

export const tabListData = [
  {
    title: "Documente",
    value: TAB_VALUE.DOCUMENTS,
  },
  {
    title: "Activitate",
    filesNumber: 2,
    value: TAB_VALUE.ACTIVITIES,
  },
];

export const beneficiaryListSort = [
  {
    name: "Ordonare alfabetică",
    sortField: "fullName",
    sortOrder: "",
  },
  {
    name: "Ordonare cronologică",
    sortField: "createdAt",
    sortOrder: "",
  },
];
