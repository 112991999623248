// LIBRARIES
import styled from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const SideBeneficiaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SIZE.max24};
  gap: ${SIZE.max24};
  width: 260px;
  border-radius: ${BORDER_RADIUS.cardRadius};
  background-color: ${COLORS.appWhite};
  height: 100%;
  overflow-y: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-bottom: 2px solid ${COLORS.appLightGrey};
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max24};
`;
export const CategoryWrapper = styled.div`
  border-bottom: 2px solid ${COLORS.appLightGrey};

  display: flex;
  flex-direction: column;

  .MuiSelect-select {
    padding: 0 5px !important;

    font-size: ${FONT_SIZE.max16} !important;
    font-weight: ${FONT_WEIGHT.semiBold} !important;
    color: ${COLORS.appBlack} !important;
  }

  input {
    font-size: ${FONT_SIZE.max16};
    font-weight: ${FONT_WEIGHT.semiBold};
    color: ${COLORS.appBlack};
  }
`;
export const TitleCategory = styled.div`
  font-size: ${FONT_SIZE.max14};
  font-weight: ${FONT_WEIGHT.medium};
  color: ${COLORS.appDarkGrey};
  white-space: nowrap;
`;
export const InfoCategory = styled.div`
  font-size: ${FONT_SIZE.max16};
  font-weight: ${FONT_WEIGHT.semiBold};
  color: ${COLORS.appBlack};
  height: 37px;
  padding: 6px 0px;
`;
