import React, { useState } from "react";

// ASSETS
import { ReactComponent as CloneIcon } from "assets/icons/CloneIcon.svg";

// STYLES
import * as styled from "./TemplatesListCardStyled";
import "react-loading-skeleton/dist/skeleton.css";

// LIBRARIES
import Skeleton from "react-loading-skeleton";

// MISC
import { IProps } from "./TemplatesListCardModel";

// REDUX

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";

// CONFIGURATION
const TemplatesListCard = (props: IProps) => {
  // PROPERTIES
  const {
    type = "",
    date = "",
    isFetching = false,
    handleCloneForm = () => {},
    formDetails = {},
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS
  const currentPage = window.location.pathname.split("/")[1];
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleCloneButtonClick = (event) => {
    handleCloneForm(formDetails);
    event.stopPropagation();
  };

  return (
    <styled.QuestionnaireCardContainer>
      {isHovering && (
        <styled.TextOnHover>
          <CustomText type="H6" text="Clonează șablonul" />
        </styled.TextOnHover>
      )}
      {currentPage === "templates-list" && (
        <styled.TemplateListCardCloneButton
          onClick={handleCloneButtonClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <CloneIcon />
        </styled.TemplateListCardCloneButton>
      )}
      <styled.TitleContainer>
        {isFetching ? <Skeleton /> : <CustomText type={"H3"} text={type} />}
      </styled.TitleContainer>
      <styled.DateContainer>
        {isFetching ? (
          <Skeleton count={2} />
        ) : (
          <>
            {" "}
            <styled.Span>Versiune din</styled.Span>
            <styled.Date>{date}</styled.Date>
          </>
        )}
      </styled.DateContainer>
    </styled.QuestionnaireCardContainer>
  );
};
export default TemplatesListCard;
