// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";
import { IStyled } from "components/BeneficiaryCard/BeneficiaryCardModel";

export const BeneficiaryCardContainer = styled.div<IStyled>(
  ({ appearance }) => css`
    min-width: 150px;
    width: 100%;
    max-width: 200px;
    height: ${SIZE.max280};
    background-color: ${COLORS.appWhite};
    padding: ${SIZE.max24};
    border-radius: ${BORDER_RADIUS.cardRadius};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: 0.5s;
    }
    ${appearance === "disable" &&
    css`
      background-color: ${COLORS.appDarkGrey};
    `}
  `
);

export const UserNameContainer = styled.div<IStyled>(
  ({ appearance }) => css`
    display: inline;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    color: ${COLORS.appBlack};
  `
);

export const UserNameText = styled.span<IStyled>(
  ({ appearance }) => css`
    font-size: ${FONT_SIZE.max20};
    font-weight: ${FONT_WEIGHT.bold};
    color: ${COLORS.appBlack};
    font-family: ${FONT_FAMILY.poppins};
    text-transform: capitalize;
    ${appearance === "disable" &&
    css`
      color: ${COLORS.appWhite}!important;
    `}
  `
);

export const SignUpContainer = styled.div<IStyled>(
  ({ appearance }) => css`
    display: flex;
    flex-direction: column;
    margin-block: ${SIZE.max16};
    ${appearance === "disable" &&
    css`
      margin-block: 0;
    `}
    div:first-child {
      line-height: 14px;
    }
    div:first-child > span {
      font-weight: ${FONT_WEIGHT.medium};
      color: ${COLORS.appDarkGrey};
      ${appearance === "disable" &&
      css`
        color: ${COLORS.appWhite};
      `}
    }
    div:last-child {
      line-height: 14px;
    }
    div:last-child > span {
      color: ${COLORS.appBlack};
      ${appearance === "disable" &&
      css`
        color: ${COLORS.appWhite};
      `}
    }
  `
);
export const BottomContainer = styled.div``;

export const ServicesContainer = styled.div<IStyled>`
  display: flex;
  align-items: baseline;
  gap: 5px;
  div:first-child > span {
    font-weight: ${FONT_WEIGHT.medium};
  }
  div:last-child {
    color: ${COLORS.appBlue}!important;
    font-size: ${FONT_SIZE.max12};
    font-weight: ${FONT_WEIGHT.bold};
  }
`;
