import React, { useState } from "react";

// ASSETS

// STYLES
import * as styled from "./CustomAutocompleteStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomAutocompleteModel";

// REDUX

// COMPONENTS

// CONFIGURATION

const CustomAutocomplete = (props: IProps) => {
  // PROPERTIES
  const { parseData = [], label = "", onChange, value, placeholder } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    if (inputValue === "") {
      setShowSuggestions(false);
    } else {
      const filteredSuggestions = parseData.filter((item) =>
        item?.user?.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    }
  };

  const handleSelect = (selectedValue) => {
    setInputValue(selectedValue.user);
    setShowSuggestions(false);
    onChange(selectedValue);
  };

  const renderSuggestions = () => {
    if (showSuggestions && suggestions.length > 0) {
      return (
        <styled.RenderSuggestionsContainer>
          <styled.RenderSuggestions>
            {suggestions.map((item, index) => (
              <styled.Item key={index} onClick={() => handleSelect(item)}>
                {item.user}
              </styled.Item>
            ))}
          </styled.RenderSuggestions>
        </styled.RenderSuggestionsContainer>
      );
    }
    return null;
  };

  return (
    <styled.AutocompleteContainer>
      {label && <label>{label}</label>}
      <styled.Input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {renderSuggestions()}
    </styled.AutocompleteContainer>
  );
};
export default CustomAutocomplete;
