import React, { useState } from "react";

// ASSETS
import { ReactComponent as CheckBoxChecked } from "assets/icons/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/CkeckBokUnchecked.svg";

// STYLES
import * as styled from "./ModelFlowStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { newDocumentDataSelector } from "slices/TemplateSlice/templateSliceSelectors";
import { handleUpdateNewDocumentData } from "slices/TemplateSlice/templateSlice";
import CustomMultipleRadioButton from "components/Atoms/CustomMultipleRadioButton/CustomMultipleRadioButton";
import CustomModal from "components/Atoms/CustomModal/CustomModal";
import { renderData } from "helpers/globalFunc";
import {
  useCompleteBeneficiaryFormMutation,
  useDeleteBeneficiaryFormMutation,
  useEditBeneficiaryFormMutation,
} from "api/newDocumentApi";
import useIsRequestLoading from "hooks/useRequestLoading";
import { QUESTION_TYPES } from "constants/variables";

// MISC

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomIconButton from "components/Atoms/CustomIconButton/CustomIconButton";
import CustomButton from "components/Atoms/CustomButton/CustomButton";
import CustomHeader from "components/CustomHeader/CustomHeader";
import CustomTextArea from "components/Atoms/CustomTextArea/CustomTextArea";

// CONFIGURATION

const ModelFlow = () => {
  // PROPERTIES

  // API REQUESTS
  const [
    completeBeneficiaryFormRequest,
    { isLoading: isCompleteBeneficiaryFormLoading },
  ] = useCompleteBeneficiaryFormMutation();
  const [
    editBeneficiaryFormRequest,
    { isLoading: isEditBeneficiaryFormLoading },
  ] = useEditBeneficiaryFormMutation();
  const [
    deleteBeneficiaryFormRequest,
    { isLoading: isDeleteBeneficiaryFormLoading },
  ] = useDeleteBeneficiaryFormMutation();

  const pageLoading =
    isCompleteBeneficiaryFormLoading ||
    isEditBeneficiaryFormLoading ||
    isDeleteBeneficiaryFormLoading;

  useIsRequestLoading(pageLoading);

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const newDocumentSelector = useAppSelector(newDocumentDataSelector);

  // STATE CONSTANTS
  const [openModal, setOpenModal] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [modalDetails, setModalDetails] = useState({
    duration: "",
  });

  // LIFE CYCLE

  // EVENT HANDLERS
  const displayInputTypes = (item: any, section: any) => {
    if (currentSectionId !== section?.sectionId) {
      setCurrentSectionId(section?.sectionId);
    }

    switch (item.type) {
      case QUESTION_TYPES.ONLY_RADIO_BUTTON:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.SelectComponentContainer>
              <CustomMultipleRadioButton
                options={item?.options}
                onClick={(event) =>
                  handleElemsChange(item?.name, event, item, "radio")
                }
                selectedOption={item?.selectedOptions}
              />
            </styled.SelectComponentContainer>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.SelectComponentContainer>
              <CustomMultipleRadioButton
                options={item?.options}
                onClick={(event) =>
                  handleElemsChange(item?.name, event, item, "checkbox")
                }
                selectedOption={item?.selectedOptions}
              />
            </styled.SelectComponentContainer>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <CustomTextArea
              placeholder={""}
              wordLength={100}
              type={"short"}
              name={item?.name}
              onChange={(event) =>
                handleElemsChange(
                  event.target.name,
                  event.target.value,
                  item,
                  "text"
                )
              }
              value={item?.completedAnswer}
            />
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.SelectComponentContainer>
              <CustomMultipleRadioButton
                options={item?.options}
                onClick={(event) =>
                  handleElemsChange(item?.name, event, item, "radio")
                }
                selectedOption={item?.selectedOptions}
              />
            </styled.SelectComponentContainer>
            <styled.ComponentContainer>
              <CustomTextArea
                placeholder={""}
                wordLength={100}
                type={"short"}
                name={item?.name}
                onChange={(event) =>
                  handleElemsChange(
                    event.target.name,
                    event.target.value,
                    item,
                    "text"
                  )
                }
                value={item?.completedAnswer}
              />
            </styled.ComponentContainer>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.SelectComponentContainer>
              <CustomMultipleRadioButton
                options={item?.options}
                onClick={(event) =>
                  handleElemsChange(item?.name, event, item, "checkbox")
                }
                selectedOption={item?.selectedOptions}
              />
            </styled.SelectComponentContainer>
            <styled.ComponentContainer>
              <CustomTextArea
                placeholder={""}
                wordLength={100}
                type={"short"}
                name={item?.name}
                onChange={(event) =>
                  handleElemsChange(
                    event.target.name,
                    event.target.value,
                    item,
                    "text"
                  )
                }
                value={item?.completedAnswer}
              />
            </styled.ComponentContainer>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_LONG_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.ComponentContainer>
              <CustomTextArea
                placeholder={""}
                wordLength={200}
                type={"long"}
                name={item?.name}
                onChange={(event) =>
                  handleElemsChange(
                    event.target.name,
                    event.target.value,
                    item,
                    "text"
                  )
                }
                value={item?.completedAnswer}
              />
            </styled.ComponentContainer>
          </styled.ComponentWrapper>
        );

      default:
        return "A aparut o eroare";
    }
  };

  const displayViewModeInputTypes = (item: any) => {
    switch (item.type) {
      case QUESTION_TYPES.ONLY_RADIO_BUTTON:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />

            {item?.options.map((option) => {
              if (item?.selectedOptions.includes(option.key)) {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxChecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              } else {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxUnchecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              }
            })}
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />

            {item?.options.map((option) => {
              if (item?.selectedOptions.includes(option.key)) {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxChecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              } else {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxUnchecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              }
            })}
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            <styled.ComponentAnswersWrapper>
              <CustomText type="H4" text={`${item?.completedAnswer}`} />
            </styled.ComponentAnswersWrapper>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            {item?.options.map((option) => {
              if (item?.selectedOptions.includes(option.key)) {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxChecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              } else {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxUnchecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              }
            })}
            <styled.ComponentAnswersWrapper>
              <CustomText type="H4" text={`${item?.completedAnswer}`} />
            </styled.ComponentAnswersWrapper>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />
            {item?.options.map((option) => {
              if (item?.selectedOptions.includes(option.key)) {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxChecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              } else {
                return (
                  <styled.ComponentAnswersWrapper>
                    <CheckBoxUnchecked />
                    <CustomText
                      type="H4"
                      text={`${option.value}`}
                      key={option.key}
                    />
                  </styled.ComponentAnswersWrapper>
                );
              }
            })}
            <styled.ComponentAnswersWrapper>
              <CustomText type="H4" text={`${item?.completedAnswer}`} />
            </styled.ComponentAnswersWrapper>
          </styled.ComponentWrapper>
        );
      case QUESTION_TYPES.ONLY_LONG_ANSWER:
        return (
          <styled.ComponentWrapper
            key={`component-wrapper--${item.questionId}`}
            isOnViewMode={newDocumentSelector?.displayMode === "viewMode"}
          >
            <CustomText type="H3" text={`${item.step + 1}. ${item.question}`} />

            <styled.ComponentAnswersWrapper>
              <CustomText type="H4" text={`${item?.completedAnswer}`} />
            </styled.ComponentAnswersWrapper>
          </styled.ComponentWrapper>
        );

      default:
        return "A aparut o eroare";
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCurrentStep = (step: string) => {
    let localNewDocumentData = { ...newDocumentSelector };

    if (
      step === "next" &&
      localNewDocumentData?.currentStep === totalQuestions - 1
    ) {
      setOpenModal(true);
      return;
    }

    localNewDocumentData.currentStep =
      step === "next"
        ? Math.min(localNewDocumentData.currentStep + 1, totalQuestions - 1)
        : Math.max(localNewDocumentData.currentStep - 1, 0);

    const currentSectionId = localNewDocumentData.sections.find((section) =>
      section.questions.some((q) => q.step === localNewDocumentData.currentStep)
    )?.sectionId;

    setCurrentSectionId(currentSectionId);

    const localSections = localNewDocumentData.sections.map((section) => {
      if (section.sectionId === currentSectionId) {
        const completed =
          step === "next"
            ? Math.min(section.completed + 1, section.questions.length)
            : Math.max(section.completed - 1, 0);

        return {
          ...section,
          completed,
        };
      }
      return section;
    });

    dispatch(
      handleUpdateNewDocumentData({
        ...localNewDocumentData,
        sections: localSections,
      })
    );
  };
  const handleElemsChange = (
    name: string,
    value: string | boolean,
    item: any,
    type: string
  ) => {
    if (newDocumentSelector?.displayMode === "viewMode") return;

    let localNewDocumentData = { ...newDocumentSelector };

    if (type === "text") {
      localNewDocumentData = {
        ...localNewDocumentData,
        sections: localNewDocumentData?.sections?.map((section) => {
          return {
            ...section,
            questions: section?.questions?.map((question) => {
              if (question?.name === name) {
                return {
                  ...question,
                  completedAnswer: value,
                };
              }
              return question;
            }),
          };
        }),
      };
    } else if (type === "radio") {
      localNewDocumentData = {
        ...localNewDocumentData,
        sections: localNewDocumentData?.sections?.map((section) => {
          return {
            ...section,
            questions: section?.questions?.map((question) => {
              if (question?.name === name) {
                return {
                  ...question,
                  selectedOptions: [value],
                };
              }
              return question;
            }),
          };
        }),
      };
    } else if (type === "checkbox") {
      localNewDocumentData = {
        ...localNewDocumentData,
        sections: localNewDocumentData?.sections?.map((section) => {
          return {
            ...section,
            questions: section?.questions?.map((question) => {
              if (question?.name === name) {
                const isValueSelected =
                  question.selectedOptions.includes(value);

                let newSelectedOptions;

                if (isValueSelected) {
                  newSelectedOptions = question.selectedOptions.filter(
                    (checkboxId) => checkboxId !== value
                  );
                } else {
                  newSelectedOptions = [...question.selectedOptions, value];
                }

                return {
                  ...question,
                  selectedOptions: newSelectedOptions,
                };
              }
              return question;
            }),
          };
        }),
      };
    }

    dispatch(handleUpdateNewDocumentData(localNewDocumentData));
  };

  const totalQuestions = newDocumentSelector?.sections?.reduce(
    (count, section) => {
      return count + (section.questions ? section.questions.length : 0);
    },
    0
  );

  const handleModalChange = (name: string, value: string) => {
    if (value !== "" && isNaN(parseInt(value))) return;
    if (value === "0") return;

    setModalDetails({
      ...modalDetails,
      [name]: value,
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalDetails({
      duration: "",
    });
  };

  const formDataArr = [
    {
      type: "input",
      name: "duration",
      placeholder: "minute",
    },
  ];

  const handleCompleteForm = async () => {
    let localNewDocumentData = { ...newDocumentSelector };

    const transformedData = [];

    if (
      newDocumentSelector?.displayMode === "createMode" &&
      modalDetails?.duration === ""
    )
      return;

    localNewDocumentData?.sections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        transformedData.push({
          answer: question?.completedAnswer || "",
          questionId: question?.questionId,
          selectedAnswers: question?.selectedOptions,
        });
      });
    });

    if (newDocumentSelector?.displayMode === "createMode") {
      await completeBeneficiaryFormRequest({
        formId: localNewDocumentData?.formId,
        duration: parseInt(modalDetails?.duration),
        beneficiaryId: localNewDocumentData?.currentBeneficiaryData?.id,
        bodyData: transformedData,
      }).then(() => {
        setOpenModal(false);
        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            displayMode: "viewMode",
            hasUnsavedChanges: false,
            currentStep: 0,
            currentFlow: "cnpFlow",
            currentBeneficiaryData: {},
            title: "",
          })
        );

        navigate(-1);
      });
    } else if (newDocumentSelector?.displayMode === "editMode") {
      await editBeneficiaryFormRequest({
        formId: localNewDocumentData?.formId,
        beneficiaryId: localNewDocumentData?.currentBeneficiaryData?.id,
        bodyData: transformedData,
      }).then(() => {
        setOpenModal(false);
        dispatch(
          handleUpdateNewDocumentData({
            ...newDocumentSelector,
            displayMode: "viewMode",
            hasUnsavedChanges: false,
            currentStep: 0,
            currentFlow: "modelFlow",
          })
        );
      });
    }
  };

  const handleDeleteForm = async () => {
    await deleteBeneficiaryFormRequest({
      formId: newDocumentSelector?.formId,
    }).then(() => {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          displayMode: "viewMode",
        })
      );
      navigate(-1);
    });
  };

  const handleSetDisplayMode = (mode: string) => {
    if (mode === "viewMode") {
      setOpenModal(true);
    } else {
      dispatch(
        handleUpdateNewDocumentData({
          ...newDocumentSelector,
          displayMode: mode,
        })
      );

      handleCompleteForm();
    }
  };

  const isCurrentStepEmpty = () => {
    const localNewDocumentData = { ...newDocumentSelector };

    const currentStepData = localNewDocumentData?.sections
      ?.find((section) =>
        section.questions.some(
          (q) => q.step === localNewDocumentData.currentStep
        )
      )
      ?.questions?.find(
        (question) => question.step === localNewDocumentData.currentStep
      );

    if (currentStepData?.type === QUESTION_TYPES.ONLY_RADIO_BUTTON) {
      return currentStepData?.selectedOptions?.length === 0;
    } else if (currentStepData?.type === QUESTION_TYPES.ONLY_CHECKBOX_BUTTONS) {
      return currentStepData?.selectedOptions?.length === 0;
    } else if (currentStepData?.type === QUESTION_TYPES.ONLY_SHORT_ANSWER) {
      return currentStepData?.completedAnswer?.length === 0;
    } else if (
      currentStepData?.type === QUESTION_TYPES.RADIO_BUTTON_AND_SHORT_ANSWER
    ) {
      return (
        currentStepData?.selectedOptions?.length === 0 ||
        currentStepData?.completedAnswer?.length === 0
      );
    } else if (
      currentStepData?.type === QUESTION_TYPES.CHECKBOX_BUTTONS_AND_SHORT_ANSWER
    ) {
      return (
        currentStepData?.selectedOptions?.length === 0 ||
        currentStepData?.completedAnswer?.length === 0
      );
    } else if (currentStepData?.type === QUESTION_TYPES.ONLY_LONG_ANSWER) {
      return currentStepData?.completedAnswer?.length === 0;
    } else {
      return true;
    }
  };

  return (
    <styled.NewDocumentPageContainer>
      <CustomHeader text={"Inapoi la Sablon"} handleGoBack={handleGoBack} />

      <styled.NewDocumentPageContentWrapper>
        <styled.SubtitleContainer>
          <styled.SubtitleText>
            <CustomText type="H2" text={newDocumentSelector?.title} />
          </styled.SubtitleText>

          {newDocumentSelector?.displayMode !== "createMode" && (
            <styled.SubtitleButtonsContainer>
              <CustomText
                type="H4"
                text={`Durata: ${newDocumentSelector?.completionDuration} minute`}
              />

              <CustomButton
                appearance={
                  newDocumentSelector?.displayMode !== "viewMode" &&
                  isCurrentStepEmpty()
                    ? "smallestDisabled"
                    : "smallestBlue"
                }
                text={
                  newDocumentSelector?.displayMode === "viewMode"
                    ? "Editeaza"
                    : "Salveaza"
                }
                handleClick={
                  isCurrentStepEmpty()
                    ? () => {}
                    : () => {
                        handleSetDisplayMode("editMode");
                      }
                }
              />

              {newDocumentSelector?.displayMode === "viewMode" && (
                <CustomButton
                  appearance="smallestBlue"
                  text={"Sterge"}
                  handleClick={() => {
                    handleSetDisplayMode("viewMode");
                  }}
                />
              )}
            </styled.SubtitleButtonsContainer>
          )}
        </styled.SubtitleContainer>
        <styled.ViewModeContentContainer>
          {newDocumentSelector?.sections?.map((section) => {
            return section?.questions?.map((question) => {
              if (newDocumentSelector?.displayMode === "viewMode") {
                return displayViewModeInputTypes(question);
              } else {
                return (
                  newDocumentSelector?.currentStep === question?.step &&
                  displayInputTypes(question, section)
                );
              }
            });
          })}
        </styled.ViewModeContentContainer>
      </styled.NewDocumentPageContentWrapper>
      {newDocumentSelector?.displayMode !== "viewMode" && (
        <styled.StepButtonContainer>
          {newDocumentSelector?.currentStep > 0 && (
            <CustomIconButton
              handleClick={() => handleCurrentStep("back")}
              appearance="backButton"
            />
          )}
          {newDocumentSelector?.displayMode !== "editMode" &&
            newDocumentSelector?.currentStep !== totalQuestions && (
              <styled.NextButtonContainer>
                <CustomButton
                  appearance={isCurrentStepEmpty() ? "disabled" : "primary"}
                  text={
                    newDocumentSelector?.currentStep !== totalQuestions - 1
                      ? "Mergi mai departe"
                      : "Finalizeaza"
                  }
                  handleClick={
                    isCurrentStepEmpty()
                      ? () => {}
                      : () => handleCurrentStep("next")
                  }
                />
              </styled.NextButtonContainer>
            )}

          {newDocumentSelector?.displayMode === "editMode" &&
            newDocumentSelector?.currentStep !== totalQuestions - 1 && (
              <styled.NextButtonContainer>
                <CustomButton
                  appearance={isCurrentStepEmpty() ? "disabled" : "primary"}
                  text={"Mergi mai departe"}
                  handleClick={
                    isCurrentStepEmpty()
                      ? () => {}
                      : () => handleCurrentStep("next")
                  }
                />
              </styled.NextButtonContainer>
            )}
        </styled.StepButtonContainer>
      )}

      <CustomModal handleClose={handleClose} open={openModal}>
        <styled.ModalWrapper>
          <styled.ModalTitleContainer>
            <CustomText
              type="H6"
              text={
                newDocumentSelector?.displayMode === "viewMode"
                  ? "ATENTIE !"
                  : "Inserati durata formularului"
              }
            />
          </styled.ModalTitleContainer>
          <styled.ModalContent>
            {newDocumentSelector?.displayMode !== "viewMode" &&
              formDataArr.map((item) => (
                <styled.InputCustomWrapper key={item.name}>
                  {renderData(item, modalDetails, handleModalChange)}
                </styled.InputCustomWrapper>
              ))}
            {newDocumentSelector?.displayMode === "viewMode" && (
              <CustomText
                type="H4"
                text="Sunteti sigur ca doriti sa stergeti aceast formular?"
              />
            )}
          </styled.ModalContent>
          <styled.ButtonsContainer>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="cancel"
                text="Anuleaza"
                handleClick={handleClose}
              />
            </styled.IndividualButtonWrapper>
            <styled.IndividualButtonWrapper>
              <CustomButton
                appearance="smallestBlue"
                text={
                  newDocumentSelector?.displayMode === "viewMode"
                    ? "Sterge"
                    : "Finalizeaza"
                }
                handleClick={
                  newDocumentSelector?.displayMode === "viewMode"
                    ? handleDeleteForm
                    : handleCompleteForm
                }
              />
            </styled.IndividualButtonWrapper>
          </styled.ButtonsContainer>
        </styled.ModalWrapper>
      </CustomModal>
    </styled.NewDocumentPageContainer>
  );
};

export default ModelFlow;
