// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { SIZE, COLORS, BORDER_RADIUS } from "constants/variables";

export const NewDocumentPageContainer = styled.div`
  width: 100%;
`;

export const NewDocumentPageContentWrapper = styled.div`
  padding: ${SIZE.max24} ${SIZE.max80};
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};
`;

export const SubtitleText = styled.div`
  margin-bottom: 20px;
`;
export const ComponentWrapper = styled.div`
  max-width: ${SIZE.max480};
  @media (max-width: 600px) {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;

  :nth-child(1) {
    margin-bottom: 30px;
  }
`;
export const StepButtonContainer = styled.div`
  padding-left: ${SIZE.max80};
  display: flex;
  gap: 16px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  align-items: stretch;
  max-width: ${SIZE.max480};
`;
export const NextButtonContainer = styled.div<{ isEmpty }>(
  ({ isEmpty }) => css`
    background-color: ${COLORS.appBlue};
    flex: 1;
    border-radius: ${BORDER_RADIUS.cardRadius};
    display: flex;
    align-items: center;
    ${isEmpty === false &&
    css`
      background-color: ${COLORS.appDarkGrey};
    `}
  `
);
