import React from "react";

// ASSETS

// STYLES
import * as styled from "./UnauthorizedPageStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// MISC

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomButton from "components/Atoms/CustomButton/CustomButton";

// CONFIGURATION

const UnauthorizedPage = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const navigate = useNavigate();

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <styled.UnauthorizedPageContainer>
      <styled.LoginContentContainer>
        <styled.InfoWrapper>
          <styled.Title>
            <CustomText type={"H1"} text={"Acces Neautorizat"} />
          </styled.Title>

          <styled.InputCustomWrapper>
            <CustomText
              type={"H4"}
              text={
                "Ne pare rău, dar nu aveți permisiunea de a accesa această pagină. Vă rugăm să vă asigurați că sunteți autentificat și aveți privilegii adecvate pentru a vizualiza acest conținut."
              }
            />
          </styled.InputCustomWrapper>
          <styled.InputCustomWrapper>
            <CustomText type={"H4"} text={"Vă mulțumim pentru înțelegere!"} />
          </styled.InputCustomWrapper>

          <styled.CustomButtonWrapper>
            <CustomButton
              appearance="primary"
              text="Înapoi"
              handleClick={handleGoBack}
            />
          </styled.CustomButtonWrapper>
        </styled.InfoWrapper>
      </styled.LoginContentContainer>
    </styled.UnauthorizedPageContainer>
  );
};

export default UnauthorizedPage;
