import React from "react";

// ASSETS
import { ReactComponent as SimpleBackArrow } from "assets/icons/SimpleBackArrow.svg";

// STYLES
import * as styled from "./OperatorLogsPageStyled";

// LIBRARIES
import { useLocation, useNavigate } from "react-router-dom";
import { handlePagePaginationChange } from "slices/AdminSlice/adminSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { usersLogsPaginationSelector } from "slices/AdminSlice/adminSliceSelectors";
import useTableHeadDetails from "hooks/useTableHeadHook";
import { useGetUsersLogsQuery } from "api/beneficiaryApi";
import useIsRequestLoading from "hooks/useRequestLoading";

// MISC
import { operatorLogHeadValues } from "mocks/adminMock";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomPagination from "components/Atoms/CustomPagination/CustomPagination";
import CustomTable from "components/Atoms/CustomTable/CustomTable";

// CONFIGURATION
const OperatorLogsPage = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useAppDispatch();

  // STATE CONSTANTS

  const [tableHeadDetails, tableHeadCell, handleChangeSortOrder] =
    useTableHeadDetails(operatorLogHeadValues);

  const usersLogsPaginationDetails = useAppSelector(
    usersLogsPaginationSelector
  );

  const { data: userLogsList, isLoading: isUserLogsLoading } =
    useGetUsersLogsQuery({
      page: usersLogsPaginationDetails?.pageNumber - 1,
      size: usersLogsPaginationDetails?.pageSize,
      sortField: tableHeadCell.sortField,
      sortOrder: tableHeadCell.sortOrder,
      userId: state?.["id"],
    });

  const pageLoading = isUserLogsLoading;

  useIsRequestLoading(pageLoading);

  // LIFE CYCLE

  // EVENT HANDLERS
  const handlePageChange = (
    name: React.ChangeEvent<unknown>,
    value: number,
    adminPage: string
  ) => {
    dispatch(
      handlePagePaginationChange({
        value,
        adminPage,
      })
    );
  };

  return (
    <styled.OperatorLogsPageContainer>
      <styled.OperatorsPageHeader onClick={() => navigate(-1)}>
        <SimpleBackArrow />
        <CustomText type="H3" text={state?.["fullName"]} />
      </styled.OperatorsPageHeader>
      <styled.OperatorsPageTableContainer>
        <styled.OperatorsPageTableWrapper>
          <CustomTable
            onHeadCellPress={handleChangeSortOrder}
            headerData={tableHeadDetails}
            bodyData={userLogsList?.content}
            banList={["id"]}
          />
        </styled.OperatorsPageTableWrapper>
      </styled.OperatorsPageTableContainer>

      <styled.OperatorsPagePaginationContainer>
        <CustomPagination
          page={usersLogsPaginationDetails?.pageNumber}
          count={Math.ceil(
            userLogsList?.["totalElements"] /
              usersLogsPaginationDetails?.pageSize
          )}
          onChange={(event, value) =>
            handlePageChange(event, value, "usersLogsPagination")
          }
        />
      </styled.OperatorsPagePaginationContainer>
    </styled.OperatorLogsPageContainer>
  );
};

export default OperatorLogsPage;
