import React from "react";
// ASSETS

// STYLES
import * as styled from "./CustomSearchStyled";

// LIBRARIES

// MISC
import { ReactComponent as SearchIcon } from "assets/icons/SearchIcon.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { headerSearchValueSelector } from "slices/TempSlice/tempSliceSelectors";
import { handleSearchChange } from "slices/TempSlice/tempSlice";
import { handlePagePaginationChange } from "slices/ClientSlice/clientSlice";
import { handlePagePaginationChange as handleAdminPagePaginationChange } from "slices/AdminSlice/adminSlice";

// REDUX

// COMPONENTS

// CONFIGURATION

const CustomSearch = () => {
  // PROPERTIES

  // API REQUESTS

  // LIBRARY CONSTANTS
  const headerSearchValueDetails = useAppSelector(headerSearchValueSelector);
  const dispatch = useAppDispatch();

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  const currentPath =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const adminPageList = [
    {
      pageName: "operators",
      paginationName: "operatorsPagination",
    },
    {
      pageName: "services",
      paginationName: "activitiesPagination",
    },
    {
      pageName: "community-centers",
      paginationName: "communityCentersPagination",
    },
    {
      pageName: "target-groups",
      paginationName: "targetGroupsPagination",
    },
    {
      pageName: "operator-logs",
      paginationName: "usersLogsPagination",
    },
  ];

  const clientPageList = [
    {
      pageName: "beneficiary-list",
      paginationName: "clientBeneficiaryPagination",
    },
    {
      pageName: "beneficiary",
      paginationName: "clientBeneficiaryActivitiesPagination",
    },
    {
      pageName: "control-panel",
      paginationName: "clientControlPagePagination",
    },
    {
      pageName: "beneficiary",
      paginationName: "clientBeneficiaryPageDocumentsPagination",
    },
    {
      pageName: "statistics",
      paginationName: "statisticsPagePagination",
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const adminPage = adminPageList.find((page) => {
      return window.location.href.includes(page.pageName);
    });

    const clientPage = clientPageList.find((page) => {
      return currentPath.includes(page.pageName);
    });
    if (adminPage) {
      dispatch(
        handleAdminPagePaginationChange({
          value: 1,
          adminPage: adminPage.paginationName,
        })
      );
    }
    if (clientPage) {
      dispatch(
        handlePagePaginationChange({
          value: 1,
          clientPage: clientPage.paginationName,
        })
      );
    }

    const value = event.target.value;
    dispatch(handleSearchChange({ value }));
  };

  return (
    <styled.SearchContainer>
      <styled.SearchIconContainer>
        <SearchIcon />
      </styled.SearchIconContainer>
      <styled.SearchInput
        type="text"
        placeholder="Caută"
        value={headerSearchValueDetails}
        onChange={handleChange}
      />
    </styled.SearchContainer>
  );
};
export default CustomSearch;
