// LIBRARIES
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./adminSliceModel";
import { revertAll } from "store/store";

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    handlePagePaginationChange: (state, action) => {
      state[action.payload.adminPage].pageNumber = action.payload.value;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { handlePagePaginationChange } = adminSlice.actions;

export const adminSelector = (state) => state.admin;
export default adminSlice.reducer;
