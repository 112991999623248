// LIBRARIES
import styled from "styled-components";
import {
  BORDER_RADIUS,
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

// MISC
import { ReactComponent as AddIcon } from "assets/icons/AddIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/tableDeleteIcon.svg";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/CkeckBokUnchecked.svg";
import { ReactComponent as CheckBoxChecked } from "assets/icons/CheckBoxChecked.svg";

export const TitleContainer = styled.div`
  width: ${SIZE.max160};
`;
// render section

export const RadioListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
`;
export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max16};
  padding-right: 15px;

  max-height: 400px;
  overflow: auto;
`;
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.appWhite};
  justify-content: space-between;
  padding: 0px ${SIZE.max16};
  border-radius: ${BORDER_RADIUS.cardRadius};

  &:hover > div > svg {
    display: block;
  }

  div > div {
    background-color: transparent !important;
  }
  div > input::placeholder {
    font-size: ${FONT_SIZE.max16};
    color: ${COLORS.appBlack};
    font-weight: ${FONT_WEIGHT.semiBold};
    font-style: italic;
  }

  div > input {
    font-size: ${FONT_SIZE.max16};
    color: ${COLORS.appBlack};
    font-weight: ${FONT_WEIGHT.semiBold};
  }
`;
export const RadioItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: calc(100% - 40px);
`;
export const CheckboxIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioUnCheckedIcon = styled(CheckBoxUnchecked)``;
export const RadioCheckedIcon = styled(CheckBoxChecked)``;

export const RadioAddMoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  div > span {
    color: ${COLORS.appBlue}!important;
  }
`;
export const AddIconImg = styled(AddIcon)`
  filter: brightness(0) saturate(100%) invert(38%) sepia(100%) saturate(6775%)
    hue-rotate(228deg) brightness(89%) contrast(90%);
`;

export const DeleteIconContainer = styled.div`
  cursor: pointer;
`;
export const DeleteIconImg = styled(DeleteIcon)`
  display: none;

  filter: brightness(0) saturate(100%) invert(43%) sepia(96%) saturate(3274%)
    hue-rotate(329deg) brightness(78%) contrast(128%);
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;

  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max16};
  color: ${COLORS.appBlack};
  font-weight: ${FONT_WEIGHT.semiBold};

  padding: ${SIZE.max16};
  background-color: transparent;

  overflow-y: hidden;
  height: auto;

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: ${FONT_SIZE.max16};
    color: ${COLORS.appBlack};
    font-weight: ${FONT_WEIGHT.semiBold};
    font-style: italic;
  }
`;
