// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomRadioButton/CustomRadioButtonModel";
import {
  COLORS,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  BORDER_RADIUS,
  SIZE,
} from "constants/variables";

export const RadioListWrapper = styled.div<IStyled>`
  width: 100%;
`;
export const RadioLabel = styled.label<IStyled>``;
export const ButtonWrapper = styled.div<IStyled>(
  ({ checked, appearance }) => css`
    && {
      ${
        appearance === "primary" &&
        css`
        height: 56px;
        background-color: ${COLORS.appWhite};
        margin-bottom: ${SIZE.max16};
        border-radius: ${BORDER_RADIUS.cardRadius};
        display: flex;
        align-items: center;
        padding: 16px;
        :hover {
          box-shadow: 3px 3px 30px rgba(176, 179, 191, 0.25);
        }
        border: solid 3px
        ${checked === "checked" ? COLORS.appBlue : COLORS.appWhite};
      }
      `
      }
      ${
        appearance === "secondary" &&
        css`
        margin-bottom: ${SIZE.max16};
        border-radius: ${BORDER_RADIUS.cardRadius};
        display: flex;
        align-items: center;
   
        :hover {
          box-shadow: 3px 3px 30px rgba(176, 179, 191, 0.25);
        }
        color: ${checked === "checked" ? COLORS.appBlack : COLORS.appDarkGrey};
      }
      `
      }

      
  `
);
export const OptionText = styled.span<IStyled>`
  font-family: ${FONT_FAMILY.poppins};
  font-size: ${FONT_SIZE.max16};
  font-weight: ${FONT_WEIGHT.semiBold};
  margin-left: 12px;
`;
export const SvgWrapper = styled.span<IStyled>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;
