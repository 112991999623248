// LIBRARIES
import styled, { css } from "styled-components";

// MISC
import { IStyled } from "components/Atoms/CustomButton/CustomButtonModel";
import {
  BORDER_RADIUS,
  FONT_SIZE,
  COLORS,
  FONT_FAMILY,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

export const ButtonComponent = styled.button<IStyled>(
  ({ appearance }) => css`
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${BORDER_RADIUS.cardRadius};
    padding: ${SIZE.max16};
    white-space: nowrap;
    /* @media (max-width:1200px) {
    flex-direction: column;
  } */

    ${appearance === "primary" &&
    css`
      background-color: ${COLORS.appBlue};
    `}
    ${appearance === "secondary" &&
    css`
      background-color: transparent;
    `}
    ${appearance === "disabled" &&
    css`
      background-color: ${COLORS.appDarkGrey};
    `}
    ${appearance === "cancel" &&
    css`
      background-color: transparent;
      justify-content: flex-start;
      padding: 0;
    `}
    ${appearance === "middleBlue" &&
    css`
      background-color: ${COLORS.appBlue};
      padding: ${SIZE.max8};
    `} 
    ${appearance === "smallestBlue" &&
    css`
      background-color: ${COLORS.appBlue};
      padding: 6px;
    `}

    ${appearance === "smallestDisabled" &&
    css`
      background-color: ${COLORS.appDarkGrey};
      padding: 6px;
    `}
    ${appearance === "black" &&
    css`
      background-color: ${COLORS.appBlack};
      padding: 6px;
    `}
  `
);

export const ButtonText = styled.span<{ appearance: string }>(
  ({ appearance }) => css`
    font-size: ${FONT_SIZE.max16};
    font-family: ${FONT_FAMILY.poppins};
    font-weight: ${FONT_WEIGHT.semiBold};
    color: ${COLORS.appWhite};
    ${appearance === "primary" &&
    css`
      font-size: ${FONT_SIZE.max16};
    `}
    ${appearance === "secondary" &&
    css`
      color: ${COLORS.appDarkGrey};
      font-size: ${FONT_SIZE.max12};
      text-decoration: underline;
      font-weight: ${FONT_WEIGHT.medium};
    `}
    ${appearance === "cancel" &&
    css`
      color: ${COLORS.appRed};
      font-size: ${FONT_SIZE.max14};
    `}  
    ${(appearance === "middleBlue" ||
      appearance === "smallestBlue" ||
      appearance === "smallestDisabled") &&
    css`
      font-size: ${FONT_SIZE.max14};
    `}
    
    
      ${appearance === "black" &&
    css`
      color: ${COLORS.appWhite};
      font-size: ${FONT_SIZE.max14};
      width: 100%;
      white-space: nowrap;
    `}
  `
);
