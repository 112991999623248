import React from "react";

// ASSETS

// STYLES
import * as styled from "./CustomButtonStyled";

// LIBRARIES

// MISC
import { IProps } from "./CustomButtonModel";

// COMPONENTS

// CONFIGURATION

const CustomButton = (props: IProps) => {
  // PROPERTIES
  const {
    text = "",
    handleClick,
    appearance = "primary",
    disabled = false,
  } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <styled.ButtonComponent
      onClick={handleClick}
      appearance={disabled && disabled === true ? "disabled" : appearance}
      disabled={disabled ? true : false}
    >
      <styled.ButtonText appearance={appearance}>{text}</styled.ButtonText>
    </styled.ButtonComponent>
  );
};

export default CustomButton;
