import React from "react";

// ASSETS
import { ReactComponent as Logo } from "assets/icons/Logo.svg";

// STYLES
import * as styled from "./HeaderStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { useDecodedJwt } from "hooks/useDecodedJwt";
import { APP_ROLES } from "constants/variables";
import { translateAppRoles } from "helpers/globalFunc";

// MISC
import { IProps } from "./HeaderModel";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import CustomSearch from "components/Atoms/CustomSearch/CustomSearch";

// CONFIGURATION

const Header = (props: IProps) => {
  // PROPERTIES
  const { appearance = "simple" } = props;

  // API REQUESTS

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const { role, fullName } = useDecodedJwt();

  const currentPath =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  // STATE CONSTANTS

  // LIFE CYCLE

  // EVENT HANDLERS

  const handleNavigateHome = () => {
    if (role === APP_ROLES.ADMIN) {
      navigate("/operators");
    } else if (role === APP_ROLES.OPERATOR) {
      navigate("/control-panel");
    } else if (role === APP_ROLES.CENTER_MANAGER) {
      navigate("/control-panel");
    } else if (role === APP_ROLES.ZONE_MANAGER) {
      navigate("/operators");
    }
  };

  const searchBarAllowedPaths = [
    "templates-list",
    "control-panel",
    "statistics",
    "beneficiary",
  ];

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <styled.HeaderWrapper>
      <styled.HeaderContainer>
        <styled.FirstPartHeader>
          <styled.LogoContainer onClick={handleNavigateHome}>
            <Logo />
          </styled.LogoContainer>
          {appearance !== "simple" &&
            !searchBarAllowedPaths?.includes(currentPath) && (
              <styled.SearchComponentWrapper>
                <CustomSearch />
              </styled.SearchComponentWrapper>
            )}
        </styled.FirstPartHeader>
        {appearance === "full" && (
          <styled.OperatorDetailes>
            <CustomText type={"H6"} text={fullName} />
            <CustomText type={"H7"} text={translateAppRoles(role)} />
            <styled.ForgotPasswordTest onClick={handleChangePassword}>
              Schimba parola
            </styled.ForgotPasswordTest>
          </styled.OperatorDetailes>
        )}
      </styled.HeaderContainer>
    </styled.HeaderWrapper>
  );
};

export default Header;
