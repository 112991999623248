// LIBRARIES
import styled from "styled-components";
import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
} from "constants/variables";

// MISC

export const TemplatesListPagePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZE.max40};
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: ${SIZE.max32};
`;

export const TemplatesCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SIZE.max24};

  max-height: 70vh;
  overflow-y: auto;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: ${SIZE.max16};
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  gap: ${SIZE.max24};
  padding-bottom: ${SIZE.max32};
`;

export const RightPageContainer = styled.div`
  width: 100%;
  height: 100%;
  .tab-list-mui {
    background-color: ${COLORS.appWhite};
    border-radius: ${BORDER_RADIUS.cardRadius};

    .MuiTabs-flexContainer {
      display: flex;

      .Mui-selected {
        color: ${COLORS.appBlack};
      }
    }
  }
  .tab-mui {
    color: ${COLORS.appDarkGrey};
    font-weight: ${FONT_WEIGHT.semiBold};
    font-size: ${FONT_SIZE.max16};
    text-transform: capitalize;
  }
  .tab-panel-mui {
    padding: 0;
    margin: 0;
    //height: 100%;
  }
`;

export const TemplateCard = styled.div``;

export const ModalWrapper = styled.div`
  background-color: ${COLORS.appLightGrey};
  border-radius: ${BORDER_RADIUS.cardRadius};
  width: ${SIZE.max550};
`;

export const ModalContent = styled.div`
  padding-top: ${SIZE.max32};
  padding-left: ${SIZE.max40};
  padding-right: ${SIZE.max40};
  padding-bottom: ${SIZE.max24};
`;

export const InputCustomWrapper = styled.div`
  margin-bottom: ${SIZE.max16};
`;

export const ModalTitleContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-left: ${SIZE.max40};
  /* height: ${SIZE.max65}; */
  padding-top: ${SIZE.max24};
  padding-bottom: ${SIZE.max24};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-right-radius: ${BORDER_RADIUS.cardRadius};
  border-top-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const ButtonsContainer = styled.div`
  background-color: ${COLORS.appWhite};
  padding-top: ${SIZE.max16};
  padding-bottom: ${SIZE.max16};
  padding-right: ${SIZE.max40};
  padding-left: ${SIZE.max40};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${BORDER_RADIUS.cardRadius};
  border-bottom-left-radius: ${BORDER_RADIUS.cardRadius};
`;
export const IndividualButtonWrapper = styled.div``;
